import { Reducer } from "redux";

import { ConfirmBookingViewAction, RESET, SET_AGENCY_REFERENCE, SET_CONTACT, SET_PAYMENT } from "./actions";
import { ConfirmData } from "./types";

const defaultState: ConfirmData = {};

/** Reducer */
const reducer: Reducer<ConfirmData, ConfirmBookingViewAction> = (state = defaultState, action) => {
  switch (action.type) {
    case RESET:
      return defaultState;
    case SET_AGENCY_REFERENCE:
      return { ...state, agencyReference: action.agencyReference };
    case SET_CONTACT:
      return { ...state, contact: action.contact };
    case SET_PAYMENT:
      return { ...state, payment: action.payment };
    default:
      return state;
  }
};

export default reducer;
