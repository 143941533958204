import React from "react";

import { useMountRef } from "~/hooks/asyncHooks";

import { useI18n } from "~/services/i18n";

import { useViewContext } from "~/views/ViewWrapper";

import QRButton from "./QRButton";

/** Las propiedades del componente. */
export interface QRExcursionProps {
  /** Función invocada cuando se ha leído una excursión por qr. */
  onScanExcursionQR: (excursionCode: string, modalityCode: string) => void;
}

/** Tipo que representa los datos de una excursión leída por qr. */
type QRExcursion = {
  /** El código de una excursión. */
  excursionCode: string;

  /** El código de la modalidad. */
  modalityCode: string;
};

/** Función que transforma el texto del qr a una respuesta válida. */
function getQrExcursion(response: string): QRExcursion | null {
  if (response.includes("-") && response.split("-").length === 2) {
    const data = response.split("-");

    return { excursionCode: data[0], modalityCode: data[1] };
  }

  return null;
}

/**
 * Lógica para capturar una excursión mediante un lector qr.
 */
const QRExcursion: React.FC<QRExcursionProps> = props => {
  const { onScanExcursionQR } = props;
  const { qrScanner, showDialog } = useViewContext();

  const mountRef = useMountRef();

  const { formatMessage } = useI18n();

  const clickQR = async () => {
    try {
      const response = await qrScanner();

      if (mountRef.current.isMounted) {
        if (response != null) {
          const qrExcursion = getQrExcursion(response);
          if (qrExcursion != null) {
            onScanExcursionQR(qrExcursion.excursionCode, qrExcursion.modalityCode);
          } else {
            showDialog(formatMessage("qrExcursion.qrFormat.message"), formatMessage("qrExcursion.qrFormat.title"));
          }
        }
      }
    } catch (error) {
      if (mountRef.current.isMounted) {
        const errorText = typeof error === "object" ? JSON.stringify(error) : error;
        showDialog(
          formatMessage("qrExcursion.qrError.message", { errorText }),
          formatMessage("qrExcursion.qrError.title")
        );
      }
    }
  };

  return <QRButton onClickQR={clickQR} />;
};

export default QRExcursion;
