import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { YearMonth } from "js-joda";

import { useI18n } from "~/services/i18n";

export interface CalendarControlProps {
  /** Mes que se está visualizando. */
  month: YearMonth;

  /** Función invocada al seleccionar el mes anterior. */
  monthBack: () => void;

  /** Función invocada al seleccionar el mes siguiente. */
  monthNext: () => void;
}

/**
 * Vista del panel de control de un calendario.
 */
const AvailabilityControl: React.FC<CalendarControlProps> = props => {
  const { month, monthBack, monthNext } = props;

  const { formatYearMonth } = useI18n();

  return (
    <>
      <IconButton onClick={monthBack}>
        <NavigateBeforeIcon />
      </IconButton>
      <Typography>{formatYearMonth(month)}</Typography>
      <IconButton onClick={monthNext}>
        <NavigateNextIcon />
      </IconButton>
    </>
  );
};

export default AvailabilityControl;
