/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { findDestinations, findExcursions } from "~/services/booking";
import logger from "~/services/logger";
import { ApiError, ConnectionTimeout, HostUnreachable } from "~/services/webapi/client/types";
import { Destination, Excursion } from "~/services/webapi/types";
import { AppState } from "~/state";
import { loadView, ViewId } from "~/views";

/**
 * Definición de acciones
 */
export const SET_DESTINATION = "SearcherView/SET_DESTINATION";
export const SET_DESTINATIONS = "SearcherView/SET_DESTINATIONS";
export const SET_DURATION = "SearcherView/SET_DURATION";
export const SET_EXCURSION = "SearcherView/SET_EXCURSION";
export const SET_EXCURSIONS = "SearcherView/SET_EXCURSIONS";
export const SET_EXCURSION_TYPE = "SearcherView/SET_EXCURSION_TYPE";
export const SET_FEATURE = "SearcherView/SET_FEATURE";
export const SET_MODALITY = "SearcherView/SET_MODALITY";
export const SET_SEGMENTATION = "SearcherView/SET_SEGMENTATION";

/** Acción para establecer el valor para el filtro de destino */
export interface SetDestination extends Action {
  destination: string | null;
  type: typeof SET_DESTINATION;
}

/** Acción para establecer los destinos */
export interface SetDestinations extends Action {
  destinations: Destination[] | null;
  type: typeof SET_DESTINATIONS;
}

/**
 * Acción para establecer el valor para el filtro de la duración de la excursión
 */
export interface SetDuration extends Action {
  duration: string | null;
  type: typeof SET_DURATION;
}

/** Acción para establecer el valor para el filtro de excursión */
export interface SetExcursion extends Action {
  excursion: string | null;
  type: typeof SET_EXCURSION;
}

/** Acción para establecer las excursiones */
export interface SetExcursions extends Action {
  destinationCode: string;
  excursions: Excursion[] | null;
  type: typeof SET_EXCURSIONS;
}

/** Acción para establecer el valor para el filtro del tipo de excursión */
export interface SetExcursionType extends Action {
  excursionType: string | null;
  type: typeof SET_EXCURSION_TYPE;
}

/** Acción para establecer el valor para el filtro de la característica */
export interface SetFeature extends Action {
  feature: string | null;
  type: typeof SET_FEATURE;
}

/** Acción para establecer el valor para el filtro de la modalidad */
export interface SetModality extends Action {
  modality: string | null;
  type: typeof SET_MODALITY;
}

/** Acción para establecer el valor para el filtro de la segmentación */
export interface SetSegmentation extends Action {
  segmentation: string | null;
  type: typeof SET_SEGMENTATION;
}

/** Acciones del módulo. */
export type SearchViewAction =
  | SetDestination
  | SetDestinations
  | SetDuration
  | SetExcursion
  | SetExcursions
  | SetExcursionType
  | SetFeature
  | SetModality
  | SetSegmentation;

/**
 * Tratamiento genérico para los errores producidos en los thunks del módulo.
 *
 * @param error el error producido
 * @param stopLoad indica que debe para la carga de datos en la vista
 * @param dispatch instancia del dispatch de redux
 */
const handleError = (error: any, stopLoad: boolean, dispatch: ThunkDispatch<AppState, void, Action>) => {
  /* Hay que quitar la selección para no entrar en bucle de errores. */
  dispatch(setDestination(null));

  if (error === HostUnreachable || error === ConnectionTimeout || error.type === ApiError) {
    /* Si es un error conocido, entonces lo tratamos. */
    const payload = stopLoad ? { dataLoadError: true } : undefined;
    dispatch(
      loadView(
        ViewId.error,
        {
          action: () => dispatch(loadView(ViewId.searcher, payload, true)),
          error,
        },
        true
      )
    );
  } else {
    /*
     * Cualquier otro error se propaga. Creo que no deberíamos llegar aquí salvo
     * por errores de programación.
     */
    throw error;
  }
};

/**
 * Genera la acción para establecer el valor del filtro de destino
 *
 * @param destination código de destino
 */
export function setDestination(destination: string | null) {
  return { destination, type: SET_DESTINATION };
}

/**
 * Genera la acción para establecer los destinos
 * @param destinations destinos
 */
const setDestinations = (destinations: Destination[] | null) => ({ destinations, type: SET_DESTINATIONS });

/**
 * Genera la acción para establecer el valor del filtro por duración
 *
 * @param duration duración
 */
export const setDuration = (duration: string) => ({ duration, type: SET_DURATION });

/**
 * Genera la acción para establecer el valor del filtro de excursión
 *
 * @param excursion código de excursión selecionado
 */
export const setExcursion = (excursion: string) => ({ excursion, type: SET_EXCURSION });

/**
 * Genera la acción para establecer las excursiones de un destino
 */
const setExcursions = (destinationCode: string, excursions: Excursion[]) => ({
  destinationCode,
  excursions,
  type: SET_EXCURSIONS,
});

/**
 * Genera la acción para realizar el fetch de destinos
 */
export const fetchDestinations = (): ThunkAction<void, AppState, void, Action> => async dispatch => {
  try {
    const destinations = await findDestinations();
    dispatch(setDestinations(destinations));

    if (destinations && destinations.length === 1) {
      // TODO: Por ahora se selecciona aquí si únicamente hay un valor.
      dispatch(setDestination(destinations[0].code));
    }
  } catch (error) {
    logger.error("Error fetching Destinatinos", error);
    handleError(error, true, dispatch);
  }
};

/**
 * Genera la acción para realizar el fetch de Excursiones
 *
 * @param destinationCode código de destino
 */
export const fetchExcursions = (destinationCode: string): ThunkAction<void, AppState, void, Action> => async (
  dispatch,
  getState
) => {
  try {
    const agency = getState().bookingProcess.agency;
    const agencyCode = agency ? agency.code : undefined;

    const excursions = await findExcursions(destinationCode, agencyCode);
    const action = setExcursions(destinationCode, excursions);
    dispatch(action);
  } catch (error) {
    logger.error("Error fetching Excursions", error);
    handleError(error, false, dispatch);
  }
};

/**
 * Genera la acción para establecer el valor del filtro de tipo de excursión
 *
 * @param excursionType código de tipo de excursión
 */
export const setExcursionType = (excursionType: string) => ({ excursionType, type: SET_EXCURSION_TYPE });

/**
 * Genera la acción para establecer el valor del filtro de característica
 *
 * @param excursionType código de característica
 */
export const setFeature = (feature: string) => ({ feature, type: SET_FEATURE });

/**
 * Genera la acción para establecer la modalidad seleccionada
 *
 * @param modality código de modalidad
 */
export const setModality = (modality: string) => ({ modality, type: SET_MODALITY });

/**
 * Genera la acción para establecer el valor del filtro de segmentación
 *
 * @param segmentation código de segmentación
 */
export const setSegmentation = (segmentation: string) => ({ segmentation, type: SET_SEGMENTATION });
