import { i18n as bookingView } from "../components/AgencyView";
import { i18n as confirmView } from "../components/ConfirmFormView";
import { i18n as contactView } from "../components/ContactView";
import { i18n as paymentView } from "../components/PaymentView";
import en from "./en";
import es from "./es";

export default {
  en: { ...en, ...bookingView.en, ...confirmView.en, ...contactView.en, ...paymentView.en },
  es: { ...es, ...bookingView.es, ...confirmView.es, ...contactView.es, ...paymentView.es },
};
