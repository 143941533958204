/* Etiquetas multiidoma en castellano. */
export default {
  "liquidationReport.title": "Informe de ventas del terminal",
  "liquidationResult.detail.sales": "Ventas:",
  "liquidationResult.detail.refunds": "Devoluciones:",
  "liquidationResult.detail.total": "Total:",
  "liquidationResult.ticketsSold": "Nº tickets vendidos:",
  "liquidationResult.ticketsCancelled": "Nº tickets anulados:",
  "liquidationResult.paymentType.card": "Tarjeta:",
  "liquidationResult.paymentType.cash": "Efectivo:",
  "liquidationResult.paymentType.general": "General:",
  "liquidationSearcherForm.action.clear": "Borrar",
  "liquidationSearcherForm.action.search": "Consultar",
  "liquidationSearcherForm.dateRange.label": "Rango de fechas",
  "liquidationSearcherForm.timepicker.from": "Desde",
  "liquidationSearcherForm.timepicker.label": "Seleccionar rango de horas",
  "liquidationSearcherForm.timepicker.to": "Hasta",
};
