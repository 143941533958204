/* Etiquetas multiidoma en castellano. */
export default {
  "availabilityCalendar.freeSale": "VL",
  "availabilityCalendar.weekDayShort.friday": "V",
  "availabilityCalendar.weekDayShort.monday": "L",
  "availabilityCalendar.weekDayShort.saturday": "S",
  "availabilityCalendar.weekDayShort.sunday": "D",
  "availabilityCalendar.weekDayShort.thursday": "J",
  "availabilityCalendar.weekDayShort.tuesday": "M",
  "availabilityCalendar.weekDayShort.wednesday": "X",
};
