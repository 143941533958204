/* Etiquetas multiidoma en castellano. */
export default {
  "rescheduleView.confirm.cancelContinue": "¿Continuar?",
  "rescheduleView.confirm.message": "Anulará el ticket actual y creará uno nuevo para la fecha: ",
  "rescheduleView.confirm.title": "Cambiar fecha",
  "rescheduleView.errorDialog.message": "Error al cambiar la fecha del ticket. Revise la reserva.",
  "rescheduleView.errorDialog.title": "Error al cambiar fecha.",
  "rescheduleView.feeDialog.message": "No es posible cambiar la fecha del ticket ya que tiene gastos de cancelación.",
  "rescheduleView.feeDialog.title": "Gastos de cancelación.",
};
