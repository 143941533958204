import React from "react";

import TextField from "@material-ui/core/TextField";

import { useI18n } from "~/services/i18n";

import { Contact } from "~/views/ConfirmBookingView/types";

/** Las propiedades del componente. */
export interface ProvidedProps {
  /** La información de contacto de la reserva. */
  contact?: Contact;

  /** Función invocada cuando cambian los datos de contacto. */
  onChangeContact: (contact?: Contact) => void;
}

const inputProps = {
  name: { maxLength: 50 },
  phone: { maxLength: 20 },
};

/**
 * Crea un objeto con la información de contacto de la reserva.
 */
const createContact = (name?: string, phone?: string) => {
  if (!name && !phone) {
    return undefined;
  }

  return { name: name === "" ? undefined : name, phone: phone === "" ? undefined : phone };
};

/**
 * Componente con la información de contacto de la reserva.
 */
const ContactView: React.FC<ProvidedProps> = props => {
  const { contact, onChangeContact } = props;

  const { formatMessage } = useI18n();

  /* Callbacks */
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeContact(createContact(event.currentTarget.value, contact?.phone));
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeContact(createContact(contact?.name, event.currentTarget.value));
  };

  return (
    <>
      <TextField
        fullWidth={true}
        label={formatMessage("contactView.name")}
        inputProps={inputProps.name}
        margin="dense"
        value={contact?.name || ""}
        onChange={handleChangeName}
        required={true}
      />
      <TextField
        fullWidth={true}
        label={formatMessage("contactView.phone")}
        margin="dense"
        inputProps={inputProps.phone}
        type="tel"
        value={contact?.phone || ""}
        onChange={handleChangePhone}
      />
    </>
  );
};

export default ContactView;
