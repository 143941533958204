/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix
/* eslint-disable react/prop-types */ // TODO Fix

import React, { useCallback, useMemo } from "react";

import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import CancelConditionsViewer from "~/components/CancelConditionsViewer";
import DistributionTable from "~/components/DistributionTable";
import { ticketDistribution } from "~/components/DistributionTable/types";
import EditableLabeledText from "~/components/EditableLabeledText";
import LabeledText from "~/components/LabeledText";

import CalendarArrow from "~/icons/CalendarArrowRight";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { ExcursionTicket } from "~/services/webapi/types";

import { noop } from "~/utils";

const useStyles = makeStyles(() =>
  createStyles({
    cancelConditions: {
      alignSelf: "center",
    },
    denseMargin: {
      margin: "8px 0",
    },
    excursionDate: {
      alignItems: "flex-start",
      display: "flex",
    },
    rowInLine: {
      "& > :not(:first-child)": {
        marginLeft: 24,
      },
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "",
    },
    rowSpaced: {
      "& > :not(:first-child)": {
        textAlign: "right",
      },
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

interface TicketDetailsProps {
  /** Indica si se muestra en modo edición. */
  editionEnabled?: boolean;

  /** Invocado cuando se modifica un dato del ticket. */
  onFieldChange?: (ticketNumber: string, field: string, value: string) => void;

  /** Invocado cuando se quiere reprogramar un ticket. */
  onRescheduleTicket?: (ticketNumber: string) => void;

  /** El ticket a visualizar. */
  ticket: ExcursionTicket;
}

/**
 * Información detallada del ticket.
 */
const TicketDetails: React.FC<TicketDetailsProps & InjectedI18nProps> = props => {
  const {
    editionEnabled,
    i18n: { formatLocalDateString, formatLocalTimeString, formatMessage },
    onFieldChange = noop,
    onRescheduleTicket,
    ticket: {
      cancelConditions,
      excursionDate,
      hotel,
      hotelReference,
      language,
      pickupPoint,
      pickupTime,
      remarks,
      ticketNumber,
    },
  } = props;

  const classes = useStyles();

  /*
   * Callbacks
   */
  const updateInputField = useCallback(
    (value: string, name?: string) => {
      onFieldChange(ticketNumber, name!, value);
    },
    [ticketNumber, onFieldChange]
  );

  const onClick = () => {
    if (onRescheduleTicket) {
      onRescheduleTicket(ticketNumber);
    }
  };

  /*
   * Render
   */

  const distribution = useMemo(() => ticketDistribution(props.ticket), [props.ticket]);

  return (
    <div>
      <div className={classes.rowSpaced}>
        <div className={classes.excursionDate}>
          <LabeledText
            label={formatMessage("excursionTicket.excursionDate")}
            text={formatLocalDateString(excursionDate)}
          />
          {onRescheduleTicket && (
            <IconButton onClick={onClick}>
              <CalendarArrow color="action" />
            </IconButton>
          )}
        </div>

        {cancelConditions && (
          <div className={classes.cancelConditions}>
            <CancelConditionsViewer cancelConditions={cancelConditions} />
          </div>
        )}
      </div>

      <div className={classes.denseMargin}>
        <DistributionTable distribution={distribution} />
      </div>

      <LabeledText label={formatMessage("excursionTicket.language")} text={language ? language.name : undefined} />

      {hotel && (
        <div className={classes.rowInLine}>
          <LabeledText label={formatMessage("excursionTicket.hotel")} text={hotel.name} />

          <EditableLabeledText
            editionEnabled={editionEnabled}
            label={formatMessage("excursionTicket.hotelReference")}
            name="hotelReference"
            text={hotelReference}
            onChange={updateInputField}
          />
        </div>
      )}

      {pickupPoint && (
        <LabeledText
          label={formatMessage("excursionTicket.pickUpPoint")}
          text={pickupPoint.description + (pickupTime != null ? ` (${formatLocalTimeString(pickupTime)})` : "")}
        />
      )}

      <EditableLabeledText
        editionEnabled={editionEnabled}
        fullWidth={true}
        label={formatMessage("excursionTicket.remarks")}
        name="remarks"
        text={remarks}
        onChange={updateInputField}
      />
    </div>
  );
};

export default withI18n(TicketDetails);
