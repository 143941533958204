/* Etiquetas multiidoma en castellano. */
export default {
  "printTicket.agency": "Agencia",
  "printTicket.agent": "Agente",
  "printTicket.annulation": "ANULACIÓN",
  "printTicket.cancellationPolicy": "Política de cancelación",
  "printTicket.contact": "Nombre",
  "printTicket.excursionDate": "Fecha",
  "printTicket.hotel": "Hotel",
  "printTicket.hotelRoom": "Nº Hab.",
  "printTicket.paxType.ADULTO": "ADU",
  "printTicket.paxType.BEBE": "BEB",
  "printTicket.paxType.JUNIOR": "JUN",
  "printTicket.paxType.NIÑO": "NIN",
  "printTicket.paxType.SENIOR": "SEN",
  "printTicket.payment": "Forma de pago: {payment}",
  "printTicket.paymentType.CARD": "Tarjeta",
  "printTicket.paymentType.CASH": "Metálico",
  "printTicket.payment.reference": "Referencia: {reference}",
  "printTicket.pickUpPointName": "Recogida",
  "printTicket.pickUpPointTime": "Hora",
  "printTicket.price.discount": "Descuento",
  "printTicket.price.subtotal": "Subtotal",
  "printTicket.price.total": "TOTAL",
  "printTicket.printTime": "Impresión: {time}",
  "printTicket.reprint": "** TICKET REIMPRESO **",
  "printTicket.reservationDate": "Fecha",
  "printTicket.reservationNumber": "Loc.",
  "printTicket.ticketNumber": "Ticket",
};
