/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";

import clsx from "classnames";

import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import LabeledText from "~/components/LabeledText";
import CalendarArrow from "~/icons/CalendarArrowRight";
import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { ExcursionTicket } from "~/services/webapi/types";

const useStyles = makeStyles(() =>
  createStyles({
    cancelled: {
      textDecoration: "line-through",
    },
    excursionDate: {
      alignItems: "flex-start",
      display: "flex",
    },
    row: {
      "& > :not(:first-child)": {
        textAlign: "right",
      },
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

interface TicketSummaryProps {
  /** Invocado cuando se quiere reprogramar un ticket. */
  onRescheduleTicket?: (ticketNumber: string) => void;

  /** El ticket a visualizar. */
  ticket: ExcursionTicket;
}

/**
 * Información resumida del ticket.
 */
const TicketSummary: React.FC<TicketSummaryProps & InjectedI18nProps> = props => {
  const {
    i18n: { formatCurrency, formatLocalDateString, formatMessage },
    onRescheduleTicket,
    ticket: { excursionDate, language, paxes, price, status, ticketNumber },
  } = props;

  const onClick = () => {
    if (onRescheduleTicket) {
      onRescheduleTicket(ticketNumber);
    }
  };

  const classes = useStyles();

  const cancelled = status === "CANCELLED";
  const distributionText =
    paxes == null
      ? ""
      : paxes.reduce((acc, paxQuota) => {
          if (paxQuota.units > 0) {
            if (acc) {
              acc += " + ";
            }
            acc += paxQuota.units + paxQuota.paxType.code;
          }

          return acc;
        }, "");

  return (
    <div>
      <div className={classes.row}>
        <div className={classes.excursionDate}>
          <LabeledText
            label={formatMessage("excursionTicket.excursionDate")}
            text={formatLocalDateString(excursionDate)}
          />
          {onRescheduleTicket && (
            <IconButton onClick={onClick}>
              <CalendarArrow color="action" />
            </IconButton>
          )}
        </div>

        <LabeledText label={formatMessage("excursionTicket.language")} text={language ? language.name : undefined} />
      </div>

      <div className={classes.row}>
        <LabeledText label={formatMessage("excursionTicket.distribution")} text={distributionText} />

        {cancelled && (
          <LabeledText
            label={formatMessage("excursionTicket.cancellation")}
            text={formatCurrency(price.cancellationAmount, price.currency)}
          />
        )}

        <LabeledText
          label={formatMessage(`excursionTicket.total${price.discount != null ? "WithDiscount" : ""}`)}
          text={formatCurrency(price.totalPrice, price.currency)}
          textClass={clsx({ [classes.cancelled]: cancelled })}
        />
      </div>
    </div>
  );
};

export default withI18n(TicketSummary);
