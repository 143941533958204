import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import { useI18n } from "~/services/i18n";
import { formatNumber } from "~/services/i18n/i18nApiImpl";

/** Estilos del componente. */
const useStyles = makeStyles(() =>
  createStyles({
    detailStyle: {
      fontSize: "0.8125rem",
      marginTop: 8,
    },
    tableStyle: {
      width: "100%",
    },
  })
);

interface TicketsProps {
  /** Contador de tickets cancelados */
  cancelledTicketsCount: number;

  /** Contador de tickets cancelados */
  soldTicketsCount: number;
}

const TotalTickets: React.FC<TicketsProps> = props => {
  const { cancelledTicketsCount, soldTicketsCount } = props;

  const classes = useStyles();
  const { formatMessage } = useI18n();

  /* Render */
  return (
    <div className={classes.detailStyle}>
      <table className={classes.tableStyle}>
        <tr>
          <td>{formatMessage("liquidationResult.ticketsSold")}</td>
          <td align="right">{formatNumber(soldTicketsCount)}</td>
        </tr>
        <tr>
          <td>{formatMessage("liquidationResult.ticketsCancelled")}</td>
          <td align="right">{formatNumber(cancelledTicketsCount)}</td>
        </tr>
      </table>
    </div>
  );
};

export default TotalTickets;
