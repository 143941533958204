import { LocalDate } from "js-joda";

import { RecursivePartial } from "~/utils";

import logger from "~/services/logger";
import { addTicket as apiAddTicket, removeTicket as apiRemoveTicket } from "~/services/webapi/bookings";
import { Booking, ExcursionTicket } from "~/services/webapi/types";

/**
 * Crea el objeto Ticket a reservar. Únicamente se deben informar los campos
 * necesarios para añadir el ticket a la reserva.
 */
const createExcursionTicket = (ticket: ExcursionTicket, date: LocalDate): RecursivePartial<ExcursionTicket> => {
  return {
    agencyCode: ticket.excursionCode,
    excursionCode: ticket.excursionCode,
    excursionDate: date.toString(), //Fecha de la nueva reserva.
    hotel: { code: ticket.hotel?.code, zoneCode: ticket.hotel?.zoneCode },
    language: { code: ticket.language.code },
    modalityCode: ticket.modalityCode,
    paxes: ticket.paxes,
    pickupPoint: { code: ticket.pickupPoint?.code },
    price: ticket.price,
    remarks: ticket.remarks,
  };
};

/**
 * Acción que cambia la fecha de un ticket.
 * La acción consiste en añadir el mismo ticket con la fecha modificada
 * y eliminar el ticket anterior.
 */
export async function rescheduleTicket(
  bookingNumber: string,
  ticket: ExcursionTicket,
  newDate: LocalDate
): Promise<Booking> {
  const newTicket = createExcursionTicket(ticket, newDate);

  try {
    //Se añade el nuevo ticket con la fecha modificada.
    await apiAddTicket(bookingNumber, newTicket);
  } catch (error) {
    logger.error("Unable to add ticket.", error);
    throw error;
  }

  try {
    //Se elimina el ticket original.
    const booking = await apiRemoveTicket(bookingNumber, ticket.ticketNumber);

    return booking;
  } catch (error) {
    logger.error("Unable to remove ticket.", error);
    throw error;
  }
}
