import React from "react";

import Button from "@material-ui/core/Button";
import withStyles, { StyleRules, WithStyles } from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import { LocalDate } from "js-joda";

import CodeNameSelector from "~/components/CodeNameSelector";
import DateRangePicker from "~/components/DateRangePicker";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { Seller } from "~/services/webapi/types";

import { SearchCriteria } from "./types";

/** Clases JSS del componente. */
type ClassKey = "buttonPanel" | "root";

/** Estilos por defecto del componente. */
const styles: StyleRules<ClassKey> = {
  buttonPanel: {
    "& > button": {
      marginLeft: 8,
    },
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
  },
  root: {},
};

/** Propiedades del componente. */
export interface Props extends SearchCriteria {
  /** Función para cargar los vendedores */
  fetchSellers?: () => void;

  /** Función invocada al lanzar la búsqueda. */
  onSearch?: (criteria: SearchCriteria) => void;

  /** Listado de vendedores. */
  sellers?: Seller[];
}

/** Se añade el estilo a las propiedades del componente */
interface ProvidedProps extends Props, WithStyles<ClassKey>, InjectedI18nProps {}

/**
 * Formulario para la búsqueda de reservas.
 */
class BookingSearcherForm extends React.PureComponent<ProvidedProps, SearchCriteria> {
  public constructor(props: ProvidedProps) {
    super(props);

    const {
      contactName,
      creationDateFrom,
      creationDateTo,
      excursionDateFrom,
      excursionDateTo,
      reference,
      sellerCode,
    } = props;

    this.state = {
      contactName,
      creationDateFrom,
      creationDateTo,
      excursionDateFrom,
      excursionDateTo,
      reference,
      sellerCode,
    };
  }

  public componentDidMount() {
    if (this.props.sellers == null && this.props.fetchSellers) {
      this.props.fetchSellers();
    }
  }

  /** #render */
  public render() {
    const {
      classes,
      i18n: { formatMessage },
      sellers,
    } = this.props;

    const {
      contactName,
      creationDateFrom,
      creationDateTo,
      excursionDateFrom,
      excursionDateTo,
      reference,
      sellerCode,
    } = this.state;

    if (sellers) {
      sellers.forEach(() => null);
    }

    return (
      <form noValidate={true} autoComplete="off" className={classes.root}>
        <TextField
          label={formatMessage("bookingSearcherForm.reference.label")}
          margin="dense"
          name="reference"
          fullWidth={true}
          value={reference || ""}
          onChange={this.handleChangeInputText}
        />
        <TextField
          fullWidth={true}
          label={formatMessage("bookingSearcherForm.passenger.label")}
          placeholder={formatMessage("bookingSearcherForm.passenger.placeholder")}
          margin="dense"
          name="paxName"
          value={contactName || ""}
          onChange={this.handleChangeInputText}
        />

        <DateRangePicker
          label={formatMessage("bookingSearcherForm.creationDate.label")}
          dateFromValue={creationDateFrom}
          dateToValue={creationDateTo}
          onChange={this.updateCreationDateRange}
        />

        <DateRangePicker
          label={formatMessage("bookingSearcherForm.excursionDate.label")}
          dateFromValue={excursionDateFrom}
          dateToValue={excursionDateTo}
          onChange={this.updateExcursionDateRange}
        />

        {sellers && sellers.length > 1 && (
          <CodeNameSelector
            addEmpty={true}
            label={formatMessage("bookingSearcherForm.seller.label")}
            selectedCode={sellerCode}
            values={sellers}
            onValueChange={this.updateSeller}
            fullWidth={true}
          />
        )}

        <div className={classes.buttonPanel}>
          <Button color="default" variant="outlined" onClick={this.clearForm}>
            {formatMessage("bookingSearcherForm.action.clear")}
          </Button>
          <Button color="primary" variant="contained" onClick={this.doSearch}>
            {formatMessage("bookingSearcherForm.action.search")}
          </Button>
        </div>
      </form>
    );
  }

  /** Vacía el formulario. */
  private clearForm = () => {
    this.setState({
      contactName: undefined,
      creationDateFrom: undefined,
      creationDateTo: undefined,
      excursionDateFrom: undefined,
      excursionDateTo: undefined,
      reference: undefined,
    });
  };

  /** Ejecuta la búsqueda. */
  private doSearch = () => {
    const { onSearch } = this.props;

    if (onSearch) {
      onSearch({ ...this.state });
    }
  };

  /**
   * Handler para tratar la introducción de datos del usuario en el formulario.
   */
  private handleChangeInputText: React.ChangeEventHandler<HTMLInputElement> = event => {
    const { name, value } = event.currentTarget;

    if (name === "reference") {
      this.setState({ reference: value || "" });
    } else if (name === "paxName") {
      this.setState({ contactName: value || "" });
    }
  };

  /** Handler para cuando actualiza la fecha de creación. */
  private updateCreationDateRange = (dateFrom?: LocalDate, dateTo?: LocalDate) => {
    this.setState({
      creationDateFrom: dateFrom,
      creationDateTo: dateTo,
    });
  };

  /** Handler para cuando actualiza la fecha de excursión. */
  private updateExcursionDateRange = (dateFrom?: LocalDate, dateTo?: LocalDate) => {
    this.setState({
      excursionDateFrom: dateFrom,
      excursionDateTo: dateTo,
    });
  };

  /** Handler para cuando se actualiza el vendedor. */
  private updateSeller = (sellerCode?: string) => {
    this.setState({ sellerCode });
  };
}

export default withI18n(withStyles(styles)(BookingSearcherForm));
