import React from "react";

import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

/** Estilos del componente. */
const useStyles = makeStyles(() =>
  createStyles({
    option: {
      "&:not(:last-child)": {
        // TODO: Color copiado de material-ui/src/NativeSelect/NativeSelect.js llevar a theme
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
      },
      height: "auto",
      minHeight: 24,
      whiteSpace: "normal",
    },
    select: {
      whiteSpace: "normal",
    },
  })
);

/** Valor del selector */
export interface SelectorValue {
  /** El código del valor */
  code: string;

  /** La descripción del valor */
  description: string;
}

/** Propiedades del componente. */
export interface SelectorProps<T> {
  /** Añade una opción para limpiar el valor */
  addEmpty?: boolean;

  /** Estilo a aplicar al <div> contenedor */
  className?: string;

  /** Indica si debe mostrarse desactivado. */
  disabled?: boolean;

  /** Indica si debe expandir a todo el acho */
  fullWidth?: FormControlProps["fullWidth"];

  /** Función invocada para recuperar el código y la descripción del elemento */
  getSelectorValue: (item: T) => SelectorValue;

  /** Etiqueta del componente */
  label: string;

  /** El espaciado verticla del componente */
  margin?: FormControlProps["margin"];

  /** Callback para cuando se cambia de valor */
  onValueChange?: (newValue?: string) => void;

  /** Indica si es un valor obligatorio */
  required?: FormControlProps["required"];

  /** Valor seleccionado */
  selectedCode?: string | null;

  /** Conjunto de valores (opciones) */
  values?: Array<T>;
}

/**
 * Componente para la selección de un valor de entre una lista.
 * El código y la descripción de los items se obtienen a partir de una función.
 */
const Selector: <T>(p: SelectorProps<T>) => React.ReactElement<SelectorProps<T>> = props => {
  const { addEmpty, className, disabled, fullWidth, label, margin, required, selectedCode, values } = props;

  const classes = useStyles();

  /** Gestiona el evento onChange del componente select */
  const handleValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.onValueChange) {
      const value = event.target.value;
      if (value) {
        props.onValueChange(value);
      } else {
        props.onValueChange(undefined);
      }
    }
  };

  /* Render */
  return (
    <FormControl className={className} margin={margin} fullWidth={fullWidth} required={required}>
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <Select
        inputProps={{ id: label }}
        value={selectedCode || ""}
        onChange={handleValueChange}
        disabled={disabled || !values || values.length < 1}
        classes={{ select: classes.select }}
      >
        {addEmpty && (
          <MenuItem value={undefined} className={classes.option}>
            <Typography color="textSecondary">--- limpiar ---</Typography>
          </MenuItem>
        )}
        {values &&
          values.map((value, index) => {
            const { code, description } = props.getSelectorValue(value);
            return (
              <MenuItem key={index} value={code} className={classes.option}>
                {description}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default Selector;
