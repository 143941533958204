import { Action } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createThunkErrorHandlerMiddleware, { ErrorHandler } from "redux-thunk-error-handler";
import thunkMiddleware, { ThunkAction } from "redux-thunk-recursion-detect";

import reducer, { AppState } from "./reducer";

export default function createStore(onError: ErrorHandler<Action, AppState, undefined>) {
  return configureStore({
    reducer,
    middleware: [
      // XXX: Usar otro método para controlar errores que no require una versión de thunk tuneada así
      createThunkErrorHandlerMiddleware({ onError }),
      thunkMiddleware,
      ...getDefaultMiddleware<AppState, { thunk: false }>({ thunk: false }),
    ] as const,
  });
}

/* Tipo que tendrán todos los thunks de esta aplicación. */
export type AppThunk<R> = ThunkAction<R, AppState, unknown, Action<string>>;
