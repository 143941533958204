/* Etiquetas multiidoma en castellano. */
export default {
  "distributionTable.amountRestriction": "máx. {amount}",
  "distributionTable.footer.cancellation": "Cancelación",
  "distributionTable.footer.discount": "Descuento",
  "distributionTable.footer.subtotal": "Subtotal",
  "distributionTable.footer.totalPrice": "TOTAL",
  "distributionTable.head.passenger": "Pasajero",
  "distributionTable.head.totalAmount": "Total",
  "distributionTable.head.unitaryPrice": "P.Pax",
  "distributionTable.head.units": "Núm.",
  "distributionTable.paxRestrictionFrom": "desde {paxMin} pax",
  "distributionTable.paxRestrictionFromTo": "de {paxMin} a {paxMax} pax",
  "distributionTable.paxRestrictionTo": "hasta {paxMax} pax",
};
