import React, { useState } from "react";
import { LocalDate } from "js-joda";

import { ExcursionTicket, ExcursionTicketOption, LocalDateString } from "~/services/webapi/types";

import RescheduleCalendar from "./RescheduleCalendar";
import RescheduleConfirmation from "./RescheduleConfirmation";

export interface RescheduleTicketProps {
  /** Mapa con los valores de disponibilidad para cada día. */
  excursionOptionMap?: Map<LocalDateString, ExcursionTicketOption>;

  /** Función invocada cuando se cancela el cambio de ticket. */
  onClose: (error?: string) => void;

  /** Función para recuperar la disponibilidad entre fechas. */
  onGetTicketOptions: (from: LocalDate, to: LocalDate) => void;

  /** Función invocada cuando se ha seleccionado la nueva fecha de un ticket. */
  onRescheduleTicket: (date: LocalDate) => void;

  /** Indica si visualizar el calendario con las opciones disponibles. */
  showCalendar: boolean;

  /** Ticket que se está reprogramando. */
  ticket: ExcursionTicket | undefined;
}

/**
 * Vista para el cambio de fecha de una reserva.
 */
const RescheduleTicket: React.FC<RescheduleTicketProps> = props => {
  const { excursionOptionMap, onClose, onGetTicketOptions, onRescheduleTicket, showCalendar, ticket } = props;

  //La fecha preseleccionada.
  const [preselectedDay, setPreselectedDay] = useState<LocalDate | undefined>();

  /**
   * Evento que controla la preselección de una fecha del calendario
   * para reprogramar un ticket.
   */
  const onSelect = (date: LocalDate) => {
    setPreselectedDay(date);
  };

  /**
   * Handler del modal de confirmación de cambio de ticket.
   */
  const handleAction = async (actionConfirmed: boolean) => {
    if (preselectedDay && actionConfirmed) {
      //Reprogramación del ticket con la nueva fecha.
      onRescheduleTicket(preselectedDay);
    }

    setPreselectedDay(undefined);
  };

  return (
    <>
      {showCalendar && ticket && (
        <RescheduleCalendar
          excursionOptionMap={excursionOptionMap}
          onClose={onClose}
          onGetTicketOptions={onGetTicketOptions}
          onSelect={onSelect}
          ticket={ticket}
        />
      )}

      {preselectedDay && <RescheduleConfirmation date={preselectedDay} onHandleAction={handleAction} />}
    </>
  );
};

export default RescheduleTicket;
