import React from "react";

import CodeNameSelector from "~/components/CodeNameSelector";
import { useI18n } from "~/services/i18n";
import { Agency } from "~/services/webapi/types";

/**
 * Propiedades del combo selector de agencia.
 */
export interface AgencySelectorProps {
  /** Listado de agencias disponibles para seleccionar. */
  agencies?: Agency[];

  /** Código de agencia actual. */
  value?: string;

  /** Indica si no se debe permitir cambiar de agencia. */
  disabled?: boolean;

  /** Handler invocado cuando cambia el valor seleccionado. */
  onChange?: (value: string) => void;
}

/**
 * Combo para seleccionar la agencia.
 */
const AgencySelector: React.FC<AgencySelectorProps> = props => {
  const { agencies, disabled, onChange, value } = props;

  const { formatMessage } = useI18n();

  return (
    <CodeNameSelector
      disabled={disabled}
      fullWidth={true}
      label={formatMessage("agencySelector.agency")}
      onValueChange={onChange}
      selectedCode={value}
      values={agencies}
    />
  );
};

export default AgencySelector;
