import React, { useEffect, useState } from "react";

import * as scanner from "~/services/scanner";

import logger from "~/services/logger";

import { default as QRScanner } from "./QRScanner";

/** Las propiedades del componente. */
export interface ProviedProps {
  /** Función invocada cuando se cierra el lector de códigos qr. */
  onClose: () => void;

  /** Función invocada cuando se ha escaneado un código qr. */
  onScanned: (result: string | undefined) => void;

  /** Función invocada cuando se produce un error. */
  onError: (error: QRScannerError) => void;
}

/**
 * Cambia el color de fondo de la App.
 * Cambia el fondo a transparente cuando se usa el escáner y
 * lo reestablece a blanco cuando acaba el escaneado.
 */
function toggleAppBackground(clear: boolean) {
  const body = document.body;
  if (body.style) {
    body.style.backgroundColor = clear ? "rgba(0,0,0,0.01)" : "#fff";
    body.style.backgroundImage = "";
    setTimeout(() => {
      body.style.backgroundColor = clear ? "transparent" : "#fff";
    }, 50);
    if (body.parentElement && body.parentElement.style) {
      body.parentElement.style.backgroundColor = clear ? "transparent" : "#fff";
      body.parentElement.style.backgroundImage = "";
    }
  }
}

/**
 * Lógica del componente qr.
 */
const QRScannerController: React.FC<ProviedProps> = props => {
  const { onClose, onError, onScanned } = props;

  const [isLightAvailable, setIsLightAvailable] = useState(true);
  const [isLightOn, setIsLightOn] = useState(false);

  const toggleLight = () => {
    if (isLightOn) {
      scanner.turnLightOff().then(status => {
        setIsLightOn(status.lightEnabled);
      });
    } else {
      scanner.turnLightOn().then(status => {
        setIsLightOn(status.lightEnabled);
      });
    }
  };

  /** Handler para cuando se cierra el lector */
  const closeScan = () => {
    scanner.cancelScan().then(() => {
      onClose();
      toggleAppBackground(false);
    });
  };

  useEffect(() => {
    scanner.getStatus().then((status: QRScannerStatus) => {
      setIsLightAvailable(status.canEnableLight);
      setIsLightOn(status.lightEnabled);
    });

    let canceled = false;
    async function scanQR() {
      try {
        const scannerResponse = await scanner.scanQR();
        logger.debug("scannQR", scannerResponse);
        if (!canceled) {
          onScanned(scannerResponse);
        }
      } catch (scannerError) {
        onError(scannerError);
      } finally {
        toggleAppBackground(false);
      }
    }

    scanQR();

    toggleAppBackground(true);

    return () => {
      canceled = true;
    };
  }, [onError, onScanned]);

  return (
    <QRScanner
      isLightAvailable={isLightAvailable}
      isLightOn={isLightOn}
      onClose={closeScan}
      onToggleLight={toggleLight}
    />
  );
};

export default QRScannerController;
