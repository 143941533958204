import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { IntlProvider } from "react-intl";

import {
  formatCurrency,
  formatLocalDate,
  formatLocalDateString,
  formatLocalDateTime,
  formatLocalDateTimeString,
  formatLocalTime,
  formatLocalTimeString,
  formatMessage,
  formatNumber,
  formatYearMonth,
  getLocale,
} from "./i18nApiImpl";
import IntlInitializer from "./IntlInitializer";
import { I18nAPI, I18nProviderProps } from "./types";

import { AppState } from "~/state";

/** Idioma por defecto */
const DEFAULT_LOCALE = "es";

/**
 * Función para construir el valor del API del contexto para el módulo
 * multiidoma
 */
function buildContext(): I18nAPI {
  return {
    formatCurrency,
    formatLocalDate,
    formatLocalDateString,
    formatLocalDateTime,
    formatLocalDateTimeString,
    formatLocalTime,
    formatLocalTimeString,
    formatMessage,
    formatNumber,
    formatYearMonth,
    getLocale,
  };
}

/** Contexto i18n */
export const i18nContext = React.createContext(buildContext());

/** Proveedor de contexto */
export const I18nContextProvider = i18nContext.Provider;

/** Consumidor de contexto */
export const I18nContextConsumer = i18nContext.Consumer;

const I18nProvider: React.FC<I18nProviderProps> = props => {
  const languageCode = useSelector((state: AppState) => state.config.appConfig?.userConfig.languageCode);
  const locale = languageCode && languageCode in props.messages ? languageCode : DEFAULT_LOCALE;
  const messages = props.messages[locale];

  /* Cada vez que se modifica el locale del estado de la aplicación debemos
   * cambiar la instancia del contexto.
   * Deshabilitamos el warning ya que realmente el contexto
   * depende del locale aunque no forme parte de él.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const context = useMemo(() => buildContext(), [locale]);

  return (
    <I18nContextProvider value={context}>
      <IntlProvider locale={locale} defaultLocale={DEFAULT_LOCALE} messages={messages} key={locale}>
        <IntlInitializer locale={locale} key={locale} />
      </IntlProvider>
      {props.children}
    </I18nContextProvider>
  );
};

export default I18nProvider;
