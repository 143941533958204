/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";
import { Action } from "redux";

import moize from "moize";
import { Provider as ReduxProvider } from "react-redux";
import { ErrorHandler } from "redux-thunk-error-handler";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { I18nProvider } from "~/services/i18n";

import { default as messages } from "../i18n";
import createStore from "./store";
import { AppState } from "./reducer";

import Environment from "~/environment";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";

// TODO: ¿No hay forma mejor?
const defaultTheme = createMuiTheme();

/*
 * TODO: Tras actualizaicón a material 4, cambia la tipografía.
 * Queda cambiar la generación para usar funciones internas create... para cada
 * parte. Llevar a un muiTheme, tipo en la extranet
 */
const theme = createMuiTheme({
  overrides: {
    MuiListItemIcon: {
      root: {
        marginRight: 16,
        minWidth: 24,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: "0.75rem",
      },
    },
  },
  palette: {
    primary: {
      main: Environment.THEME_PRIMARY_COLOR, // "#1b8738",
    },
    secondary: {
      main: Environment.THEME_SECONDARY_COLOR, // "#bc0236",
    },
  },
  typography: {
    caption: {
      color: defaultTheme.palette.text.secondary,
      lineHeight: "1.375em",
    },
  },
});

const moizedStoreCreator = moize.simple(createStore);

/** Propiedades del provider. */
export interface Props {
  onError: ErrorHandler<Action, AppState, undefined>;
}

/**
 * Componente que se encarga de proveer de estado a la aplicación.
 * Básicamente, agrupa todas las configuraciones de redux y sus middlewares en
 * un único componente.
 */
const StateProvider: React.SFC<Props> = ({ children, onError }) => {
  /* No debemos crear instancias nuevas cada vez. */
  const store = moizedStoreCreator(onError);

  return (
    <ReduxProvider store={store}>
      <I18nProvider messages={messages}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </I18nProvider>
    </ReduxProvider>
  );
};

export default StateProvider;
