import React from "react";

import clsx from "classnames";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import { useMountRef } from "~/hooks/asyncHooks";

import QRCodeScan from "~/icons/QRCodeScan";

import { useI18n } from "~/services/i18n";

import { useViewContext } from "~/views/ViewWrapper";

import AgencyView from "../AgencyView";
import ContactView from "../ContactView";
import PaymentView from "../PaymentView";

import { Contact, Payment } from "../../types";

const useStyles = makeStyles(theme =>
  createStyles({
    paymentView: {
      marginTop: 20,
    },
    rootPaper: {
      margin: "4px 0px",
      padding: 4,
    },
    qrRow: {
      textAlign: "right",
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "flex-end",
    },
    importData: {
      paddingLeft: theme.spacing(0.5),
    },
    fixedSize: {
      flexGrow: 0,
      flexShrink: 0,
    },
  })
);

/** Las propiedades del componente. */
interface Props {
  /** La referencia de la agencia. */
  agencyReference?: string;

  /** Los datos de contacto. */
  contact?: Contact;

  /** Indica si se puede informar la referencia de pago. */
  enableMPos: boolean;

  /** Indica si es obligatorio informar la referencia de la agencia. */
  isMandatoryAgencyReference: boolean;

  /** Función invocada cuando cambia la referencia de la agencia. */
  onAgencyReferenceChange: (agencyReference: string) => void;

  /** Función invocada cuando cambian los datos de contacto. */
  onContactChange: (contact?: Contact) => void;

  /** Función invocada cuando cambian los datos de pago. */
  onPaymentChange: (payment?: Payment) => void;

  /** Los datos de pago de la reserva. */
  payment?: Payment;

  /** Indica si se permite informar la referencia de la agencia. */
  showAgencyReference: boolean;
}

/** Tipo que representa la lectura de un QR en la confirmación. */
type QRConfirm = {
  /** La referencia de la agencia  */
  agencyReference: string;

  /** El nombre de la persona de contacto. */
  name: string;
};

/** Función que transforma el texto del qr a una respuesta válida. */
function getQrConfirm(response: string): QRConfirm | null {
  if (response.includes("/") && response.split("/").length === 2) {
    const data = response.split("/");

    return { agencyReference: data[0], name: data[1] };
  }

  return null;
}

/**
 * Vista para introducir los datos de confirmación de reserva.
 */
const ConfirmDataView: React.FC<Props> = props => {
  const {
    agencyReference,
    contact,
    enableMPos,
    isMandatoryAgencyReference,
    onAgencyReferenceChange,
    onContactChange,
    onPaymentChange,
    payment,
    showAgencyReference,
  } = props;

  const mountRef = useMountRef();

  const { formatMessage } = useI18n();

  const classes = useStyles();

  const { qrScanner, showDialog } = useViewContext();

  const toggleShowQR = async () => {
    try {
      const response = await qrScanner();

      if (mountRef.current.isMounted) {
        if (response != null) {
          const qrConfirm = getQrConfirm(response);
          if (qrConfirm != null) {
            onAgencyReferenceChange(qrConfirm.agencyReference || "");
            onContactChange({ name: qrConfirm.name || "", phone: contact?.phone || "" });
          } else {
            showDialog(
              formatMessage("confirmFormView.qrFormat.message"),
              formatMessage("confirmFormView.qrFormat.title")
            );
          }
        }
      }
    } catch (error) {
      if (mountRef.current.isMounted) {
        const errorText = typeof error === "object" ? JSON.stringify(error) : error;
        showDialog(
          formatMessage("confirmFormView.qrError.message", { errorText }),
          formatMessage("confirmFormView.qrError.title")
        );
      }
    }
  };

  return (
    <>
      <Paper elevation={1} className={classes.rootPaper}>
        {showAgencyReference && (
          <div className={classes.qrRow}>
            <Button onClick={toggleShowQR}>
              <QRCodeScan color="action" />
              <span className={classes.importData}>{formatMessage("confirmFormView.importData")}</span>
            </Button>
          </div>
        )}

        <div className={classes.fixedSize}>
          <ContactView contact={contact} onChangeContact={onContactChange} />
        </div>

        {showAgencyReference && (
          <div className={classes.fixedSize}>
            <AgencyView
              agencyReference={agencyReference}
              onAgencyReferenceChange={onAgencyReferenceChange}
              isMandatoryAgencyReference={isMandatoryAgencyReference}
            />
          </div>
        )}

        <div className={clsx(classes.fixedSize, classes.paymentView)}>
          <PaymentView disableReference={enableMPos} payment={payment} onPaymentChange={onPaymentChange} />
        </div>
      </Paper>
    </>
  );
};

export default ConfirmDataView;
