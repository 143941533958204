import { Reducer } from "redux";

import { AddExcursionViewAction } from "./actions";

import { RESET, SET_RANGE_TICKET_OPTIONS, SET_TICKET_OPTION, SET_EXCURSION } from "~/views/AddExcursionView/actions";

import { AddExcursionViewState } from "./types";

const defaultState: AddExcursionViewState = {
  ready: false,
};

/** Reducer */
const reducer: Reducer<AddExcursionViewState, AddExcursionViewAction> = (state = defaultState, action) => {
  switch (action.type) {
    case RESET:
      return defaultState;

    case SET_EXCURSION:
      return { ...state, excursion: action.excursion, ready: true };

    case SET_RANGE_TICKET_OPTIONS:
      return { ...state, rangeAvailability: action.rangeAvailabitliy };

    case SET_TICKET_OPTION:
      return { ...state, excursionTicketOption: action.excursionTicketOption };

    default:
      return state;
  }
};

export default reducer;
