import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { startNewBooking, updatePrintCount } from "~/services/booking";
import logger from "~/services/logger";
import { get as apiGetBooking, updatePrintCount as apiUpdatePrintCount } from "~/services/webapi/bookings";
import { getVoucherAsPNG as apiGetVoucher } from "~/services/webapi/payment";
import { Booking, TicketConfig } from "~/services/webapi/types";
import { AppState } from "~/state";

import { loadView } from "../actions";
import { ViewId } from "../types";
import { printTicket as actionPrintTicket, printVoucherImage } from "./printTicket";

export const onNewBooking = (): ThunkAction<void, AppState, void, Action> => async dispatch => {
  dispatch(loadView(ViewId.searcher, undefined, true));
  dispatch(startNewBooking());
};

export const printOneTicket = (
  booking: Booking,
  ticketNumber: string
): ThunkAction<Promise<void>, AppState, void, Action> => async (dispatch, getState) => {
  const state = getState();
  const ticketConfig = state.config.appConfig?.ticketConfig;
  await printTicket(booking, ticketNumber, ticketConfig);
  dispatch(updatePrintCount(booking.bookingNumber, ticketNumber));
};

async function printTicket(booking: Booking, ticketNumber: string, config?: TicketConfig | null): Promise<void> {
  let ticket = booking.tickets.find(tk => tk.ticketNumber === ticketNumber);

  if (ticket) {
    logger.info(`PrintTicket ${ticketNumber}`);
    if (ticket.status === "CANCELLED") {
      const cancellationBookingNumber = ticket.cancellationBookingNumber;
      const cancellationTicketNumber = ticket.cancellationTicketNumber;

      if (cancellationBookingNumber != null && cancellationTicketNumber != null) {
        logger.info(`PrintTicket cancellation: ${cancellationBookingNumber}/${cancellationTicketNumber}`);

        /*
         * Si el ticket está anulado, entonces debemos recuperar el ticket de
         * anulación e imprimir ese.
         * Buscamos si el ticket de anulación está incluido en esta reserva.
         * Si no lo está, lo recuperamos del api.
         */
        let annulation;
        if (booking.bookingNumber === cancellationBookingNumber) {
          annulation = booking;
        } else {
          annulation = await apiGetBooking(cancellationBookingNumber).catch(() => {
            /* No hacer nada, se trata más abajo. */
          });
        }

        if (annulation != null) {
          booking = annulation;
          ticket = annulation.tickets.find(tk => tk.ticketNumber === cancellationTicketNumber);

          if (ticket == null) {
            logger.error(`Cancellation ticket not found: ${cancellationBookingNumber}/${cancellationTicketNumber}`);

            return;
          }
        } else {
          logger.error(`Unable to retrieve ticket: ${cancellationBookingNumber}`);

          return;
        }
      } else {
        logger.error(`Cancelled ticket without cancellation reference: ${booking.bookingNumber}/${ticketNumber}`);

        return;
      }
    }

    if (booking != null && ticket != null) {
      try {
        await actionPrintTicket(booking, ticket, config);

        /**
         * Actualiza el número de impresiones del ticket.
         */
        await apiUpdatePrintCount(booking.bookingNumber, ticketNumber).catch(() => {
          /* No tratamos este error, permitimos que se pueda continuar. */
        });

        return;
      } catch (error) {
        logger.error("Error on printTicket", error);

        // TODO: Si falla aquí (que no debería nunca) notificarlo en la propia vista. No ir a error.
        return;
      }
    }
  } else {
    logger.error(`Ticket not found on booking: ${booking.bookingNumber}/${ticketNumber}`);
  }
}

export const printAllTickets = (booking: Booking): ThunkAction<void, AppState, void, Action> => async dispatch => {
  for (const ticket of booking.tickets) {
    /* No falla porque cada printTicket ya trata el error. */
    if (ticket.status === "CONFIRMED") {
      await dispatch(printOneTicket(booking, ticket.ticketNumber));
    }
  }
};

/**
 * Acción (no de redux realmente) para imprimir el recibo del tpv.
 * @param voucherId id del recibo a imprimir.
 */
export async function printVoucher(voucherId: number): Promise<void> {
  try {
    const image = await apiGetVoucher(voucherId);
    await printVoucherImage(image);
  } catch (error) {
    logger.error("Unable to print voucher", error);

    // TODO: Tratar error bien.
    throw error;
  }
}
