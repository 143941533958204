import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useI18n } from "~/services/i18n";

const useStyles = makeStyles(theme =>
  createStyles({
    cell: {
      "&:first-child": {
        paddingLeft: theme.spacing(0.5),
      },
      "&:last-child": {
        paddingRight: theme.spacing(0.5),
      },
      height: "3.5em",
      padding: theme.spacing(0.5),
      position: "relative",
      textAlign: "center",
      width: "3.5em",
    },
  })
);

const CalendarHeader: React.FC = () => {
  const { formatMessage } = useI18n();

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.cell}>{formatMessage("availabilityCalendar.weekDayShort.monday")}</TableCell>
        <TableCell className={classes.cell}>{formatMessage("availabilityCalendar.weekDayShort.tuesday")}</TableCell>
        <TableCell className={classes.cell}>{formatMessage("availabilityCalendar.weekDayShort.wednesday")}</TableCell>
        <TableCell className={classes.cell}>{formatMessage("availabilityCalendar.weekDayShort.thursday")}</TableCell>
        <TableCell className={classes.cell}>{formatMessage("availabilityCalendar.weekDayShort.friday")}</TableCell>
        <TableCell className={classes.cell}>{formatMessage("availabilityCalendar.weekDayShort.saturday")}</TableCell>
        <TableCell className={classes.cell}>{formatMessage("availabilityCalendar.weekDayShort.sunday")}</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CalendarHeader;
