import { LocalDateTime } from "js-joda";

import { formatCurrency, formatLocalDateTime, formatMessage, formatNumber } from "~/services/i18n/i18nApiImpl";

import {
  buildPrintColumnsText,
  buildPrintString,
  cancelBold,
  print,
  printAndFeed,
  PrintCall,
  setBold,
} from "~/services/printer";

import { Alignment } from "~/services/printer/types";

import { Liquidation, LiquidationDetail, LiquidationReport } from "~/services/webapi/types";

const UNICODE_SCISSORS = "\u2702";
const SCISSORS_LINE: string = new Array(10).fill(UNICODE_SCISSORS).join(" ");

const buildPrintLabelDataRow = (label: string, value: string) =>
  buildPrintColumnsText([`${label}:`, value], [20, 12], [Alignment.LEFT, Alignment.RIGHT]);

const CONTINUOUS_LINE_CHAR = "\u2500";
const SEPARATOR_LINE: string = new Array(32).fill(CONTINUOUS_LINE_CHAR).join("");
const buildPrintSeparatorLine = (): PrintCall => buildPrintString(`${SEPARATOR_LINE}\n`);

/**
 * Imprime un informe de liquidación.
 */
export async function printLiquidation(liquidationReport: LiquidationReport): Promise<void> {
  const printCalls: PrintCall[] = [];

  addHeader(liquidationReport, printCalls);
  addSeparator(printCalls);
  addLiquidations(liquidationReport.liquidations, printCalls);
  addTotalTickets(liquidationReport, printCalls);
  addSeparator(printCalls);
  addPrintTime(printCalls);
  printCalls.push(printAndFeed(20), buildPrintString(SCISSORS_LINE));

  await print({ headingWhiteLines: 1, trailingWhiteLines: 4 }, ...printCalls);
}

/**
 * Añade las liquidaciones del report.
 */
function addLiquidations(liquidations: Liquidation[], printCalls: PrintCall[]) {
  if (liquidations && liquidations.length > 0) {
    for (const liquidation of liquidations) {
      addDetails(liquidation, printCalls);
      addSeparator(printCalls);
    }
  }
}

/**
 * Añade la fecha de impresión del infome de liquidación
 */
function addPrintTime(printCalls: PrintCall[]) {
  printCalls.push(
    buildPrintString(
      formatMessage({ id: "printLiquidation.printTime" }, { time: formatLocalDateTime(LocalDateTime.now()) }) + "\n"
    )
  );
}

/**
 * Añade una línea para separar contenido.
 */
function addSeparator(printCalls: PrintCall[]) {
  printCalls.push(buildPrintSeparatorLine());
}

/**
 * Añade el total de tickets gestionados en el infome de liquidación
 */
function addTotalTickets(liquidationReport: LiquidationReport, printCalls: PrintCall[]) {
  printCalls.push(
    buildPrintLabelDataRow(
      formatMessage("printLiquidation.ticketsSold"),
      formatNumber(liquidationReport.soldTicketsCount)
    ),
    buildPrintLabelDataRow(
      formatMessage("printLiquidation.ticketsCancelled"),
      formatNumber(liquidationReport.cancelledTicketsCount)
    )
  );
}

/**
 * Añade cada uno de los detalles del infome de liquidación
 */
function addDetails(liquidation: Liquidation, printCalls: PrintCall[]) {
  /** Detalle ventas en metálico */
  addDetail(liquidation.cash, formatMessage("printLiquidation.paymentType.cash"), printCalls);
  printCalls.push(buildPrintString("\n"));
  /** Detalle ventas con tarjeta */
  addDetail(liquidation.card, formatMessage("printLiquidation.paymentType.card"), printCalls);
  printCalls.push(buildPrintString("\n"));
  /** Detalle con todas las ventas realizadas */
  addDetail(liquidation.total, formatMessage("printLiquidation.paymentType.general"), printCalls);
}

/**
 * Añade la información de un detalle del infome de liquidación
 */
function addDetail(detail: LiquidationDetail, title: string, printCalls: PrintCall[]) {
  printCalls.push(
    buildPrintString(`${title}`),
    buildPrintString("\n"),
    buildPrintLabelDataRow(
      `  ${formatMessage("printLiquidation.detail.sales")}`,
      formatCurrency(detail.salesAmount, detail.currency)
    ),
    buildPrintLabelDataRow(
      `  ${formatMessage("printLiquidation.detail.refunds")}`,
      formatCurrency(detail.cancellationsAmount, detail.currency)
    ),
    setBold(),
    buildPrintLabelDataRow(
      `  ${formatMessage("printLiquidation.detail.total")}`,
      formatCurrency(detail.totalAmount, detail.currency)
    ),
    cancelBold()
  );
}

/**
 * Añade la cabecera del infome de liquidación
 */
function addHeader(liquidationReport: LiquidationReport, printCalls: PrintCall[]) {
  printCalls.push(buildPrintString(liquidationReport.seller.name + "\n"));
  printCalls.push(buildPrintString(liquidationReport.terminalId + "\n"));

  printCalls.push(
    buildPrintString(formatMessage({ id: "printLiquidation.dateFrom" }, { dateFrom: liquidationReport.dateFrom })),
    buildPrintString("\n"),
    buildPrintString(formatMessage({ id: "printLiquidation.dateTo" }, { dateTo: liquidationReport.dateTo })),
    buildPrintString("\n")
  );
}
