/* Etiquetas multiidoma en inglés. */
export default {
  "printLiquidation.dateFrom": "From: {dateFrom}",
  "printLiquidation.dateTo": "To: {dateTo}",
  "printLiquidation.detail.sales": "Sales",
  "printLiquidation.detail.refunds": "Refunds",
  "printLiquidation.detail.total": "Total",
  "printLiquidation.paymentType.card": "Card:",
  "printLiquidation.paymentType.cash": "Cash:",
  "printLiquidation.paymentType.general": "General:",
  "printLiquidation.printTime": "Printed: {time}",
  "printLiquidation.ticketsSold": "No. tickets sold",
  "printLiquidation.ticketsCancelled": "No. tickets cancelled",
};
