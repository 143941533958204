import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { PaymentResult } from "~/services/mpos";
import { charge } from "~/services/mpos/redsys";
import { Booking, Contact, Discount, Payment } from "~/services/webapi/types";
import { AppState } from "~/state";
import { getDevice } from "~/utils/device";
import { useViewContext, wrap as viewWrapper } from "~/views/ViewWrapper";
import { loadView } from "~/views/actions";
import { ViewId } from "~/views/types";

import {
  cancelBooking,
  postBookingConfirm,
  postTicketCancel,
  setAgencyReference,
  setContact,
  setDiscount,
  setPayment,
} from "./actions";
import ConfirmBookingView, { Props } from "./ConfirmBookingView";

/** Propiedades derivadas del estado redux. */
type StateProps = Pick<
  Props,
  | "agencyName"
  | "agencyReference"
  | "booking"
  | "bookingDiscount"
  | "bookingDiscounts"
  | "canConfirm"
  | "contact"
  | "enableMPos"
  | "isMandatoryAgencyReference"
  | "payment"
  | "showAgencyReference"
  | "ticketDiscount"
  | "ticketDiscounts"
>;

/** Redux state to props. */
function statePropsSelector(state: AppState): StateProps | undefined {
  if (!state.bookingProcess?.booking) {
    return undefined;
  }

  const booking = state.bookingProcess.booking;
  const restrictions = booking.restrictions;

  const agencyReferenceRes = restrictions?.fieldConfigs?.find(t => t.name === "AGENCYREFERENCE");
  const showAgencyReference = agencyReferenceRes?.config !== "NEVER";
  const isMandatoryAgencyReference = agencyReferenceRes?.config === "MANDATORY";

  const { agencyReference, payment, contact } = state.confirmBookingView;
  const canConfirm = Boolean(
    (payment?.type === "CASH" || payment?.type === "CARD") &&
      contact?.name &&
      booking &&
      booking.tickets &&
      booking.tickets.length > 0 &&
      (!isMandatoryAgencyReference || (agencyReference && agencyReference.length > 0))
  );

  return {
    agencyName: state.bookingProcess.agency?.name,
    agencyReference: agencyReference || "",
    booking,
    bookingDiscount: state.bookingProcess.bookingDiscount,
    bookingDiscounts: state.bookingProcess.bookingDiscounts,
    canConfirm,
    contact: contact || undefined,
    enableMPos: Boolean(state.config.appConfig?.paymentConfig?.mposEnabled),
    isMandatoryAgencyReference: isMandatoryAgencyReference,
    payment: payment as Payment | undefined,
    showAgencyReference: showAgencyReference,
    ticketDiscount: state.bookingProcess.ticketDiscount || undefined,
    ticketDiscounts: state.bookingProcess.ticketDiscounts || undefined,
  };
}

/**
 * Lógica de la vista ConfirmBooking.
 */
const ConfirmBookingContainer: React.FC = () => {
  const stateProps = useSelector(statePropsSelector);
  const dispatch = useDispatch();
  const { showDialog } = useViewContext();

  if (!stateProps) {
    /* Se controla que no fallemos si no se pueden extraer las props porque que
     * el estado no es válido. */
    // Aunque nunca debería pasar sería mejor poner algo más trabajado, algún día. */
    return <div>Invalid state</div>;
  }

  // TODO: [REDUX] Es un primer paso, pero hay que unificar esto. Un hook por ejemplo.
  const goToSearcher = () => dispatch(loadView(ViewId.searcher));
  const cancel = () => dispatch(cancelBooking());
  const cancelTicket = (ticketNumber: string) => dispatch(postTicketCancel(ticketNumber));
  const updateContact = (contact?: Contact) => dispatch(setContact(contact));
  const changeDiscount = (discount: Discount | null, ticketNumber?: string) =>
    dispatch(setDiscount(discount, ticketNumber));
  const changePayment = (payment?: Payment) => dispatch(setPayment(payment));
  const changeAgencyReference = (agencyReference?: string) => dispatch(setAgencyReference(agencyReference));

  const confirmBooking = async (booking: Booking) => {
    const { enableMPos, payment } = stateProps;

    let confirmError = false;

    if (enableMPos && payment?.type === "CARD") {
      const amount = booking.tickets.reduce((accumulator, { price: { totalPrice } }) => (accumulator += totalPrice), 0);

      const paymentResult = await charge(amount, booking.bookingNumber).catch<PaymentResult>(error => ({
        errorMessage: `ERROR: ${error}`,
      }));

      if (paymentResult?.authorizationCode) {
        booking.payment.reference = paymentResult.authorizationCode;
        booking.payment.voucherId = paymentResult.voucherId;
      } else {
        confirmError = true;
        // TODO i18n
        showDialog(paymentResult?.errorMessage || "No message from mPos", "Payment error");
      }
    }

    booking.terminalId = getDevice()?.serial || "UNKNOWN";

    if (!confirmError) {
      dispatch(postBookingConfirm(booking));
    }
  };

  const viewProps: Props = {
    ...stateProps,
    onAddMoreTickets: goToSearcher,
    onAgencyReferenceChange: changeAgencyReference,
    // TODO: Cambiar el nombre de la prop
    onBackToSearch: cancel,
    onCancel: cancelTicket,
    onContactChange: updateContact,
    onConfirm: confirmBooking,
    onDiscountChange: changeDiscount,
    onPaymentChange: changePayment,
  };

  return <ConfirmBookingView {...viewProps} />;
};

export default viewWrapper(ConfirmBookingContainer);
