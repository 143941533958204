import React, { useMemo, useState } from "react";

import { LocalTime, LocalDateTime } from "js-joda";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { TimePicker as MaterialTimePicker } from "@material-ui/pickers";

import IconButton from "@material-ui/core/IconButton";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

/** Estilos del componente. */
const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      padding: 0,
    },
    root: {
      alignItems: "baseline",
      display: "flex",
      flex: "1 1 auto",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  })
);

/** Propiedades del componente */
interface TimePickerProps {
  /** Hora en formato HH:mm */
  time: string | null;

  /** Etiqueta. */
  label?: string;

  /** Handler para tratar cuando cambia el valor. */
  onChange?: (time?: LocalTime) => void;
}

const TimePicker: React.FC<TimePickerProps> = props => {
  const { label, onChange, time: timeProp } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const classes = useStyles();

  const timeFormat = useMemo(() => formatTimeToISO(timeProp), [timeProp]);

  /* Callbacks */
  const onDateChange = (date: MaterialUiPickersDate | null) => {
    if (onChange && date) {
      const time = LocalTime.of(date.hour, date.minute, 0);
      onChange(time);
    }
  };

  /* Render */
  return (
    <div className={classes.root}>
      <MaterialTimePicker
        fullWidth={true}
        margin="normal"
        id="time-picker"
        label={label}
        format="HH:mm"
        value={timeFormat}
        onChange={onDateChange}
        ampm={false}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        placeholder={label}
        readOnly={false}
      />
      <IconButton className={classes.icon} onClick={() => setIsOpen(true)}>
        <AccessTimeIcon />
      </IconButton>
    </div>
  );
};

/**
 * Crea un LocalDateTime con la hora indicada y lo devuelve en formato ISO.
 * @param time Hora a formatear
 */
function formatTimeToISO(time: string | null) {
  if (time) {
    let dateL = LocalDateTime.now();
    const timeL = LocalTime.parse(time);
    dateL = dateL.withHour(timeL.hour()).withMinute(timeL.minute());

    return dateL.toString();
  }

  return null;
}

export default TimePicker;
