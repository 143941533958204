/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";

import classnames from "classnames";

import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";

import { CustomTableCell, CustomTableRow } from "~/components/CustomTable";
import { Distribution } from "~/components/DistributionTable/types";
import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { I18nAPI } from "~/services/i18n/types";
import { PaxQuota } from "~/services/webapi/types";
import UnitsControl from "./components/UnitsControl";

/** Los estidos por defecto del componente. */
const styles = () =>
  createStyles({
    cancelled: {
      textDecoration: "line-through",
    },
    money: {
      textAlign: "right",
    },
    moneyHeader: {
      textAlign: "right",
    },
    summaryLabel: {
      textAlign: "right",
    },
    units: {
      textAlign: "center",
    },
    unitsHeader: {
      textAlign: "center",
    },
  });

/** Las propiedades del componente. */
interface Props extends WithStyles<typeof styles> {
  /** Estilo a aplicar al elemento principal */
  className?: string;

  /** Contiene la distribución de la reserva */
  distribution: Distribution;

  /** El precio máximo del ticket */
  maxAmount?: number;

  /**
   * Callback para el cambio de pasajero.
   * (Opcional) Si no se informa no se mostrarán los controles para modificar
   * el número de unidades.
   * @param paxType: tipo de pasajero
   * @param units: unidades seleccionadas
   */
  onPaxQuotaChange?: (paxType: string, units: number) => void;

  /** El número máximo de pax permitidos. */
  paxMax?: number;

  /** El número mínimo de pax permitidos. */
  paxMin?: number;
}

interface ProvidedProps extends Props, InjectedI18nProps {}

/**
 * Cabecera de la tabla de distribución de pasajeros.
 */
const PaxTableHead: React.SFC<Pick<ProvidedProps, "classes" | "i18n">> = ({ classes, i18n }) => (
  <TableHead>
    <CustomTableRow>
      <CustomTableCell>{i18n.formatMessage("distributionTable.head.passenger")}</CustomTableCell>
      <CustomTableCell className={classes.unitsHeader}>
        {i18n.formatMessage("distributionTable.head.units")}
      </CustomTableCell>
      <CustomTableCell className={classes.moneyHeader}>
        {i18n.formatMessage("distributionTable.head.unitaryPrice")}
      </CustomTableCell>
      <CustomTableCell className={classes.moneyHeader}>
        {i18n.formatMessage("distributionTable.head.totalAmount")}
      </CustomTableCell>
    </CustomTableRow>
  </TableHead>
);

/**
 * Píe de la tabla de distribución de pasajeros. Muestra los totales
 * seleccionados y las restricciones de distribución.
 */
const PaxTableFooter: React.SFC<Pick<
  ProvidedProps,
  "classes" | "distribution" | "i18n" | "maxAmount" | "paxMax" | "paxMin"
>> = props => {
  const {
    classes,
    distribution: { price },
    maxAmount,
    paxMax,
    paxMin,
  } = props;

  const restrictions = buildRestrictions(price.currency, props.i18n, maxAmount, paxMax, paxMin);

  return (
    <TableFooter>
      {price.discountAmount != null && price.discountAmount !== 0 && (
        <>
          <CustomTableRow>
            <CustomTableCell colSpan={3} className={classes.summaryLabel}>
              {props.i18n.formatMessage("distributionTable.footer.subtotal")}
            </CustomTableCell>
            <CustomTableCell className={classes.money}>
              {props.i18n.formatCurrency(price.basePrice, price.currency)}
            </CustomTableCell>
          </CustomTableRow>
          <CustomTableRow>
            <CustomTableCell colSpan={3} className={classes.summaryLabel}>
              {props.i18n.formatMessage("distributionTable.footer.discount")}
            </CustomTableCell>
            <CustomTableCell className={classes.money}>
              {props.i18n.formatCurrency(-price.discountAmount, price.currency)}
            </CustomTableCell>
          </CustomTableRow>
        </>
      )}
      <CustomTableRow>
        <CustomTableCell colSpan={2}>{restrictions}</CustomTableCell>
        <CustomTableCell className={classes.summaryLabel}>
          {props.i18n.formatMessage("distributionTable.footer.totalPrice")}
        </CustomTableCell>
        <CustomTableCell
          className={classnames(classes.money, { [classes.cancelled]: price.cancellationAmount != null })}
        >
          {props.i18n.formatCurrency(price.totalPrice, price.currency)}
        </CustomTableCell>
      </CustomTableRow>
      {price.cancellationAmount != null && price.cancellationAmount !== 0 && (
        <CustomTableRow>
          <CustomTableCell colSpan={3} className={classes.summaryLabel}>
            {props.i18n.formatMessage("distributionTable.footer.cancellation")}
          </CustomTableCell>
          <CustomTableCell className={classes.money}>
            {props.i18n.formatCurrency(price.cancellationAmount, price.currency)}
          </CustomTableCell>
        </CustomTableRow>
      )}
    </TableFooter>
  );
};

/**
 * Construye un texto con las restricciones que se aplican.
 */
function buildRestrictions(
  currency: string,
  i18n: I18nAPI,
  maxAmount: number | undefined,
  paxMax: number | undefined,
  paxMin: number | undefined
) {
  let restrictions = "";
  if (paxMin != null && paxMax != null) {
    restrictions = i18n.formatMessage("distributionTable.paxRestrictionFromTo", { paxMin, paxMax });
  } else if (paxMin != null) {
    restrictions = i18n.formatMessage("distributionTable.paxRestrictionFrom", { paxMin });
  } else if (paxMax != null) {
    restrictions = i18n.formatMessage("distributionTable.paxRestrictionTo", { paxMax });
  }

  if (maxAmount != null) {
    if (restrictions) {
      restrictions += " | ";
    }
    const amount = i18n.formatCurrency(maxAmount, currency);
    restrictions += i18n.formatMessage("distributionTable.amountRestriction", { amount });
  }

  return restrictions;
}

/**
 * Row con la información y selección de un tipo de pasajero. Recibe un handler
 * para tratar los eventos de modificación.
 */
const PaxRow: React.SFC<Pick<ProvidedProps, "classes" | "i18n" | "onPaxQuotaChange"> & {
  paxQuota: PaxQuota;
}> = props => {
  const {
    i18n,
    classes,
    paxQuota: { paxType, units },
    onPaxQuotaChange,
  } = props;

  return (
    <CustomTableRow>
      <CustomTableCell>{paxType.name}</CustomTableCell>
      <UnitsControl className={classes.units} onValueChange={onPaxQuotaChange} type={paxType.code} units={units} />
      <CustomTableCell className={classes.money}>
        {i18n.formatCurrency(paxType.price.amount, paxType.price.currency)}
      </CustomTableCell>
      <CustomTableCell className={classes.money}>
        {i18n.formatCurrency(units * Number(paxType.price.amount), paxType.price.currency)}
      </CustomTableCell>
    </CustomTableRow>
  );
};

/**
 * Componente para pintar el detalle (distribución e importe por pax) de la
 * reserva de un ticket.
 */
class DistributionTable extends React.PureComponent<ProvidedProps> {
  /** #render */
  public render() {
    const {
      classes,
      distribution,
      distribution: { paxes },
      i18n,
      maxAmount,
      onPaxQuotaChange,
      paxMax,
      paxMin,
    } = this.props;

    return (
      <Table className={this.props.className}>
        <PaxTableHead classes={classes} i18n={i18n} />
        <TableBody>
          {paxes.map((paxQuota, index) => (
            <PaxRow key={index} i18n={i18n} paxQuota={paxQuota} classes={classes} onPaxQuotaChange={onPaxQuotaChange} />
          ))}
        </TableBody>
        <PaxTableFooter
          classes={classes}
          i18n={i18n}
          distribution={distribution}
          maxAmount={maxAmount}
          paxMax={paxMax}
          paxMin={paxMin}
        />
      </Table>
    );
  }
}

export default withI18n(withStyles(styles)(DistributionTable));
