import React, { useEffect, useRef, useState } from "react";

import Progress from "@material-ui/core/LinearProgress";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import logger from "~/services/logger";
import { addRequestTracker, removeRequestTracker, RequestTracker } from "~/services/webapi/client";

import { useI18n } from "~/services/i18n";

const useStyles = makeStyles(theme =>
  createStyles({
    backdrop: {
      backgroundColor: theme.palette.grey[100],
      height: "100%",
      left: 0,
      opacity: 0.68,
      position: "fixed",
      top: 0,
      width: "100%",
      // TODO: Llevar a theme
      zIndex: 10000,
    },
    content: {
      alignContent: "center",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    root: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      left: 0,
      position: "fixed",
      top: 0,
      width: "100%",
      // TODO: Llevar a theme
      zIndex: 10000,
    },
    text: {
      display: "block",
      minWidth: 100,
    },
    timeWarning: {
      fontWeight: 500,
      marginTop: theme.spacing(1.5),
    },
  })
);

/** Tiempo que transcurre hasta que se muestra un texto de espera al usuario. */
const TIMEOUT_WARNING = 25000;

const WaitingLayer: React.FC = () => {
  /** Número de procesos activos. */
  const [active, setActive] = useState(0);

  const [showTimeWarning, setShowTimeWarning] = useState(false);

  const timeId = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (active > 0 && timeId.current === null) {
      timeId.current = setTimeout(() => {
        setShowTimeWarning(active > 0);
      }, TIMEOUT_WARNING);
    } else if (active === 0 && timeId.current != null) {
      clearInterval(timeId.current);
      setShowTimeWarning(false);
      timeId.current = null;
    }
  }, [active]);

  useEffect(() => {
    const requestTracker: RequestTracker = status => {
      logger.trace(`trace: ${status}`);

      switch (status) {
        case "END":
          setActive(prev => Math.max(prev - 1, 0));
          break;

        case "START":
          setActive(prev => prev + 1);
          break;
      }
    };

    addRequestTracker(requestTracker);

    return () => {
      removeRequestTracker(requestTracker);
    };
  }, []);

  const { formatMessage } = useI18n();
  const classes = useStyles();

  return active > 0 ? (
    <>
      <div className={classes.backdrop}></div>
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="overline" className={classes.text}>
            {formatMessage("waitingLayer.loading")}
          </Typography>
          <Progress color="secondary" />
          {showTimeWarning && (
            <div className={classes.timeWarning}>
              <div>{formatMessage("waitingLayer.timeWarning.delay")}</div>
              <div>{formatMessage("waitingLayer.timeWarning.wait")}</div>
            </div>
          )}
        </div>
      </div>
    </>
  ) : null;
};

export default WaitingLayer;
