import React from "react";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import { useI18n } from "~/services/i18n";
import { PaymentType } from "~/services/webapi/types";

import { Payment } from "~/views/ConfirmBookingView/types";

/** Las propiedades del componente. */
interface Props {
  /** Indica si se debe deshabilitar el campo de referencia de pago. */
  disableReference?: boolean;

  /** Función invocada cuando cambian los datos de pago. */
  onPaymentChange: (payment?: Payment) => void;

  /** Los datos de pago de la reserva. */
  payment?: Payment;
}

const useStyles = makeStyles(() =>
  createStyles({
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  })
);

/**
 * Crea un objeto con la información de pago de la reserva.
 */
const createPayment = (paymentType?: PaymentType, paymentReference?: string): Payment | undefined => {
  if (!paymentType) {
    return undefined;
  }

  return { type: paymentType, reference: paymentReference ? paymentReference : undefined };
};

/**
 * Componente con la información de pago de la reserva.
 */
const PaymentView: React.FC<Props> = props => {
  const { disableReference, onPaymentChange, payment } = props;

  const { formatMessage } = useI18n();

  const classes = useStyles();

  /* Callbacks */
  const handlePaymentReferenceChange = (paymentReference?: string) => {
    onPaymentChange(createPayment(payment?.type, paymentReference));
  };

  const handlePaymentTypeChange = (paymentType?: PaymentType) => {
    onPaymentChange(createPayment(paymentType, payment?.reference));
  };

  return (
    <>
      <FormControl margin="none" fullWidth={true}>
        <FormLabel>{formatMessage("paymentView.paymentType")}</FormLabel>
        <RadioGroup
          className={classes.radioGroup}
          value={payment?.type}
          onChange={(event: React.ChangeEvent, value: string) => {
            handlePaymentTypeChange(value as PaymentType);
          }}
        >
          <FormControlLabel value="CASH" control={<Radio />} label={formatMessage("paymentView.paymentType.CASH")} />
          <FormControlLabel value="CARD" control={<Radio />} label={formatMessage("paymentView.paymentType.CARD")} />
        </RadioGroup>
      </FormControl>
      {!disableReference && (
        <TextField
          fullWidth={true}
          label={formatMessage("paymentView.paymentReference")}
          value={payment?.reference || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handlePaymentReferenceChange(event.target.value);
          }}
          disabled={payment?.type !== "CARD"}
          margin="dense"
        />
      )}
    </>
  );
};

export default PaymentView;
