import { LocalDate } from "js-joda";
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { softReset } from "~/actions";
import { addTicket, findTicketOptions, getExcursion, getTicketOption } from "~/services/booking";
import logger from "~/services/logger";
import { ApiError, ConnectionTimeout, HostUnreachable } from "~/services/webapi/client/types";
import { Excursion, ExcursionTicket, ExcursionTicketOption, LocalDateString } from "~/services/webapi/types";
import { AppState } from "~/state";

import { loadView, ViewId } from "~/views";

/** Definición de acciones */
export const RESET = "AddExcursionView/RESET";

export const SET_EXCURSION = "AddExcursion/SET_EXCURSION";
export const SET_TICKET_OPTION = "AddExcursion/SET_TICKET_OPTION";
export const SET_RANGE_TICKET_OPTIONS = "AddExcursion/SET_RANGE_TICKET_OPTIONS";

export interface Reset extends Action {
  type: typeof RESET;
}

export interface SetExcursion extends Action {
  excursion?: Excursion;
  type: typeof SET_EXCURSION;
}

export interface SetTicketOption extends Action {
  excursionTicketOption?: ExcursionTicketOption;
  type: typeof SET_TICKET_OPTION;
}

export interface SetRangeTicketOptions extends Action {
  rangeAvailabitliy: Map<LocalDateString, ExcursionTicketOption>;
  type: typeof SET_RANGE_TICKET_OPTIONS;
}

export type AddExcursionViewAction = Reset | SetExcursion | SetRangeTicketOptions | SetTicketOption;

/* Action creators. */

/**
 * Tratamiento genérico para los errores producidos en los thunks del módulo.
 *
 * @param error el error producido
 * @param resetState si se debe resetear el estado del a reserva
 * @param dispatch instancia del dispatch de redux
 */
// TODO Fix
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleError = (error: any, resetState: boolean, dispatch: ThunkDispatch<AppState, void, Action>) => {
  if (resetState) {
    dispatch(softReset());
  }

  if (error === HostUnreachable || error === ConnectionTimeout || error.type === ApiError) {
    /* Si es un error conocido, entonces lo tratamos. */
    dispatch(
      loadView(
        ViewId.error,
        {
          action: () => dispatch(loadView(ViewId.searcher, undefined, true)),
          error,
          message: error.type === ApiError && error.details ? error.details.message : undefined,
        },
        true
      )
    );
  } else {
    /*
     * Cualquier otro error se propaga. Creo que no deberíamos llegar aquí salvo
     * por errores de programación.
     */
    throw error;
  }
};

/** */
export const reset = () => ({ type: RESET });

/** */
export const setExcursion = (excursion: Excursion | undefined): SetExcursion => ({
  excursion,
  type: SET_EXCURSION,
});

/** */
export const setTicketOption = (excursionTicketOption: ExcursionTicketOption | undefined): SetTicketOption => ({
  excursionTicketOption,
  type: SET_TICKET_OPTION,
});

/** */
export const setRangeTicketOptions = (
  rangeAvailabitliy: SetRangeTicketOptions["rangeAvailabitliy"]
): SetRangeTicketOptions => ({ rangeAvailabitliy, type: SET_RANGE_TICKET_OPTIONS });

/** */
export const loadSearchView = (): ThunkAction<void, AppState, void, Action> => async dispatch => {
  dispatch(loadView(ViewId.searcher));
  dispatch(reset());
};

/** Genera la acción para realizar el fetch de la excursión seleccionada.*/
export const fetchExcursion = (
  excursionCode: string,
  modalityCode: string
): ThunkAction<void, AppState, void, Action> => async (dispatch, getState) => {
  try {
    const agency = getState().bookingProcess.agency;
    const agencyCode = agency ? agency.code : undefined;

    dispatch(reset());

    const excursion = await getExcursion(excursionCode, modalityCode, agencyCode);

    dispatch(setExcursion(excursion));
  } catch (error) {
    /*
     * Si esto falla, poco podemos hacer más que volver al buscador. Se trata
     * para no resetear todo el estado.
     */
    logger.error("Error on fetchExcursion", error);
    handleError(error, false, dispatch);
  }
};

/**
 * Genera la acción para realizar el fetch de la opción reservable para el día
 * indicado.
 */
export const fetchTicketOption = (
  excursionCode: string,
  modalityCode: string,
  date: LocalDate
): ThunkAction<void, AppState, void, Action> => async (dispatch, getState) => {
  try {
    const agency = getState().bookingProcess.agency;
    const agencyCode = agency ? agency.code : undefined;

    const ticketOption = await getTicketOption(excursionCode, modalityCode, agencyCode, date.toString());
    dispatch(setTicketOption(ticketOption));
  } catch (error) {
    logger.error("Error on getTicketOption", error);
    handleError(error, false, dispatch);
  }
};

/**
 * Genera la acción para realizar el fetch de un rango de días. Retornando el
 * Map con los resultados de la disponibildad
 * o null si se produce error.
 */
export const fetchTicketOptions = (
  excursionCode: string,
  modalityCode: string,
  from: LocalDate,
  to: LocalDate
): ThunkAction<void, AppState, void, Action> => async (dispatch, getState) => {
  const fromDate = from.toString();
  const toDate = to.toString();
  try {
    const agency = getState().bookingProcess.agency;
    const agencyCode = agency ? agency.code : undefined;

    const ticketOptions = await findTicketOptions(excursionCode, modalityCode, agencyCode, fromDate, toDate);

    const action = setRangeTicketOptions(
      ticketOptions.reduce(
        (map, value) => (value.date ? map.set(value.date.toString(), value) : map),
        new Map<LocalDateString, ExcursionTicketOption>()
      )
    );
    dispatch(action);
  } catch (error) {
    logger.error("Error on fetchTicketOptions", error);
    handleError(error, false, dispatch);
  }
};

export const createAddBookingAction = (
  ticket: Partial<ExcursionTicket>
): ThunkAction<void, AppState, void, Action> => async dispatch => {
  try {
    const booking = await dispatch(addTicket(ticket));

    if (booking != null) {
      dispatch(loadView(ViewId.confirmBooking, null, true));
      dispatch(reset());
    } else {
      /*
       * No debería darse. El servidor o contesta con reserva o da un error de
       * aglún tipo. Si llegamos aquí, es un error.
       */
      throw new Error("Bad response. Booking cannot be null");
    }
  } catch (error) {
    /*
     * Si se produce algún error manipulando la reserva, entonces siempre
     * nos cargamos el estado para invalidarla. No sabemos realmente cómo
     * habrá quedado en servidor.
     */
    logger.error("Error on addTicket", error);
    handleError(error, true, dispatch);
  }
};
