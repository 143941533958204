import client from "./client";
import { PaymentVoucher } from "./types";

/**
 * Envía un recibo de pago
 */
export async function postVoucher(voucher: PaymentVoucher): Promise<PaymentVoucher> {
  const response = await client.post("/payment-vouchers?withContent=false", voucher);

  return response.data;
}

/**
 * Recupera el recibo en formato PNG para imprimir
 */
export async function getVoucherAsPNG(voucherId: number): Promise<ArrayBuffer> {
  const response = await client.get(`/payment-vouchers/${voucherId}/content?format=img`, {
    responseType: "arraybuffer",
  });

  return response.data;
}
