import React, { useContext } from "react";

/** Contexto de las vistas. */
export interface ViewContext {
  /**
   * Muestra una diálogo para notificar un mensaje.
   * Devuelve una promesa que se resuelve cuando se cierra el diálogo.
   */
  showDialog: (text: string, title?: string) => Promise<void>;
  /**
   * Muestra un lector de códigos qr.
   * Devuelve una promesa que se resuelve por una de las siguientes causas:
   * Se ha leído el qr.
   * Se ha cerrado el componente.
   * Ha habido un error.
   */
  qrScanner: () => Promise<string | null>;
}

/** Instancia del contexto con un valor por defecto. */
export const ViewContextInstance = React.createContext<ViewContext>({
  showDialog: () => Promise.resolve(),
  qrScanner: () => Promise.resolve(null),
});

/** Hook para utilizar el contexto de la vista. */
export const useViewContext = () => useContext(ViewContextInstance);
