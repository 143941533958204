import { useContext } from "react";

import { i18nContext } from "./I18nProvider";

/**
 * Hook para acceder al las funciones de traducción.
 */
export default function useI18n() {
  return useContext(i18nContext);
}
