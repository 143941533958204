import React from "react";

import Button from "@material-ui/core/Button";

import QRIcon from "~/components/QRIcon";

import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  createStyles({
    button: {
      color: "white",
      minWidth: "unset",
      padding: "7px 8px",
    },
    iconButton: {
      color: "white",
    },
  })
);

/** Las propiedades del componente. */
interface QRButtonProps {
  /** Función invocada cuando se quiere leer un código qr. */
  onClickQR: () => void;
}

/**
 * Vista de un botón para abrir el lector qr.
 */
const QRButton: React.FC<QRButtonProps> = props => {
  const { onClickQR } = props;

  const classes = useStyles();

  return (
    <Button variant="contained" color="secondary" className={classes.button} onClick={onClickQR}>
      <QRIcon className={classes.iconButton} />
    </Button>
  );
};

export default QRButton;
