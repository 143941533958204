import React from "react";

import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import { LocalDate } from "js-joda";

import { useI18n } from "~/services/i18n";
import { ExcursionTicketOption, LocalDateString } from "~/services/webapi/types";
import { noop } from "~/utils";

const useStyles = makeStyles(theme =>
  createStyles({
    adjacentMonthCell: {
      backgroundColor: theme.palette.grey[200],
      opacity: 0.25,
    },
    /*
     * Este bloque tiene que ir en orden específico para precedencia de las
     * reglas css generadas.
     */
    availability: {
      alignItems: "center",
      borderRadius: "50%",
      boxShadow: theme.shadows[2],
      display: "inline-flex",
      height: "2em",
      justifyContent: "center",
      width: "2em",
      marginTop: "0.4em",
      flexDirection: "column",
      alignContent: "center",
    },
    availabilityNone: {
      backgroundColor: grey["200"],
      color: theme.palette.common.black,
    },
    availabilityLow: {
      backgroundColor: red["700"],
      color: theme.palette.common.white,
    },
    availabilityMedium: {
      backgroundColor: orange["800"],
      color: theme.palette.common.white,
    },
    availabilityHigh: {
      backgroundColor: green["700"],
      color: theme.palette.common.white,
    },
    cell: {
      "&:first-child": {
        paddingLeft: theme.spacing(0.5),
      },
      "&:last-child": {
        paddingRight: theme.spacing(0.5),
      },
      height: "3.5em",
      padding: theme.spacing(0.5),
      position: "relative",
      textAlign: "center",
      width: "3.5em",
    },
    currentDayCell: {},
    currentDayOfMonth: {
      color: theme.palette.text.primary,
    },
    dayOfMonth: {
      fontSize: "0.6rem",
      left: 4,
      position: "absolute",
      top: 2,
      color: theme.palette.text.primary,
    },
    disabledCell: {
      opacity: 0.4,
    },
    selectedDayOfMonth: {
      borderLeftColor: theme.palette.secondary.dark,
      borderLeftStyle: "solid",
      borderLeftWidth: 2,
      paddingLeft: 2,
    },
  })
);

export interface CalendarCellProps {
  /** El día que se visualiza. */
  day: LocalDate;

  /** Indica si la celda es del mes del día seleccionado. */
  isCurrentMonth: boolean;

  /** Indica si un día debe estar habilitado. */
  isEnabled?: (ticketOption: ExcursionTicketOption) => boolean;

  /** Indica si la celda es el día seleccionado del calendario. */
  isSelectedDay: boolean;

  /** Función invocada al seleccionar una celda. */
  onSelectDate: (date: LocalDate) => void;

  /** Mapa con los valores de disponibilidad para cada día. */
  values?: Map<LocalDateString, ExcursionTicketOption> | null;
}

/**
 * Vista de la celda de un calendario.
 */
const CalendarCell: React.FC<CalendarCellProps> = props => {
  const { day, isCurrentMonth, isEnabled, isSelectedDay, onSelectDate, values } = props;

  const classes = useStyles();
  const { formatMessage } = useI18n();

  const today = LocalDate.now();
  const option = values ? values.get(day.toString()) : undefined;
  const currentDay = day.equals(today);
  const pastDay = day.isBefore(today);

  let enabledDay = true;
  if (isEnabled && option) {
    enabledDay = isEnabled(option);
  }

  const available = option && option.available ? option.available : 0;
  const selectable = option != null && (available > 0 || option.freeSale) && !pastDay && enabledDay;
  const handler = selectable ? () => onSelectDate(day) : noop;

  const freeSaleStr = formatMessage({ id: "availabilityCalendar.freeSale" });

  return (
    <TableCell
      key={day.toString()}
      className={classNames(classes.cell, {
        [classes.adjacentMonthCell]: !isCurrentMonth,
        [classes.currentDayCell]: currentDay,
        [classes.disabledCell]: pastDay || !enabledDay,
      })}
      onClick={handler}
    >
      <Typography
        variant="caption"
        className={classNames(classes.dayOfMonth, {
          [classes.currentDayOfMonth]: currentDay,
          [classes.selectedDayOfMonth]: isSelectedDay,
        })}
      >
        {day.dayOfMonth()}
      </Typography>
      <Typography
        variant="body1"
        className={classNames(
          { [classes.availability]: option != null },
          { [classes.availabilityNone]: option && available === 0 },
          { [classes.availabilityLow]: option && available > 0 },
          { [classes.availabilityMedium]: option && available > 10 },
          { [classes.availabilityHigh]: option && (option.freeSale || available > 50) }
        )}
      >
        {option && (option.freeSale ? freeSaleStr : option.available)}
      </Typography>
    </TableCell>
  );
};

export default CalendarCell;
