/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix
/* eslint-disable react/prop-types */ // TODO Fix
/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

import React, { useCallback, useEffect, useRef, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import { useAsyncCancelableCallback as useAsyncCallback } from "~/hooks/asyncHooks";
import { Booking, Contact } from "~/services/webapi/types";

import BookingForm from "./components/BookingForm";

import BookingViewActions from "./components/BookingViewActions";
import BookingViewStatus from "./components/BookingViewStatus";
import RescheduleTicket from "./components/RescheduleTicketView/RescheduleController";
import TicketCancelPanel from "./components/TicketCancelPanel";
import { BookingViewMode } from "./types";

const useStyles = makeStyles(() =>
  createStyles({
    actionsPanel: {
      display: "flex",
      flex: "0 0",
      flexDirection: "column",
    },
    bookingPanel: {
      display: "flex",
      flex: "1 0",
      flexDirection: "column",
    },
    root: {
      display: "flex",
      flex: "1 0",
      flexDirection: "column",
      justifyContent: "space-between",
      position: "relative",
    },
    statusPanel: {
      position: "absolute",
      right: 0,
      top: 6,
    },
  })
);

export interface BookingViewProps {
  /** Indica si se permite cancelar tickets. */
  allowCancellation?: boolean;

  /** Indica si se permite editar la reserva. */
  allowEdition?: boolean;

  /** Indica si se permite reprogramar un ticket. */
  allowReschedule?: boolean;

  /** Indica si se permite iniciar una nueva reserva desde esta vista. */
  allowStartNewBooking?: boolean;

  /** La reserva a mostrar. */
  booking: Booking;

  /** Función para cancelar un ticket. */
  cancelTicket: (bookingNumber: string, ticketNumber: string) => Promise<any>;

  /** Función para recuperar una reserva. */
  getBooking: (bookingNumber: string) => Promise<Booking>;

  /** Función para inicializar el estado con la reserva a mostrar. */
  // XXX
  initBooking: (booking: Booking) => void;

  /** Función para imprimir todos los tickets de una reserva. */
  printAllTickets: (booking: Booking) => Promise<void>;

  /** Función para imprimir un ticket de la reserva. */
  printTicket: (booking: Booking, ticketNumber: string) => Promise<any>;

  /** Invocado cuando se queire imprimir un recibo tpv. */
  printVoucher: (voucherId: number) => void;

  /** Función para refrescar el estado con la reserva a mostrar. */
  refreshBooking: (booking: Booking) => void;

  /** Función para iniciar una nueva reserva. */
  startNewBooking: () => void;

  /** Función para actualizar un ticket. */
  updateBooking: (bookingData: Partial<Booking>) => Promise<any>;
}

/**
 * Pantalla de gestión de reserva.
 *
 * Se carga indicando un booking a mostrar. Es el varlo inicial para cargar el
 * estado. A partir de ahí, modificaciones de la prop booking no se tendrán en
 * cuenta.
 *
 * OJO: Por el momento...
 * No se implementa permitir cambiar el booking vía prop por como se usan las
 * vistas en la aplicación. Para cambiar de vista, mostrar otra reserva, el
 * componente se debe desmontar y montar uno nuevo con la reserva en cuestión.
 *
 * TODO: [CTX/REDUX] Hooks de contexto y redux para las props correspondientes
 *       Ctx para el propio estado intero de la vista y delegar en Redux el
 *       estado compartido o que debe persistir entre cambios de vista.
 */
const BookingView: React.FC<BookingViewProps> = props => {
  const {
    allowCancellation,
    allowEdition,
    allowReschedule,
    allowStartNewBooking,
    booking,
    cancelTicket,
    initBooking,
    printAllTickets: propPrintAllTickets,
    printTicket: propPrintTicket,
    printVoucher,
    refreshBooking,
    startNewBooking,
    updateBooking,
  } = props;

  /*
   * Estado
   */

  /* Instancia de la reserva con los datos del formulario. */
  // TODO: Crear useStateWithRef
  const [formBooking, setFormBooking] = useState<Booking>();
  const formBookingRef = useRef(formBooking);
  formBookingRef.current = formBooking;

  const [mode, setMode] = useState<BookingViewMode>("default");

  const [cancelTicketNumber, setCancelTicketNumber] = useState<string>();
  const [rescheduleTicketNumber, setRescheduleTicketNumber] = useState<string>();

  const classes = useStyles();

  /*
   * Efect
   */
  useEffect(() => {
    setFormBooking(undefined);
    setMode("default");
    initBooking(booking);
  }, [booking, initBooking]);

  /*
   * Callback
   */
  /* Combios de los modos de la vista. */
  const changeMode = useCallback(
    (newMode: BookingViewMode) => {
      setMode(newMode);

      switch (newMode) {
        case "default":
          setFormBooking(undefined);
          break;

        case "editing":
          /* No tamar como ejemplo. */
          setFormBooking(JSON.parse(JSON.stringify(booking)));
          break;
      }
    },
    [booking]
  );

  /* Callbacks de cancelación. */
  const initCancelTicket = useCallback(
    (bookingNumber: string, ticketNumber: string) => setCancelTicketNumber(ticketNumber),
    []
  );

  const closeCancelPanel = useCallback(() => setCancelTicketNumber(undefined), []);

  const printCancelledTicket = useCallback(() => cancelTicketNumber && propPrintTicket(booking, cancelTicketNumber), [
    booking,
    cancelTicketNumber,
    propPrintTicket,
  ]);

  /* Callbacks de edición. */
  const changeContactData = useCallback((field: keyof Contact, value: string) => {
    const formData = formBookingRef.current!;

    // XXX: Immutable.js me vendría muy bien para evitar esta construcción.
    const newFormBooking = {
      ...formData,
      contact: { ...formData.contact, [field]: value },
    };

    setFormBooking(newFormBooking);
  }, []);

  const changeTicketData = useCallback((ticketNumber: string, field: string, value: string) => {
    const formData = formBookingRef.current!;

    // XXX: Immutable.js me vendría muy bien para evitar esta construcción.
    const newFormBooking = {
      ...formData,
      tickets: formData.tickets.map(ticket =>
        ticketNumber === ticket.ticketNumber ? { ...ticket, [field]: value } : ticket
      ),
    };

    setFormBooking(newFormBooking);
  }, []);

  const editCancel = useCallback(() => setMode("default"), []);

  const editSave = useAsyncCallback(
    async () => await updateBooking(formBookingRef.current!),
    () => changeMode("default"),
    // TODO Mostrar error y traerse errorboudnary de extranet. Ahora no llegamos, salta el error global y recarga.
    () => changeMode("default"),
    [booking.bookingNumber, changeMode]
  );

  /* Callback de selección del ticket a reprogramar.  */
  const reschedule = useCallback((ticketNumber: string) => setRescheduleTicketNumber(ticketNumber), []);

  /* Callback de fin de reprogramación de un ticket. */
  const closeReschedulePanel = useCallback(() => setRescheduleTicketNumber(undefined), []);

  /*
   * Render
   */
  const isEdititing = mode === "editing";

  return (
    <>
      {rescheduleTicketNumber && (
        <RescheduleTicket
          bookingNumber={booking.bookingNumber}
          onBookingChange={refreshBooking}
          ticketNumber={rescheduleTicketNumber}
          onClose={closeReschedulePanel}
        />
      )}
      {cancelTicketNumber && (
        <TicketCancelPanel
          bookingNumber={booking.bookingNumber}
          ticketNumber={cancelTicketNumber}
          cancelTicket={cancelTicket}
          onClose={closeCancelPanel}
          onPrint={printCancelledTicket} // FIXME: Pasar a acción
        />
      )}
      <div className={classes.root}>
        <div className={classes.statusPanel}>
          <BookingViewStatus mode={mode} />
        </div>

        <div className={classes.bookingPanel}>
          <BookingForm
            booking={isEdititing ? formBooking! : booking}
            editionEnabled={isEdititing}
            onCancelTicket={allowCancellation ? initCancelTicket : undefined}
            onChangeContact={changeContactData}
            onChangeTicket={changeTicketData}
            onPrintAllTickets={propPrintAllTickets}
            onPrintTicket={propPrintTicket}
            onPrintVoucher={printVoucher}
            onRescheduleTicket={allowReschedule ? reschedule : undefined}
          />
        </div>

        <div className={classes.actionsPanel}>
          <BookingViewActions
            allowEdition={allowEdition}
            allowStartNewBooking={allowStartNewBooking}
            booking={booking}
            formBooking={formBooking}
            mode={mode}
            onChangeMode={changeMode}
            onEditCancel={editCancel}
            onEditSave={editSave}
            onStartNewBooking={startNewBooking}
          />
        </div>
      </div>
    </>
  );
};

export default BookingView;
