/* Etiquetas multiidoma en castellano. */
export default {
  "printTicket.agency": "Agency",
  "printTicket.agent": "Agent",
  "printTicket.annulation": "ANNULATION",
  "printTicket.cancellationPolicy": "Cancellation policy",
  "printTicket.contact": "Name",
  "printTicket.excursionDate": "Date",
  "printTicket.hotel": "Hotel",
  "printTicket.hotelRoom": "Room no.",
  "printTicket.paxType.ADULTO": "ADT",
  "printTicket.paxType.BEBE": "INF",
  "printTicket.paxType.JUNIOR": "JUN",
  "printTicket.paxType.NIÑO": "CHD",
  "printTicket.paxType.SENIOR": "SEN",
  "printTicket.payment": "Payment type: {payment}",
  "printTicket.paymentType.CARD": "Card",
  "printTicket.paymentType.CASH": "Cash",
  "printTicket.payment.reference": "Reference: {reference}",
  "printTicket.pickUpPointName": "Pick Up",
  "printTicket.pickUpPointTime": "Time",
  "printTicket.price.discount": "Discount",
  "printTicket.price.subtotal": "Subtotal",
  "printTicket.price.total": "TOTAL",
  "printTicket.printTime": "Printed: {time}",
  "printTicket.reprint": "** REPRINTED TICKET **",
  "printTicket.reservationDate": "Date",
  "printTicket.reservationNumber": "Loc.",
  "printTicket.ticketNumber": "Ticket",
};
