import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { AppState } from "~/state";
import logger from "../logger";
import { authenticate as apiAuth, deauthenticate as apiDeauth } from "../webapi/authentication";
import { AuthUser } from "../webapi/types";
import { onAuthHandler } from "./types";

/*
 * Constantes (no acciones)
 */
const KEY_USERNAME = "Auth/USERNAME";
const KEY_PASSWORD = "Auth/PASSWORD";

const ON_AUTH_HANDLERS: onAuthHandler[] = [];

/** Registra un handler en el evento de autenticar */
export function registerOnAuth(handler: onAuthHandler) {
  if (ON_AUTH_HANDLERS.indexOf(handler) === -1) {
    ON_AUTH_HANDLERS.push(handler);
  }
}

/** Desregistra un handler en el evento de autenticar */
export function unregisterOnAuth(handler: onAuthHandler) {
  const index = ON_AUTH_HANDLERS.indexOf(handler);
  if (index > -1) {
    ON_AUTH_HANDLERS.splice(index, 1);
  }
}

/*
 * Definición de acciones
 */
export const SET_USER = "Auth/SET_USER";

/** Acción para establecer el usuario autenticado. */
interface SetAuthenticatedUser extends Action {
  type: typeof SET_USER;
  user: AuthUser | null;
}

/** Acciones del módulo. */
export type AuthAction = SetAuthenticatedUser;

/*
 * Aciones finales
 */

/**
 * Genera la acción para establecer el usuario autenticado.
 *
 * @param user   el usuario autenticado
 */
const setAuthenticatedUser = (user: AuthUser | null) => ({ user, type: SET_USER });

/**
 * Genera la acción para desautenticar el usuario autenticado.
 */
export const deauthenticate = () => {
  apiDeauth();

  localStorage.removeItem(KEY_USERNAME);
  localStorage.removeItem(KEY_PASSWORD);

  return setAuthenticatedUser(null);
};

/*
 * Thunks
 */

/**
 * Genera la acción para autenticar al usuario.
 * En caso de ir bien, guardará en el estado el usuario autenticado y persistirá
 * las credenciales para poder autenticar de forma automática. Si va mal,
 * elimina al usuario del estado (en caso de existir).
 *
 * @param username   el nombre de usuario
 * @param password   la constraseña del usuario
 *
 * @returns el AuthUser autenticado o null si va mal.
 * @throws Error si falla la petición.
 */
export const authenticate = (
  username: string,
  password: string
): ThunkAction<Promise<AuthUser | null>, AppState, void, Action> => async dispatch => {
  const user = await apiAuth(username, password);

  if (user != null) {
    dispatch(setAuthenticatedUser(user));
    localStorage.setItem(KEY_USERNAME, username);
    localStorage.setItem(KEY_PASSWORD, password);

    for (const onAuth of ON_AUTH_HANDLERS) {
      try {
        await dispatch(onAuth(user));
      } catch (err) {
        logger.error("Erron calling onAuth handler", err);
        throw err;
      }
    }
  }

  return user;
};

/**
 * Genera la acción para autenticar al usuario con las credenciales guardadas.
 * En caso de ir bien, guardará en el estado el usuario autenticado. Si va mal,
 * elimina al usuario del estado (en caso de existir).
 *
 * @param username   el nombre de usuario
 * @param password   la constraseña del usuario
 *
 * @returns el AuthUser autenticado o null si va mal.
 * @throws Error si falla la petición.
 */
export const autoAuthenticate = (): ThunkAction<Promise<AuthUser | null>, AppState, void, Action> => async dispatch => {
  const username = localStorage.getItem(KEY_USERNAME);
  const password = localStorage.getItem(KEY_PASSWORD);

  if (username && password) {
    return dispatch(authenticate(username, password));
  }

  return null;
};
