import React, { useState } from "react";

import { LocalDate, LocalTime, LocalDateTime } from "js-joda";

import { useDispatch, useSelector } from "react-redux";

import { useMountRef } from "~/hooks/asyncHooks";
import { wrap as viewWrapper } from "~/views/ViewWrapper";
import { formatLocalDateString, formatLocalDateTimeString } from "~/services/i18n/i18nApiImpl";
import { LiquidationReport } from "~/services/webapi/types";
import { AppState } from "~/state";

import LiquidationReportView, { LiquidationReportProps } from "./LiquidationReportView";
import { getLiquidationReport, printLiquidationReport, setCriteria } from "./slice";
import { SearchCriteria } from "./types";

/* Patrón parseo LocalDateTime sin segundos */
const pattern = "dd/MM/yyyy HH:mm";

const LiquidationReportContainer: React.FC = () => {
  const mountRef = useMountRef();
  const [data, setData] = useState<LiquidationReport>();

  /* Callbacks */
  const criteria = useSelector((state: AppState) => state.liquidationReportView.searchCriteria);

  const dispatch = useDispatch();

  const search = (criteria: SearchCriteria) => {
    dispatch(setCriteria(criteria));

    const dateFrom = LocalDate.parse(criteria.dateFrom);
    let dateTimeFrom = dateFrom.atStartOfDay();
    if (criteria.timeFrom) {
      dateTimeFrom = dateFrom.atTime(LocalTime.parse(criteria.timeFrom));
    }

    const dateTo = LocalDate.parse(criteria.dateTo);
    let dateTimeTo = dateTo.atStartOfDay();
    if (criteria.timeTo) {
      dateTimeTo = dateTo.atTime(LocalTime.parse(criteria.timeTo));
    } else {
      dateTimeTo = dateTimeTo.plusDays(1);
    }

    getLiquidationReport(criteria.terminalId, dateTimeFrom.toString(), dateTimeTo.toString()).then(data => {
      if (mountRef.current.isMounted) {
        if (criteria.timeFrom) {
          data.dateFrom = formatLocalDateTimeString(data.dateFrom, pattern);
        } else {
          data.dateFrom = formatLocalDateString(data.dateFrom);
        }

        if (criteria.timeTo) {
          data.dateTo = formatLocalDateTimeString(data.dateTo, pattern);
        } else {
          data.dateTo = formatLocalDateString(
            LocalDateTime.parse(data.dateTo)
              .minusDays(1)
              .toString()
          );
        }

        setData(data);
      }
    });
  };

  const printLiquidation = () => data && printLiquidationReport(data);

  const viewProps: LiquidationReportProps = {
    searchCriteria: criteria,
    liquidation: data,
    onSearch: search,
    onPrintLiquidation: printLiquidation,
  };

  return <LiquidationReportView {...viewProps} />;
};

export default viewWrapper(LiquidationReportContainer);
