import * as React from "react";

import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const CalendarArrowRight: React.SFC<SvgIconProps> = props => (
  <SvgIcon {...props}>
    {/* eslint-disable-next-line max-len */}
    <path d="M19 3H18V1H16V3H8V1H6V3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.89 20.1 3 19 3M19 19H5V8H19V19M12 17V15H8V12H12V10L16 13.5L12 17Z" />
  </SvgIcon>
);

export default CalendarArrowRight;
