/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import { LocalDateTime } from "js-joda";

import {
  existMessage,
  formatCurrency,
  formatLocalDateString,
  formatLocalDateTime,
  formatLocalDateTimeString,
  formatLocalTimeString,
  formatMessage,
  formatWeekdayName,
} from "~/services/i18n/i18nApiImpl";

import {
  buildPrintBarcode,
  buildPrintColumnsText,
  buildPrintImage,
  buildPrintQR,
  buildPrintString,
  cancelBold,
  print,
  printAndFeed,
  PrintCall,
  resetAlignment,
  setAlignment,
  setBold,
} from "~/services/printer";
import { Alignment } from "~/services/printer/types";

import { Booking, ExcursionTicket, TicketConfig } from "~/services/webapi/types";

const UNICODE_SCISSORS = "\u2702";
const SCISSORS_LINE: string = new Array(10).fill(UNICODE_SCISSORS).join(" ");

const splitString = (text: string, lineLength: number) => {
  const result: string[] = [];
  let line = "";
  for (const word of text.split(" ")) {
    if (line.length + word.length < lineLength) {
      line += " " + word;
    } else {
      if (line.length > 0) {
        result.push(line);
        line = word;
      } else {
        result.push(word);
      }
    }
  }
  if (line.length > 0) {
    result.push(line);
  }

  return result;
};

const buildPrintLabelDataRow = (labelId: string, value: string): PrintCall =>
  buildPrintColumnsText(
    [formatMessage({ id: labelId }), ": ", value],
    [8, 2, 22],
    [Alignment.LEFT, Alignment.LEFT, Alignment.LEFT]
  );

const CONTINUOUS_LINE_CHAR = "\u2500";
const SEPARATOR_LINE: string = new Array(32).fill(CONTINUOUS_LINE_CHAR).join("");

const buildPrintSeparatorLine = (): PrintCall => buildPrintString(`${SEPARATOR_LINE}\n`);

export const printTicket = (booking: Booking, ticket: ExcursionTicket, config?: TicketConfig | null): Promise<void> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = async () => {
      try {
        const printCalls: PrintCall[] = [];
        printCalls.push(buildPrintImage(image), printAndFeed(3));

        if (config != null && config.header != null && config.header.lines != null) {
          for (const line of config.header.lines) {
            printCalls.push(buildPrintString(`${line}\n`));
          }
        }

        printCalls.push(buildPrintSeparatorLine());

        if (ticket.status === "ANNULATION") {
          printCalls.push(setBold(), buildPrintString(formatMessage("printTicket.annulation") + "\n"), cancelBold());
        }

        printCalls.push(
          buildPrintLabelDataRow("printTicket.reservationNumber", booking.bookingNumber),
          buildPrintLabelDataRow("printTicket.ticketNumber", ticket.ticketNumber),
          buildPrintLabelDataRow("printTicket.reservationDate", formatLocalDateTimeString(ticket.creationDate)),
          buildPrintLabelDataRow("printTicket.agent", booking.seller ? booking.seller.name : "---"),
          buildPrintLabelDataRow("printTicket.agency", booking.agency ? booking.agency.name : "---"),
          buildPrintSeparatorLine(),
          setBold(),
          buildPrintString(`${ticket.excursionName}\n`),
          buildPrintString(`${ticket.modalityName}\n`),
          cancelBold()
        );

        if (ticket.status === "ANNULATION") {
          printCalls.push(
            buildPrintLabelDataRow(
              "printTicket.reservationNumber",
              `${ticket.cancelledBookingNumber}/${ticket.cancelledTicketNumber}`
            )
          );
        }

        if (ticket.excursionDate) {
          printCalls.push(
            buildPrintLabelDataRow(
              "printTicket.excursionDate",
              `${formatLocalDateString(ticket.excursionDate)} ${formatWeekdayName(ticket.excursionDate)}`
            )
          );
        }

        if (ticket.pickupPoint) {
          printCalls.push(buildPrintLabelDataRow("printTicket.pickUpPointName", ticket.pickupPoint.description));

          if (ticket.pickupTime) {
            printCalls.push(
              buildPrintLabelDataRow("printTicket.pickUpPointTime", formatLocalTimeString(ticket.pickupTime))
            );
          }
        }

        if (ticket.hotel) {
          printCalls.push(buildPrintLabelDataRow("printTicket.hotel", ticket.hotel.name));
          if (ticket.hotelReference) {
            printCalls.push(buildPrintLabelDataRow("printTicket.hotelRoom", ticket.hotelReference));
          }
        }

        if (ticket.status !== "ANNULATION" && booking.contact && booking.contact.name) {
          printCalls.push(buildPrintLabelDataRow("printTicket.contact", booking.contact.name));
        }

        const columnsWidth = [7, 12, 13];
        const columsnAlignement = [Alignment.LEFT, Alignment.RIGHT, Alignment.RIGHT];

        if (ticket.paxes) {
          for (const pax of ticket.paxes) {
            if (pax.units > 0) {
              const units = pax.units;
              const price = pax.paxType.price;
              const strPrice = formatCurrency(price.amount, price.currency);
              const strTotalPrice = formatCurrency(price.amount * units, price.currency);
              const paxTypeAbbr = getPaxTypeAbbr(pax.paxType.name);
              printCalls.push(
                buildPrintColumnsText(
                  [`${units}x${paxTypeAbbr}`, strPrice.replace(/\s/, ""), strTotalPrice.replace(/\s/, "")],
                  columnsWidth,
                  columsnAlignement
                )
              );
            }
          }

          printCalls.push(buildPrintColumnsText(["", "", "----------"], [1, 1, 30], columsnAlignement));
        }

        if (ticket.price.discountAmount) {
          printCalls.push(
            buildPrintColumnsText(
              [
                formatMessage({ id: "printTicket.price.subtotal" }),
                "",
                formatCurrency(ticket.price.basePrice, ticket.price.currency).replace(/\s/, ""),
              ],
              columnsWidth,
              columsnAlignement
            ),
            buildPrintColumnsText(
              [
                formatMessage({ id: "printTicket.price.discount" }),
                ticket.price.discount && ticket.price.discount.name ? ticket.price.discount.name : "",
                formatCurrency(-ticket.price.discountAmount!, ticket.price.currency).replace(/\s/, ""),
              ],
              columnsWidth,
              columsnAlignement
            )
          );
        }

        printCalls.push(
          setBold(),
          buildPrintColumnsText(
            [
              formatMessage({ id: "printTicket.price.total" }),
              "",
              formatCurrency(ticket.price.totalPrice, ticket.price.currency).replace(/\s/, ""),
            ],
            columnsWidth,
            columsnAlignement
          ),
          cancelBold(),
          buildPrintSeparatorLine()
        );

        /* Añadir forma de pago de la reserva */
        addPayment(booking, printCalls);

        /* Añadir códigos de barras, si es que hay. */
        addTicketBarcodes(ticket, printCalls);

        if (ticket.cancelConditions && ticket.cancelConditions.length > 0) {
          printCalls.push(
            setBold(),
            buildPrintString(`${formatMessage({ id: "printTicket.cancellationPolicy" })}:\n`),
            cancelBold()
          );
          for (const cancelCondition of ticket.cancelConditions) {
            for (const line of splitString(cancelCondition, 32)) {
              printCalls.push(buildPrintString(line + "\n"));
            }
          }
          printCalls.push(buildPrintSeparatorLine());
        }

        if (ticket.remarks) {
          printCalls.push(buildPrintString(ticket.remarks + "\n"), buildPrintSeparatorLine());
        }

        if (ticket.conditions) {
          printCalls.push(buildPrintString(ticket.conditions + "\n"), buildPrintSeparatorLine());
        }

        if (ticket.officeConditions) {
          printCalls.push(buildPrintString(ticket.officeConditions + "\n"), buildPrintSeparatorLine());
        }

        if (config && config.withQRToken) {
          printCalls.push(buildPrintQR(`${booking.bookingNumber}/${ticket.ticketNumber}`), buildPrintSeparatorLine());
        }

        printCalls.push(
          buildPrintString(
            formatMessage({ id: "printTicket.printTime" }, { time: formatLocalDateTime(LocalDateTime.now()) }) + "\n"
          )
        );

        /* Texto de reimpresión de ticket */
        addReprint(ticket, printCalls);

        printCalls.push(printAndFeed(20), buildPrintString(SCISSORS_LINE));

        await print({ headingWhiteLines: 1, trailingWhiteLines: 4 }, ...printCalls);
        resolve();
      } catch (error) {
        reject(error);
      }
    };
    image.src = "./img/Logo_Ticket.png";
  });

function addTicketBarcodes(ticket: ExcursionTicket, printCalls: PrintCall[]) {
  if (ticket.barcodes != null) {
    ticket.barcodes.forEach(barcode => {
      if (barcode.label) {
        printCalls.push(buildPrintString(barcode.label + "\n"));
      }

      if (barcode.type === "QR") {
        printCalls.push(buildPrintQR(barcode.data));
      } else {
        printCalls.push(buildPrintBarcode(barcode.data, barcode.type));
      }
      printCalls.push(buildPrintString("\n"), buildPrintSeparatorLine());
    });
  }
}

/**
 * Añade la forma de pago de la reserva
 * @param booking
 * @param printCalls
 */
function addPayment(booking: Booking, printCalls: PrintCall[]) {
  if (booking.payment != null) {
    if (booking.payment.type != null) {
      const payment = formatMessage(`printTicket.paymentType.${booking.payment.type}`);

      printCalls.push(
        buildPrintString(formatMessage({ id: "printTicket.payment" }, { payment: payment })),
        buildPrintString("\n")
      );
    }

    if (booking.payment.reference != null) {
      printCalls.push(
        buildPrintString(
          formatMessage({ id: "printTicket.payment.reference" }, { reference: booking.payment.reference })
        ),
        buildPrintString("\n")
      );
    }

    printCalls.push(buildPrintSeparatorLine());
  }
}

/**
 * Añade el texto de reimpresión de ticket
 */
function addReprint(ticket: ExcursionTicket, printCalls: PrintCall[]) {
  /* Si no es la primera vez que se imprime, añadimos el texto de reimpresión */
  if (ticket.printCount && ticket.printCount > 0) {
    printCalls.push(
      setAlignment(Alignment.CENTER),
      buildPrintString(formatMessage("printTicket.reprint") + "\n"),
      resetAlignment()
    );
  }
}

/**
 * Indica la abreviatura de un tipo de pasajero.
 */
function getPaxTypeAbbr(paxTypeName: string) {
  if (existMessage("printTicket.paxType." + paxTypeName)) {
    return formatMessage("printTicket.paxType." + paxTypeName);
  }

  //Si no existe la etiqueta devuelve el tipo de pasajero sin abreviar.
  //Si se detecta este caso hay que crear la etiqueta correspondiente.
  return paxTypeName;
}
