/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import React from "react";

import moize from "moize";

import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ShoppingCart from "@material-ui/icons/ShoppingCart";

import { ViewId } from "~/views";

import Environment from "~/environment";
import { InjectedI18nProps, withI18n } from "~/services/i18n";

import User from "./User";

import QRExcursion from "../QRExcursion";
import { MenuHeader } from "../Menu";

/**
 * Definición de los estilos por defecto.
 *
 * @param theme   el tema de la app
 */
const styles = (theme: Theme) =>
  createStyles({
    iconButton: {
      color: "white",
    },
    shoppingCardBadge: {
      backgroundColor: theme.palette.secondary.main,
      height: 18,
      width: 18,
    },
  });

/** Las propiedades del componente. */
export interface Props extends WithStyles<typeof styles> {
  /** El nombre de la agencia a mostrar. */
  agency?: string | null;

  /** Indica el número de items en la cesta. */
  bookedItems?: number;

  /** La vista actual. */
  currentView: ViewId;

  /** Handler para el vento cerrar sesión. */
  onLogout: () => void;

  /** Handler para tratar el evento de cuando el usuario escoge una vista. */
  onViewSelect: (view: ViewId) => void;

  /** El nombre de usuario a mostrar. */
  user?: string | null;
}

interface ProvidedProps extends Props, InjectedI18nProps {}

/**
 * Wrapper para añadir el menú de la aplicación a las vistas.
 */
class HeaderBar extends React.PureComponent<ProvidedProps> {
  /**
   * Devuelve la función para tratar el evento de cuando el usuario selecciona
   * la opción de menú correspondiente a la vista indicada.
   *
   * Esta función evitar tener que crear una diferente para cada posible vista.
   */
  private getViewSelectHandler = moize((view: ViewId) => {
    return () => {
      this.handleViewSelect(view);
    };
  });

  /** #render */
  public render() {
    const { bookedItems, classes, currentView } = this.props;

    return (
      <AppBar position="static">
        <Toolbar>
          <QRExcursion />

          <User agency={this.props.agency} user={this.props.user} />

          {bookedItems! > 0 && (
            <IconButton color="inherit" onClick={this.getViewSelectHandler(ViewId.confirmBooking)}>
              <Badge badgeContent={bookedItems} classes={{ badge: classes.shoppingCardBadge }}>
                <ShoppingCart />
              </Badge>
            </IconButton>
          )}

          {!Environment.APK && (
            // TODO: Ver si esto se puede controlar con "popstate" y para quitar este botón.
            <IconButton aria-label="Back" className={classes.iconButton} onClick={this.handleBack}>
              <ArrowBackIcon />
            </IconButton>
          )}

          <MenuHeader currentView={currentView} onLogout={this.handleLogout} onViewSelect={this.handleViewSelect} />
        </Toolbar>
      </AppBar>
    );
  }

  /** Simulo el evento de pulsar el botón físico de volver en el dispositivo */
  private handleBack = () => {
    const backEvent = document.createEvent("Event");
    backEvent.initEvent("backbutton", false, false);
    document.dispatchEvent(backEvent);
  };

  /** Trata el evento de que se ha seleccionado la opción de "salir". */
  private handleLogout = () => {
    this.props.onLogout();
  };

  /** Trata el evento de cuando se selecciona una vista determinada. */
  private handleViewSelect = (view: ViewId) => {
    this.props.onViewSelect(view);
  };
}

export default withI18n(withStyles(styles)(HeaderBar));
