import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import { LiquidationDetail } from "~/services/webapi/types";

import { formatCurrency } from "~/services/i18n/i18nApiImpl";
import { useI18n } from "~/services/i18n";

interface DetailProps {
  /** Título del detalle */
  title: string;

  /** Valores del detalle */
  value: LiquidationDetail;
}

/** Estilos del componente. */
const useStyles = makeStyles(theme =>
  createStyles({
    detailStyle: {
      fontSize: "0.8125rem",
      marginTop: 8,
    },
    rowStyle: {
      color: theme.palette.text.secondary,
    },
    tableStyle: {
      width: "100%",
    },
    subItemStyle: {
      paddingLeft: 7,
    },
  })
);

const Detail: React.FC<DetailProps> = props => {
  const {
    title,
    value: { cancellationsAmount, currency, salesAmount, totalAmount },
  } = props;

  const classes = useStyles();
  const { formatMessage } = useI18n();

  /* Render */
  return (
    <div className={classes.detailStyle}>
      <table className={classes.tableStyle}>
        <tr>
          <td>{title}</td>
        </tr>
        <tr className={classes.rowStyle}>
          <td className={classes.subItemStyle}>{formatMessage("liquidationResult.detail.sales")}</td>
          <td align="right">{formatCurrency(salesAmount, currency)}</td>
        </tr>
        <tr className={classes.rowStyle}>
          <td className={classes.subItemStyle}>{formatMessage("liquidationResult.detail.refunds")}</td>
          <td align="right">{formatCurrency(cancellationsAmount, currency)}</td>
        </tr>
        <tr className={classes.rowStyle}>
          <td className={classes.subItemStyle}>
            <b>{formatMessage("liquidationResult.detail.total")}</b>
          </td>
          <td align="right">
            <b>{formatCurrency(totalAmount, currency)}</b>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Detail;
