import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import StoreIcon from "@material-ui/icons/Store";
import UserIcon from "@material-ui/icons/Face";

const useStyles = makeStyles(theme =>
  createStyles({
    userBox: {
      flexDirection: "column",
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      width: 0,
    },
    userItem: {
      "& span": {
        color: "white",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      alignItems: "center",
      display: "flex",
      whiteSpace: "nowrap",
    },
    icon: {
      color: "white",
      height: "0.85em",
      marginRight: theme.spacing(1),
      width: "0.85em",
    },
  })
);

interface UserProps {
  /** Agencia del usuario */
  agency?: string | null;
  /** Nombre del usuario */
  user?: string | null;
}

/**
 * Componente para mostrar la información del usuario logueado.
 * En caso de que algún valor sea nulo se mostrará únicamente el icono.
 */
const User: React.FC<UserProps> = props => {
  const { user, agency } = props;

  const classes = useStyles();

  return (
    <div className={classes.userBox}>
      <div className={classes.userItem}>
        <UserIcon className={classes.icon} />
        <span>{user}</span>
      </div>
      <div className={classes.userItem}>
        <StoreIcon className={classes.icon} />
        <span>{agency}</span>
      </div>
    </div>
  );
};

export default User;
