import { ViewPayload } from "~/components/ViewController";

export type HistoryItem = Readonly<{
  payload: ViewPayload | null;
  view: ViewId;
}>;

/** Estado del módulo que controla las vistas. */
export type ViewState = Readonly<{
  /** Vista actual. */
  current: ViewId | null;

  /** Los parámetros de la vista. */
  payload: ViewPayload | null;

  /** Histórico de vistas. */
  stack: HistoryItem[];
}>;

/* Definición de vistas disponibles. */
export enum ViewId {
  addExcursion = "addexcursion",
  booking = "booking",
  bookingSearch = "bookingSearch",
  confirmBooking = "confirmBooking",
  error = "error",
  liquidationReport = "liquidationReport",
  login = "login",
  qrScann = "qrScann",
  searcher = "searcher",
  splash = "splash",
}
