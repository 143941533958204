import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import BugIcon from "@material-ui/icons/BugReport";

import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import MenuItem from "@material-ui/core/MenuItem";

import { useI18n } from "~/services/i18n";

import { setAppConfig } from "~/services/config";
import { AppState } from "~/state";

const Debug: React.FC = () => {
  //Variables de estado
  const [debugShow, setDebugShow] = useState<boolean>();

  const { formatMessage, getLocale } = useI18n();

  const handleToggleDebugMenuShow = () => {
    setDebugShow(!debugShow);
  };

  const toggleSplashScreen = () => {
    const event = document.createEvent("Event");
    event.initEvent("toggleSplashScreen", false, false);
    document.dispatchEvent(event);
  };

  const handleGenerateException = () => {
    throw new Error("Generated Debug Exception");
  };

  const currentLocale = getLocale();
  const ArrowDropIcon = debugShow ? ArrowDropUp : ArrowDropDown;

  const dispatch = useDispatch();

  const appConfig = useSelector((state: AppState) => state.config.appConfig);

  return (
    <>
      <MenuItem onClick={handleToggleDebugMenuShow}>
        <ListItemIcon>
          <BugIcon />
        </ListItemIcon>
        <ListItemText>Debug</ListItemText>
        <ArrowDropIcon />
      </MenuItem>
      {debugShow && (
        <div>
          <Divider />
          <MenuItem
            onClick={() => {
              if (appConfig && appConfig.userConfig) {
                dispatch(setAppConfig({ ...appConfig, userConfig: { languageCode: "es" } }));
              }
            }}
            disabled={currentLocale === "es"}
          >
            {formatMessage("viewWrapper.language.spanish")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (appConfig && appConfig.userConfig) {
                dispatch(setAppConfig({ ...appConfig, userConfig: { languageCode: "en" } }));
              }
            }}
            disabled={currentLocale === "en"}
          >
            {formatMessage("viewWrapper.language.english")}
          </MenuItem>
          <MenuItem onClick={toggleSplashScreen}>Show splash screen</MenuItem>
          <MenuItem onClick={handleGenerateException}>Generate Exception</MenuItem>
        </div>
      )}
    </>
  );
};

export default Debug;
