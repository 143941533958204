import React, { useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { PopoverOrigin } from "@material-ui/core/Popover";

import BarChart from "@material-ui/icons/BarChart";
import MenuIcon from "@material-ui/icons/Menu";
import ExitIcon from "@material-ui/icons/MeetingRoom";
import SearchIcon from "@material-ui/icons/Search";
import MyBookingsIcon from "@material-ui/icons/Work";

import Environment from "~/environment";

import { ViewId } from "~/views";

import { useI18n } from "~/services/i18n";

import Debug from "./Debug";

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      color: "white",
    },
  })
);

interface MenuProps {
  /** La vista actual. */
  currentView: ViewId;

  /** Handler para el evento cerrar sesión. */
  onLogout: () => void;

  /** Handler para tratar el evento de cuando el usuario escoge una vista. */
  onViewSelect: (view: ViewId) => void;
}

const MenuHeader: React.FC<MenuProps> = props => {
  const { currentView, onLogout, onViewSelect } = props;

  const { formatMessage } = useI18n();
  const classes = useStyles();

  //Variables de estado
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const open = anchorEl != null;

  /** Posicionamiento entre el botón del menú y el desplegable. */
  const menuAnchorPosition: PopoverOrigin = {
    horizontal: "right",
    vertical: "top",
  };

  const handleMenuClose = () => {
    setAnchorEl(undefined);
  };

  const handleViewSelect = (view: ViewId) => {
    handleMenuClose();
    onViewSelect(view);
  };

  const handleMenuShow = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    onLogout();
    handleMenuClose();
  };

  const changeViewSearcher = () => handleViewSelect(ViewId.searcher);
  const changeViewBooking = () => handleViewSelect(ViewId.bookingSearch);
  const changeViewLiquidation = () => handleViewSelect(ViewId.liquidationReport);

  return (
    <>
      <IconButton aria-label="Menu" className={classes.iconButton} onClick={handleMenuShow}>
        <MenuIcon />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={menuAnchorPosition}
        open={open}
        transformOrigin={menuAnchorPosition}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={changeViewSearcher} disabled={currentView === ViewId.searcher}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText>{formatMessage("viewWrapper.menu.searcher")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={changeViewBooking} disabled={currentView === ViewId.bookingSearch}>
          <ListItemIcon>
            <MyBookingsIcon />
          </ListItemIcon>
          <ListItemText>{formatMessage("viewWrapper.menu.bookingSearch")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={changeViewLiquidation} disabled={currentView === ViewId.liquidationReport}>
          <ListItemIcon>
            <BarChart />
          </ListItemIcon>
          <ListItemText>{formatMessage("viewWrapper.menu.liquidation")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText>{formatMessage("viewWrapper.menu.logout")}</ListItemText>
        </MenuItem>
        {Environment.DEBUG && <Debug />}
      </Menu>
    </>
  );
};

export default MenuHeader;
