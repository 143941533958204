import React from "react";

import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";

import LabeledText from "~/components/LabeledText";
import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { Payment } from "~/services/webapi/types";

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      alignSelf: "flex-end",
    },
    root: {
      "& > :not(:first-child)": {
        marginLeft: theme.spacing(1),
      },
      display: "flex",
      flex: "0 0",
    },
    spacer: {
      flexGrow: 1,
    },
  })
);

/** Propiedades del componente. */
interface BookingPaymentProps {
  /** Datos del pago. */
  payment?: Payment;

  /** Invocado cuando se queire imprimir un recibo tpv. */
  onPrint: (voucherId: number) => void;
}

/**
 * Información de pago de la reserva.
 */
const BookingPayment: React.FC<BookingPaymentProps & InjectedI18nProps> = props => {
  const {
    i18n: { formatMessage },
    payment,
    onPrint,
  } = props;

  const classes = useStyles();

  if (!payment?.type) {
    return null;
  }

  const print = () => payment.voucherId != null && onPrint(payment.voucherId);

  return (
    <div className={classes.root}>
      <LabeledText
        label={formatMessage("bookingView.paymentType")}
        text={formatMessage(`bookingView.paymentType.${payment.type}`)}
        textSize="large"
      />
      {payment.reference && (
        <LabeledText label={formatMessage("bookingView.paymentReference")} text={payment.reference} textSize="large" />
      )}
      <div className={classes.spacer} />
      {payment.voucherId && (
        <Button className={classes.button} startIcon={<PrintIcon color="action" />} onClick={print}>
          {formatMessage("bookingView.printVoucher")}
        </Button>
      )}
    </div>
  );
};

export default withI18n(BookingPayment);
