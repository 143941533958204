/* eslint-disable max-len */
/* Etiquetas multiidoma en castellano. */
export default {
  "addExcursionView.agency": "Agency",
  "addExcursionView.back": "Back to search",
  "addExcursionView.book": "Book",
  "addExcursionView.calendar.label": "Excursion Date",
  "addExcursionView.copyTemplate": "Copy",
  "addExcursionView.excursion": "Excursion",
  "addExcursionView.hotel": "Hotel",
  "addExcursionView.hotelReference": "Room",
  "addExcursionView.hotelZone": "Hotel zone",
  "addExcursionView.invalidCurrency":
    "This ticket cannot be added to the current booking. It's not possible to combine different currencies in the same booking.",
  "addExcursionView.language": "Language",
  "addExcursionView.modality": "Modality",
  "addExcursionView.pickUpPoint": "Pick Up",
  "addExcursionView.remarks": "Remarks",
  "addExcursionView.withoutAvailability": " No availability found in the coming months.",
};
