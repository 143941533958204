import { buildPrintB64Image, print } from "~/services/printer";
import { readBlobAsBase64 } from "~/utils/blobs";

/**
 * Imprime la imagen del recibo.
 *
 * @param image bytes de la imagen
 */
export async function printVoucherImage(image: ArrayBuffer) {
  const imageB64 = await readBlobAsBase64(new Blob([image]));

  await print({ headingWhiteLines: 1, trailingWhiteLines: 4 }, buildPrintB64Image(imageB64));
}
