import { Reducer } from "redux";

import { BookingAction, REFRESH_BOOKING, UPDATE_PRINT_COUNT } from "~/services/booking";

import { LOAD_VIEW, ViewControllerAction } from "../actions";
import { BookingViewState } from "./types";

/** Estado por defecto. */
const defaultState: BookingViewState = {};

/** Reducer */
const reducer: Reducer<BookingViewState, BookingAction | ViewControllerAction> = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_VIEW: {
      return defaultState;
    }

    case REFRESH_BOOKING:
      if (action.booking != null) {
        return {
          ...state,
          booking: action.booking,
        };
      } else {
        return state;
      }

    case UPDATE_PRINT_COUNT: {
      if (state?.booking == null) {
        return state;
      }

      const newBooking = {
        ...state.booking,
        tickets: state.booking.tickets.map(ticket => {
          if (ticket.ticketNumber === action.ticketNumber) {
            return { ...ticket, printCount: (ticket.printCount || 0) + 1 };
          } else {
            return ticket;
          }
        }),
      };

      return {
        ...state,
        booking: newBooking,
      };
    }

    default:
      return state;
  }
};

export default reducer;
