/* Etiquetas multiidoma en inglés. */
export default {
  "liquidationReport.title": "Terminal sales report",
  "liquidationResult.detail.sales": "Sales:",
  "liquidationResult.detail.refunds": "Refunds:",
  "liquidationResult.detail.total": "Total:",
  "liquidationResult.ticketsSold": "No. tickets sold:",
  "liquidationResult.ticketsCancelled": "No. tickets cancelled:",
  "liquidationResult.paymentType.card": "Card:",
  "liquidationResult.paymentType.cash": "Cash:",
  "liquidationResult.paymentType.general": "General:",
  "liquidationSearcherForm.action.clear": "Clear",
  "liquidationSearcherForm.action.search": "Search",
  "liquidationSearcherForm.dateRange.label": "Date range",
  "liquidationSearcherForm.timepicker.from": "From",
  "liquidationSearcherForm.timepicker.label": "Select time range",
  "liquidationSearcherForm.timepicker.to": "To",
};
