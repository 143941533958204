import React, { useMemo } from "react";

import TableBody from "@material-ui/core/TableBody";

import { DayOfWeek, LocalDate, TemporalAdjusters, YearMonth } from "js-joda";

import { ExcursionTicketOption, LocalDateString } from "~/services/webapi/types";

import CalendarRow from "./CalendarRow";

export interface CalendarBodyProps {
  /** La fecha actualmente seleccionada. */
  currentSelectedDate?: LocalDate;

  /** Indica si un día debe estar habilitado. */
  isEnabled?: (ticketOption: ExcursionTicketOption) => boolean;

  /** Mes que se está visualizando. */
  month: YearMonth;

  /** Función para tratar el evento de selección de fecha. */
  onSelectDate: (date: LocalDate) => void;

  /** Mapa con los valores de disponibilidad para cada día. */
  values?: Map<LocalDateString, ExcursionTicketOption> | null;
}

/**
 * Vista del cuerpo de un calendario.
 */
const CalendarBody: React.FC<CalendarBodyProps> = props => {
  const { currentSelectedDate, isEnabled, month, onSelectDate, values } = props;

  const rows = useMemo(() => {
    const weeks: React.ReactNode[] = [];

    /*
     * Se empiezan a generar rows desde el primer día de la semana (lunes),
     * porque el mes en curso puede ser que empieze en cualquier otro día.
     */
    let firstDayOfWeek = month.atDay(1).with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY));
    do {
      weeks.push(
        <CalendarRow
          currentSelectedDate={currentSelectedDate}
          firstDayOfWeek={firstDayOfWeek}
          isEnabled={isEnabled}
          month={month}
          onSelectDate={onSelectDate}
          values={values}
        />
      );
      /* Iteramos hasta que la semana a generar ya no se encuentre en el mes. */
      firstDayOfWeek = firstDayOfWeek.plusWeeks(1);
    } while (firstDayOfWeek.monthValue() === month.monthValue());

    return weeks;
  }, [currentSelectedDate, isEnabled, month, onSelectDate, values]);

  return <TableBody>{rows}</TableBody>;
};

export default CalendarBody;
