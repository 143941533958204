import React from "react";

import TextField from "@material-ui/core/TextField";

import { useI18n } from "~/services/i18n";

/** Las propiedades del componente. */
export interface Props {
  /** La referencia de la agencia. */
  agencyReference?: string;

  /** Indica si es obligatorio informar la referencia de la agencia. */
  isMandatoryAgencyReference: boolean;

  /** Función invocada cuando cambia la referencia de la agencia. */
  onAgencyReferenceChange: (agencyReference: string) => void;
}

const inputProps = {
  agencyReference: { maxLength: 50 },
};

/**
 * Componente con la información de agencia de la reserva.
 */
const AgencyView: React.FC<Props> = props => {
  const { agencyReference, isMandatoryAgencyReference, onAgencyReferenceChange } = props;

  const { formatMessage } = useI18n();

  return (
    <TextField
      fullWidth={true}
      label={formatMessage("bookingView.agencyReference")}
      inputProps={inputProps.agencyReference}
      margin="dense"
      value={agencyReference || ""}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onAgencyReferenceChange(event.target.value);
      }}
      required={isMandatoryAgencyReference}
    />
  );
};

export default AgencyView;
