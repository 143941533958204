import React from "react";
import { LocalTime } from "js-joda";
import produce from "immer";
import moize from "moize";

import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import CancelConditionsViewer from "~/components/CancelConditionsViewer";
import CodeNameSelector from "~/components/CodeNameSelector";
import DistributionTable from "~/components/DistributionTable";
import { paxQuotaToDistribution } from "~/components/DistributionTable/types";
import Selector from "~/components/Selector";
import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { ExcursionTicketOption, Hotel, PaxQuota, PickupPoint } from "~/services/webapi/types";

/** Estilos por defecto. */
const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 0,
      flexShrink: 0,
    },
  });

type changeFunc = (formValue: Partial<FormValue>) => void;

/** Datos del formulario. */
export interface FormValue {
  /** El código de hotel seleccionado. */
  hotelCode?: string;

  /** La referencia del hotel introducida. */
  hotelReference?: string;

  /** La zona del hotel seleccionada. */
  hotelZoneCode?: string;

  /** El idioma seleccionado. */
  languageCode?: string;

  /** La selección de paxes. */
  paxQuotas: PaxQuota[];

  /** El pickup seleccionado. */
  pickUpPointCode?: string;

  /** Las observaciones introducidas. */
  remarks?: string;
}

/**
 * Propiedades del componente. Tanto las que vienen directas como las derivadas.
 */
export interface Props extends WithStyles<typeof styles> {
  /** Indica si el formulario está en modo edición (es reservable) */
  bookable: boolean;

  /** Valores de los campos del formulario */
  formValue: FormValue;

  /** La opción del ticket seleccionado. */
  excursionTicketOption: ExcursionTicketOption;

  /** Función invocada cuando cambia algún valor del formulario */
  onChange: (formValue: FormValue) => void;
}

interface ProvidedProps extends Props, InjectedI18nProps {}

class ExcursionTicketForm extends React.PureComponent<ProvidedProps> {
  /** Función para transformar de paxQuota a distribution. */
  /*
   * TODO: Ahora es así porque simplemente sumamos precios.
   * Si cambia la valoración con descuentos y otras cosas, habrá que pasarlo a
   * las funciones de reserva y tener otra estructura.
   */
  private asDistribution = moize.simple(paxQuotaToDistribution);

  /** Filtro con memoria para recupera los hoteles seleccionables. */
  private hotelsFilter = moize.simple((hasPickUpService?: boolean, hotelZoneCode?: string, hotels?: Hotel[]) => {
    if (hasPickUpService && hotelZoneCode && hotels) {
      return hotels.filter(hotel => hotel.zoneCode === hotelZoneCode);
    }

    /* Si no se ha podido devolver nada se quedará vacío. */
    return [];
  });

  /** Filtro con memoria para recupera los puntos de recogida. */
  private pickUpPointsFilter = moize.simple(
    (hasPickUpService?: boolean, hotels?: Hotel[], hotelCode?: string, pickUpPoints?: PickupPoint[]) => {
      if (hasPickUpService && hotels && hotelCode && pickUpPoints) {
        const hotel = hotels?.find(h => h.code === hotelCode);

        /*
         * Siempre debería existir porque no puede ser que haya seleccionado en
         * el combo un hotel y que no exista. Pero no cuesta nada controlarlo.
         */
        if (hotel != null) {
          return hotel.pickUpPoints;
        }
      }

      /* Si no se ha podido devolver nada se quedará vacío. */
      return [];
    }
  );

  /** #constructor */
  public constructor(props: ProvidedProps) {
    super(props);
  }

  /** #didMount */
  public componentDidMount() {
    this.initFormValues();
  }

  /**
   * #didlUpdate
   *
   * Una vez se recarga la información del ticket, entonces hay que actualizar
   * el componente. Al mismo tiempo, se aprovecha para preseleccionar algunos
   * elementos.
   */
  public componentDidUpdate(prevProps: Props) {
    /*
     * Hay que ir con ojo de actualizar únciamente cuando se ha cargado la
     * excursión por primera vez o si ha cambiado el día de la misma.
     */
    /*
     * Ambos casos se pueden detectar controlando que la instancia de
     * excursionTicketOption ha cambiado. No debería llegar otra instancia si no
     * es el caso. Y mucho menos, llegar la misma instancia con valores
     * cambiados.
     */

    const { excursionTicketOption } = this.props;

    if (excursionTicketOption !== prevProps.excursionTicketOption) {
      this.initFormValues();
    }
  }

  public render() {
    const { excursionTicketOption } = this.props;

    const { formatMessage } = this.props.i18n;

    const {
      languageCode,
      hotelCode,
      hotelReference,
      hotelZoneCode,
      paxQuotas,
      pickUpPointCode,
      remarks,
    } = this.props.formValue;

    const { bookable, classes } = this.props;

    const {
      maxAmount,
      cancelConditions,
      hotels,
      hotelZones,
      languages,
      paxMax,
      paxMin,
      pickUpPoints,
      pickUpService: hasPickUpService,
    } = excursionTicketOption;

    /*
     * Se precalculan los listados que van a alimentar los combos. Todos los
     * filtros llevan memoria para evitar crear listas nuevas cuando cambian
     * valores no asociados. El caso especial son las zonas que se pueden
     * seleccionar, es el combo padre y por lo tanto siempre son todas.
     */
    const selectableHotelsZones = hotelZones;
    const selectableHotels = this.hotelsFilter(hasPickUpService, hotelZoneCode, hotels);
    const selectablePickUpPoints = this.pickUpPointsFilter(hasPickUpService, hotels, hotelCode, pickUpPoints);

    const distribution = this.asDistribution(paxQuotas);

    const getSelectorValue = (item: PickupPoint) => {
      return {
        code: item.code,
        description:
          (item.description ? item.description : item.name) +
          (item.time ? ` - ${this.props.i18n.formatLocalTime(LocalTime.parse(item.time))}` : ""),
      };
    };

    return (
      <div className={classes.root}>
        {
          <DistributionTable
            distribution={distribution}
            onPaxQuotaChange={bookable ? this.changePaxQuota : undefined}
            maxAmount={maxAmount}
            paxMin={paxMin}
            paxMax={paxMax}
          />
        }
        {bookable && (
          <>
            {cancelConditions && <CancelConditionsViewer cancelConditions={cancelConditions} />}

            <CodeNameSelector
              fullWidth={true}
              label={formatMessage("addExcursionView.language")}
              margin="dense"
              onValueChange={this.changeLanguage}
              required={true}
              selectedCode={languageCode}
              values={languages}
            />

            {hasPickUpService && (
              <>
                <CodeNameSelector
                  fullWidth={true}
                  label={formatMessage("addExcursionView.hotelZone")}
                  onValueChange={this.changeHotelZone}
                  required={true}
                  selectedCode={hotelZoneCode}
                  values={selectableHotelsZones}
                  margin="dense"
                />

                <CodeNameSelector
                  fullWidth={true}
                  label={formatMessage("addExcursionView.hotel")}
                  onValueChange={this.changeHotel}
                  required={true}
                  selectedCode={hotelCode}
                  values={selectableHotels}
                  margin="dense"
                />

                <TextField
                  fullWidth={true}
                  label={formatMessage("addExcursionView.hotelReference")}
                  margin="dense"
                  onChange={this.changeHotelReference}
                  value={hotelReference || ""}
                  disabled={hotelCode == null}
                />

                <Selector
                  fullWidth={true}
                  label={formatMessage("addExcursionView.pickUpPoint")}
                  margin="dense"
                  onValueChange={this.changePickUp}
                  required={true}
                  selectedCode={pickUpPointCode}
                  values={selectablePickUpPoints}
                  getSelectorValue={getSelectorValue}
                />
              </>
            )}

            <TextField
              fullWidth={true}
              label={formatMessage("addExcursionView.remarks")}
              margin="dense"
              multiline={true}
              onChange={this.changeRemarks}
              value={remarks || ""}
            />
          </>
        )}
      </div>
    );
  }

  /** Handler para notificar el cambio del hotel seleccionado. */
  private changeHotel = (value?: string, onChange: changeFunc = this.onChange) => {
    const hotelCode = this.props.formValue.hotelCode;

    /* Únicamente se propaga el cambio si se selecciona otro valor. */
    if (hotelCode !== value) {
      let pickUpCode;
      const hotels = this.props.excursionTicketOption?.hotels;
      const hotel = hotels != null && hotels.find(h => h.code === value);

      /*
       * Si tenemos hotel seleccionado se mira si se puede encontrar un pickup
       * para seleccionarlo también automáticamente.
       */
      if (value && hotel && hotel.pickUpPoints && hotel.pickUpPoints.length === 1) {
        /*
         * TODO: Ojo que aquí se supone que un pickUpPoint no vendrá repetido
         * para un hotel. Nunca debería darse el caso, pero sabemos que el
         * modelo puede permitirlo y que DMT no lo contorla.
         */
        pickUpCode = hotel.pickUpPoints[0].code;
      }

      const nextOnChange = (formValue: Partial<FormValue>) =>
        onChange({
          hotelCode: value || "",
          ...formValue,
        });

      this.changePickUp(pickUpCode, nextOnChange);
    } else if (onChange !== this.onChange) {
      // FIXME: Arreglo rápido para corregir error en PRO. Revisar con más detalle.
      onChange({});
    }
  };

  /** Handler para notificar el cambio en el input de referencia del hotel. */
  private changeHotelReference: React.ChangeEventHandler<HTMLInputElement> = event => {
    const { value } = event.currentTarget;

    this.props.onChange(
      produce(this.props.formValue, formValue => {
        formValue.hotelReference = value || "";
      })
    );
  };

  /** Handler para notificar el cambio de la zona de hotel seleccionada. */
  private changeHotelZone = (value?: string, onChange: changeFunc = this.onChange) => {
    const hotelZoneCode = this.props.formValue.hotelZoneCode;

    /*
     * Si el valor seleccionado es el mismo, entonces no hace falta actualizar.
     * Aquí se controla porque se hacen actualizaciones en cascada.
     */
    if (hotelZoneCode !== value) {
      let hotelCode;
      const hotels = this.props.excursionTicketOption?.hotels;

      /*
       * Si nos han seleccionado un valor y el ticket tiene hoteles, entonces se
       * mira la lista de hoteles de la zona para autoseleccionar el hotel en
       * caso de que solo exista una opción.
       */
      if (value && hotels) {
        const zoneHotels = hotels.filter(h => h.zoneCode === value);
        if (zoneHotels.length === 1) {
          hotelCode = zoneHotels[0].code;
        }
      }

      const nextOnChange = (formValue: Partial<FormValue>) =>
        onChange({
          hotelZoneCode: value || "",
          ...formValue,
        });

      /*
       * Se propaga el cambio al combo de hotel, con opción por defecto o sin
       * ella.
       */
      this.changeHotel(hotelCode, nextOnChange);
    }
  };

  /** Handler para notificar la modificación de la selección de idioma. */
  private changeLanguage = (value?: string, onChange: changeFunc = this.onChange) => {
    onChange({ languageCode: value || "" });
  };

  /** Handler para notificar las modificación de la selección de paxes. */
  private changePaxQuota = (paxTypeCode: string, units: number) => {
    /*
     * Hay que crear una instancia nueva del array, de todo el array. No
     * modificar únicamente el elemento afectado.
     */
    const quotas = this.props.formValue.paxQuotas.map(paxQuota => {
      if (paxTypeCode === paxQuota.paxType.code) {
        return {
          paxType: paxQuota.paxType,
          units,
        };
      } else {
        /*
         * Los elementos no modificados sí pueden (y deben) seguir siendo la
         * misma instancia.
         */
        return paxQuota;
      }
    });

    this.props.onChange(
      produce(this.props.formValue, formValue => {
        formValue.paxQuotas = quotas;
      })
    );
  };

  /** Handler para notificar la modificación de los puntos de recogida. */
  private changePickUp = (value?: string, onChange: changeFunc = this.onChange) => {
    onChange({ pickUpPointCode: value || "" });
  };

  /** Handler para notificar el cambio de observaciones. */
  private changeRemarks = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    this.props.onChange(
      produce(this.props.formValue, formValue => {
        formValue.remarks = value || "";
      })
    );
  };

  /** Inicializa y autoselecciona valores del formulario */
  private initFormValues() {
    const { excursionTicketOption } = this.props;
    const { languages, hotelZones, paxTypes } = excursionTicketOption;

    if (excursionTicketOption != null && paxTypes) {
      let newState: Partial<FormValue> = {};
      const callback = (newValue: Partial<FormValue>) => {
        newState = { ...newState, ...newValue };
      };

      const paxQuotas = paxTypes.map(paxType => ({
        /*
         * No se crea una instancia nueva de paxType porque nadie debería
         * cambiar la que viene.
         */
        paxType,
        units: 0,
      }));

      newState = { ...newState, paxQuotas };

      /*
       * Si hay ticket se mira si hay valores que se puedan auto seleccionar.
       * Son combos con una única opción.
       */
      if (languages && languages.length === 1) {
        // FIXME (a): Posible doble llamada, con FIXME(b)
        this.changeLanguage(languages[0].code, callback);
      }

      if (hotelZones && hotelZones.length === 1) {
        /*
         * Basta con mirar la zona de hotel. La selección de hotel y pickup
         * ya se hace en cascada en los handlers de cada combo en caso de
         * únicamente tengan una opción tras seleccionar la zona.
         */
        // FIXME (b): Ver FIXME(a)
        this.changeHotelZone(hotelZones[0].code, callback);
      }

      this.onChange(newState);
    }
  }

  /** Handler para notificar cambios en el formulario */
  private onChange = (formValue: Partial<FormValue>) => {
    // FIXME: Revisar si hay que usar produce o no a este nivel
    this.props.onChange({ ...this.props.formValue, ...formValue });
  };
}

export default withI18n(withStyles(styles)(ExcursionTicketForm));
