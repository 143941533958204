import React, { useCallback, useEffect, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";

import { LocalDate, TemporalAdjusters, YearMonth } from "js-joda";

import { ExcursionTicketOption, LocalDateString } from "~/services/webapi/types";

import CalendarBody from "./CalendarBody";
import CalendarControl from "./CalendarControl";
import CalendarHeader from "./CalendarHeader";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 0,
      flexShrink: 0,
      margin: theme.spacing(1),
    },
    topPane: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: "space-between",
    },
  })
);

/** Las propiedades del componente. */
export interface ProviedProps {
  /** La fecha actualmente seleccionada. */
  date?: LocalDate;

  /** Indica si un día debe estar habilitado. */
  isEnabled?: (ticketOption: ExcursionTicketOption) => boolean;

  /** Función para recuperar la disponibilidad entre fechas. */
  getTicketOptions?: (from: LocalDate, to: LocalDate) => void;

  /** Función para tratar el evento de selección de fecha. */
  onSelect?: (date: LocalDate) => void;

  /** Mapa con los valores de disponibilidad para cada día. */
  values?: Map<LocalDateString, ExcursionTicketOption> | null;
}

/**
 * Vista de un calendario.
 */
const AvailabilityCalendar: React.FC<ProviedProps> = props => {
  const { date, isEnabled, getTicketOptions, onSelect, values } = props;

  const calendarDate = date ? YearMonth.of(date.year(), date.month()) : YearMonth.now();

  /* Estado */
  const [month, setMonth] = useState(calendarDate);

  const classes = useStyles();

  useEffect(() => {
    function fetch() {
      if (getTicketOptions) {
        const from = month.atDay(1);
        const to = from.with(TemporalAdjusters.lastDayOfMonth());

        return getTicketOptions(from, to);
      }
    }
    fetch();
  }, [getTicketOptions, month]);

  /** Lanza el evento de selección de una fecha. */
  const selectDate = useCallback(
    (date: LocalDate) => {
      if (onSelect) {
        onSelect(date);
      }
    },
    [onSelect]
  );

  /** Carga el mes anterior del calendario. */
  const monthBack = () => {
    setMonth(month.minusMonths(1));
  };

  /** Carga el mes siguiente del calendario. */
  const monthNext = () => {
    setMonth(month.plusMonths(1));
  };

  return (
    <div className={classes.root}>
      <div className={classes.topPane}>
        <CalendarControl month={month} monthBack={monthBack} monthNext={monthNext} />
      </div>
      <Table>
        <CalendarHeader />
        <CalendarBody
          currentSelectedDate={date}
          isEnabled={isEnabled}
          month={month}
          values={values}
          onSelectDate={selectDate}
        />
      </Table>
    </div>
  );
};

export default AvailabilityCalendar;
