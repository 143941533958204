import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";

import { AppState } from "~/state";
import { loadView, ViewId } from "~/views";
import { wrap } from "~/views/ViewWrapper";

import SearcherView, { Props } from "./SearcherView";

import { bindActionCreators } from "redux";
import {
  fetchDestinations,
  fetchExcursions,
  setDestination,
  setDuration,
  setExcursion,
  setExcursionType,
  setFeature,
  setModality,
  setSegmentation,
} from "./actions";

interface OwnProps {
  bookingNumber?: string;
}

/** Propiedades derivadas del estado */
type StateProps = Pick<
  Props,
  | "agencyCode"
  | "destinationCode"
  | "destinations"
  | "duration"
  | "durations"
  | "excursion"
  | "excursionType"
  | "excursions"
  | "feature"
  | "features"
  | "modalities"
  | "modality"
  | "showAgency"
>;
const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (state, ownProps) => {
  return {
    agencyCode: state.bookingProcess.agency && state.bookingProcess.agency.code,
    bookingNumber: ownProps.bookingNumber,
    destinationCode: state.searchView.destination || undefined,
    destinations: state.searchView.destinations || undefined,
    duration: state.searchView.duration || undefined,
    durations: state.searchView.durations || undefined,
    excursion: state.searchView.excursion || undefined,
    excursionType: state.searchView.excursionType || undefined,
    excursionTypes: state.searchView.excursionTypes,
    excursions: state.searchView.excursions || undefined,
    feature: state.searchView.feature || undefined,
    features: state.searchView.features || undefined,
    modalities: state.searchView.modalities || undefined,
    modality: state.searchView.modality || undefined,
    segmentation: state.searchView.segmentation || undefined,
    segmentations: state.searchView.segmentations || undefined,
    showAgency: state.bookingProcess.agencies?.length !== 1,
  };
};

const loadAddEXcursionView = (excursionCode: string, modalityCode: string, bookingNumber?: string) =>
  loadView(ViewId.addExcursion, { bookingNumber, excursionCode, modalityCode });

/** Propiedades derivadas de acciones */
type DispatchProps = Pick<
  Props,
  | "fetchDestinations"
  | "fetchExcursions"
  | "onDestinationChange"
  | "onDurationChange"
  | "onExcursionChange"
  | "onExcursionTypeChange"
  | "onFeaturechange"
  | "onModaliytChange"
  | "onReload"
  | "onSearch"
  | "onSegementatonChange"
>;
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch, ownProps) =>
  bindActionCreators(
    {
      fetchDestinations,
      fetchExcursions,
      onDestinationChange: setDestination,
      onDurationChange: setDuration,
      onExcursionChange: setExcursion,
      onExcursionTypeChange: setExcursionType,
      onFeaturechange: setFeature,
      onModaliytChange: setModality,
      onReload: () => loadView(ViewId.searcher, undefined, true),
      onSearch: (excursionCode: string, modalityCode: string) =>
        loadAddEXcursionView(excursionCode, modalityCode, ownProps.bookingNumber),
      onSegementatonChange: setSegmentation,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(wrap(SearcherView));
