import React from "react";
import { LocalDate } from "js-joda";
import moize from "moize";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";

import BottomButtonsPane from "~/components/BottomButtonsPane";
import CalendarDatePicker from "~/components/CalendarDatePicker";
import { paxQuotaToDistribution } from "~/components/DistributionTable/types";
import LabeledText from "~/components/LabeledText";

import { TicketTemplate } from "~/services/booking/types";
import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { RecursivePartial } from "~/utils";

import AvailabilityCalendar, { AvailabilityCalendarProps } from "~/components/AvailabilityCalendar";
import ExcursionNotFound from "./components/ExcursionNotFound";

import { CodeName, Excursion, ExcursionTicket, ExcursionTicketOption } from "~/services/webapi/types";

import Clipboard from "~/icons/ClipboardArrowDown";
import ExcursionTicketForm, { FormValue } from "./components/ExcursionTicketForm/ExcursionTicketForm";

/** Estilos por defecto. */
const styles = (theme: Theme) =>
  createStyles({
    dateAndCancelPolicyPanel: {
      alignItems: "center",
      display: "flex",
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: "space-between",
    },
    fixedSize: {
      flexGrow: 0,
      flexShrink: 0,
    },
    notice: {
      "& > :first-child": {
        marginRight: theme.spacing(1),
      },
      display: "flex",
      marginTop: theme.spacing(1),
    },
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexShrink: 0,
    },
  });

/** Propiedades de entrada que acepta la vista cuando se carga. */
export interface PayloadProps {
  excursionCode: string;
  modalityCode: string;
}

/**
 * Propiedades del componente. Tanto las que vienen directas como las derivadas.
 */
export interface Props extends PayloadProps, WithStyles<typeof styles> {
  /** Nombre de la agencia que se está utilizando. */
  agencyName?: string;

  /** Divisa requerida. */
  currencyRestriction?: string;

  /** Excursión seleccionada. */
  excursion?: Excursion;

  /**
   * La opción del ticket seleccionado.
   * Si no existe la opción, entonces vendrá vacío.
   * Si existe pero no se puede vener ese día, vendrá sin avaiablitiy.
   */
  excursionTicketOption?: ExcursionTicketOption;

  /** Función para recuperar los datos de una excursión */
  getExcursion: () => void;

  /** Función para recuperar la disponibilidad de un día en concreto. */
  getTicketOption: (date: LocalDate) => void;

  /** Función para recuperar la disponibilidad entre fechas. */
  getTicketOptions: (from: LocalDate, to: LocalDate) => void;

  /** Disponibilida del mes en curso. */
  monthAvailability?: AvailabilityCalendarProps["values"];

  /** Función invocada para volver al buscador */
  onBackToSearcher: () => void;

  /**
   * Función invocada cuando se pulsa sobre el botón para realizar la reserva.
   */
  onReservation: (ticket: RecursivePartial<ExcursionTicket>) => void;

  /**
   * Indica que las acciones de inicialización de la vista ya se han ejecutado.
   */
  ready: boolean;

  /** Plantilla para copiar los datos del ticket. */
  ticketTemplate?: TicketTemplate;
}

/** Estado del componente. */
interface State {
  /** Indica si el calendario de disponibilidad está abierto. */
  calendarOpen: boolean;

  /** Datos del formulario */
  formValue: FormValue;

  /** Instancia último TicketTemplate copiado  */
  ticketTemplate?: TicketTemplate;
}

interface ProvidedProps extends Props, InjectedI18nProps {}

/*
 * Esto corrige que al poner el atributo fullWidth el paper sige manteniendo el
 * margin, mientras que no lo hace si usamos fullScreen.
 */
// TODO: Verificar si es un error de Material o si hay que pasar clases. Abrir issue en github.
const paperFix = { style: { margin: 8 } };

/**
 * Pantalla para añadir una excursión - modalidad.
 */
class AddExcursionView extends React.PureComponent<ProvidedProps, State> {
  /** Función para transformar de paxQuota a distribution. */
  /*
   * TODO: Ahora es así porque simplemente sumamos precios.
   * Si cambia la valoración con descuentos y otras cosas, habrá que pasarlo a
   * las funciones de reserva y tener otra estructura.
   */
  private asDistribution = moize.simple(paxQuotaToDistribution);

  /** #constructor */
  public constructor(props: ProvidedProps) {
    super(props);

    this.state = {
      calendarOpen: true,
      formValue: { paxQuotas: [] },
    };
  }

  /**
   * #didMount
   *
   * Una vez se ha montado el componente se debe lanzar la búsqueda para tener
   * la información de la excursión.
   * NOTA: Esto cambiará y se llevará a acciones.
   */
  public componentDidMount() {
    /*
     * TODO: Esto volará a acciones y no hará falta lanzar la carga aquí.
     * Entonces se deberá comprobar que esté o no ready.
     */
    this.props.getExcursion();
  }

  /**
   * #didlUpdate
   *
   * Una vez se recarga la información de la excursión
   * hay que actualizar el componente.
   */
  public componentDidUpdate(prevProps: Props) {
    if (this.props.excursionCode !== prevProps.excursionCode || this.props.modalityCode !== prevProps.modalityCode) {
      this.props.getExcursion();
    }

    if (this.props.excursion !== prevProps.excursion) {
      this.setState({ formValue: { paxQuotas: [] } });
    }
  }

  /**
   * #render
   */
  public render() {
    const { agencyName, excursion, excursionCode, excursionTicketOption, modalityCode, ready } = this.props;

    const { formatMessage } = this.props.i18n;

    /* Los casos excepcionales se renderizan aparte. */
    if (!ready) {
      /*
       * Está cargando. No debería llegar a este estado, pues se debería cambiar
       * a la vista una vez realizada la carga. En cualquier caso, se controla
       * para que no falle y se deja la vista en blanco.
       */
      return null;
    } else if (excursion == null || excursion.modalities == null || excursion.modalities.length === 0) {
      /*
       * La excursión no existe. No es que no exista disponibilidad, es que no
       * se encuentra ni la excursión. Esto sería un caso de error si se escanea
       * un QR con formato válido pero datos que no son correctos.
       */
      return <ExcursionNotFound {...{ excursionCode, modalityCode }} />;
    } else if (excursionTicketOption && excursionTicketOption.paxTypes == null) {
      return "Invalid ticket configuration.";
    }

    const { calendarOpen } = this.state;

    const { classes, currencyRestriction, monthAvailability, ticketTemplate } = this.props;

    const { name: excursionName, modalities } = excursion;

    const { name: modalityName } = modalities[0];

    const date = excursionTicketOption?.date ? LocalDate.parse(excursionTicketOption.date) : undefined;

    let addButtonEnabled = false;
    let available = false;
    let bookable = false;
    let copyButtonEnabled = false;
    let isValidCurrency = false;
    let notReservableMessage;

    if (excursionTicketOption && excursionTicketOption.paxTypes != null) {
      const { available: availableQuota = 0, freeSale } = excursionTicketOption;

      available = freeSale || availableQuota > 0;
      const ticketCurrency = excursionTicketOption.paxTypes[0].price.currency;
      isValidCurrency = currencyRestriction == null || currencyRestriction === ticketCurrency;
      bookable = available && isValidCurrency;
      copyButtonEnabled = bookable && ticketTemplate != null;

      if (!isValidCurrency) {
        notReservableMessage = formatMessage("addExcursionView.invalidCurrency");
      }

      const { hotelCode, languageCode, paxQuotas, pickUpPointCode } = this.state.formValue;
      const { pickUpService: hasPickUpService, maxAmount, paxMax, paxMin } = excursionTicketOption;

      const distribution = this.asDistribution(paxQuotas);
      const paxTotal = distribution.totalPaxCount;
      const priceDis = distribution.price;

      addButtonEnabled = Boolean(
        languageCode &&
          paxTotal > 0 &&
          (paxMax == null || paxMax >= paxTotal) &&
          (paxMin == null || paxMin <= paxTotal) &&
          (maxAmount == null || priceDis.totalPrice <= maxAmount) &&
          (!hasPickUpService || (pickUpPointCode && hotelCode))
      );
    }

    return (
      <div className={classes.root}>
        <LabeledText
          className={classes.fixedSize}
          label={formatMessage("addExcursionView.agency")}
          text={agencyName}
          textSize="large"
        />
        <LabeledText
          className={classes.fixedSize}
          label={formatMessage("addExcursionView.excursion")}
          text={excursionName}
          textSize="extraLarge"
        />
        <LabeledText
          className={classes.fixedSize}
          label={formatMessage("addExcursionView.modality")}
          text={modalityName}
          textSize="large"
        />
        <div className={classes.dateAndCancelPolicyPanel}>
          <CalendarDatePicker
            label={formatMessage("addExcursionView.calendar.label")}
            date={date}
            onOpen={this.calendarOpen}
          />
          <Dialog onClose={this.calendarClose} open={calendarOpen} PaperProps={paperFix}>
            <AvailabilityCalendar
              date={date}
              onSelect={this.changeSelectedDate}
              getTicketOptions={this.props.getTicketOptions}
              values={monthAvailability}
            />
          </Dialog>

          {copyButtonEnabled && (
            <Button startIcon={<Clipboard color="action" />} onClick={this.copyTemplate}>
              {formatMessage("addExcursionView.copyTemplate")}
            </Button>
          )}
        </div>

        {excursionTicketOption && available && (
          <ExcursionTicketForm
            bookable={bookable}
            formValue={this.state.formValue}
            excursionTicketOption={excursionTicketOption}
            onChange={this.changeData}
          />
        )}

        {notReservableMessage && (
          <div className={classes.notice}>
            <InfoIcon color="action" />
            <Typography>{notReservableMessage}</Typography>
          </div>
        )}

        <BottomButtonsPane>
          <Button variant="outlined" color="default" onClick={this.props.onBackToSearcher}>
            {formatMessage("addExcursionView.back")}
          </Button>
          {bookable && (
            <Button variant="outlined" color="default" onClick={this.addTicket} disabled={!addButtonEnabled}>
              {formatMessage("addExcursionView.book")}
            </Button>
          )}
        </BottomButtonsPane>
      </div>
    );
  }

  /**
   * Función para cerrar el calendario cuando el usuario pulsa sobre el selector
   * de fecha.
   */
  private calendarClose = () => {
    this.setState({ calendarOpen: false });
  };

  /**
   * Función para abrir el calendario cuando el usuario pulsa sobre el selector
   * de fecha.
   */
  private calendarOpen = () => {
    this.setState({ calendarOpen: true });
  };

  /**
   * Handler para actualizar el estado.
   */
  private changeData = (newValue: FormValue) => {
    this.setState({ formValue: newValue });
  };

  /**
   * Función para tratar cuando el usuario cambia de fecha seleccionada.
   */
  private changeSelectedDate = (date: LocalDate) => {
    this.calendarClose();
    this.props.getTicketOption(date);
  };

  /**
   * Añade el ticket seleccionado a la reserva con los datos introducidos en el
   * formulario.
   */
  private addTicket = () => {
    const excursionTicket = this.createExcursionTicket();
    if (excursionTicket) {
      this.props.onReservation(excursionTicket);
    }
  };

  /**
   * Crea el objeto Ticket a reservar. Únicamente se deben informar los campos
   * necesarios para añadir el ticket a la reserva.
   */
  private createExcursionTicket = (): RecursivePartial<ExcursionTicket> | null => {
    const { excursionTicketOption } = this.props;

    const {
      hotelCode,
      hotelReference,
      hotelZoneCode,
      languageCode,
      paxQuotas,
      pickUpPointCode,
      remarks,
    } = this.state.formValue;

    const { agencyCode, date, excursionCode, modalityCode } = excursionTicketOption || {};

    return {
      agencyCode,
      excursionCode,
      excursionDate: date,
      hotel: { code: hotelCode, zoneCode: hotelZoneCode },
      hotelReference,
      language: { code: languageCode },
      modalityCode,
      /*
       * Se mapea para retornar únicamente los códigos. Cierto que el servidor
       * debería ignorar los datos que sobran si se envían... pero quedará un
       * poco más limpio así.
       */
      paxes: paxQuotas?.map(p => ({
        paxType: { code: p.paxType.code },
        units: p.units,
      })),
      pickupPoint: { code: pickUpPointCode },
      remarks,
    };
  };

  /**
   * Función para rellenar el ticket a partir de los datos de la plantilla.
   *
   * TODO: Pedir confirmación antes de copiar si ya se han introducido datos.
   */
  private copyTemplate = () => {
    const template = this.props.ticketTemplate;
    if (template != null) {
      const { languageCode, hotelZoneCode, hotelCode, pickupPointCode, hotelReference, paxes, remarks } = template;

      const newState: FormValue = {
        hotelCode: undefined,
        hotelReference: undefined,
        hotelZoneCode: undefined,
        languageCode: undefined,
        paxQuotas: [],
        pickUpPointCode: undefined,
        remarks,
      };

      const quotas: { [k: string]: number } = {};

      if (paxes) {
        paxes.forEach(pq => (quotas[pq.paxType.code] = pq.units));
      }

      newState.paxQuotas = this.state.formValue.paxQuotas.map(paxQuota => ({
        ...paxQuota,
        units: quotas[paxQuota.paxType.code] || 0,
      }));

      const languages = this.props.excursionTicketOption?.languages;
      if (languages && languageCode && this.isValid(languageCode, languages)) {
        newState.languageCode = languageCode;
      }

      if (this.props.excursionTicketOption?.pickUpService) {
        const hotelZones = this.props.excursionTicketOption?.hotelZones;
        if (hotelZones && hotelZoneCode && this.isValid(hotelZoneCode, hotelZones)) {
          newState.hotelZoneCode = hotelZoneCode;

          const hotels = this.props.excursionTicketOption?.hotels;
          if (hotels) {
            const zoneHotels = hotels.filter(h => h.zoneCode === hotelZoneCode);

            if (zoneHotels && hotelCode && this.isValid(hotelCode, zoneHotels)) {
              newState.hotelCode = hotelCode;
              newState.hotelReference = hotelReference;

              const hotel = zoneHotels.find(h => h.code === hotelCode);
              if (hotel && hotel.pickUpPoints && pickupPointCode && this.isValid(pickupPointCode, hotel.pickUpPoints)) {
                newState.pickUpPointCode = pickupPointCode;
              }
            }
          }
        }
      }

      this.setState({ formValue: newState });
    }
  };

  /** Comprueba si el valor es válido para la lista indicada. */
  private isValid = (value: string, options: CodeName[]) => options.findIndex(({ code }) => value === code) !== -1;
}

export default withI18n(withStyles(styles)(AddExcursionView));
