import JsBarcode from "jsbarcode";

import { BitmapData } from "./types";

/** Configuración por defecto. */
const DEFAULTS: JsBarcode.BaseOptions = {
  format: "CODE128",
  height: 150,
  width: 2,
};

/**
 * Genera una imagen con el código de barras.
 *
 * @param data datos del código de barras.
 * @param type tipo de código de barras a generar.
 */
export function createBarcodeBitMapData(data: string, type?: string): BitmapData {
  const canvas = window.document.createElement("canvas");

  renderBarcode(canvas, data, {
    format: type,
    marginBottom: 32,
    marginTop: 32,
  });

  let imageData: string;

  if (canvas.width > 380) {
    const canvasRotated = window.document.createElement("canvas");
    canvasRotated.width = canvas.height;
    canvasRotated.height = canvas.width;

    const ctx = canvasRotated.getContext("2d");
    if (ctx) {
      ctx.translate(canvas.height, 0);
      ctx.rotate(Math.PI / 2);
      ctx.drawImage(canvas, 0, 0);
    }
    imageData = canvasRotated.toDataURL("image/bmp", 1.0);
  } else {
    imageData = canvas.toDataURL("image/bmp", 1.0);
  }

  return {
    base64Data: imageData.substr("data:image/bmp;base64, ".length - 1),
    height: canvas.height,
    width: canvas.width,
  };
}

/**
 * Renderiza el código de barras en el canvas indicado.
 */
function renderBarcode(canvas: HTMLCanvasElement, data: string, options: JsBarcode.BaseOptions = DEFAULTS) {
  JsBarcode(canvas, data, { ...DEFAULTS, ...options });
}
