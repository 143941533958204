import React from "react";

import { useDispatch } from "react-redux";

import { loadView } from "~/views/actions";
import { ViewId } from "~/views/types";

import QRExcursion from "./QRExcursion";

/**
 * Lógica del lector qr de excursiones.
 */
const QRExcursionController: React.FC = () => {
  const dispatch = useDispatch();

  const loadExcursionView = (excursionCode: string, modalityCode: string) => {
    dispatch(loadView(ViewId.addExcursion, { excursionCode, modalityCode }));
  };

  return <QRExcursion onScanExcursionQR={loadExcursionView} />;
};

export default QRExcursionController;
