import { Mutex } from "async-mutex";

import { createBarcodeBitMapData } from "./barcode";
import { bitmapFromBase64Image, bitmapFromImage } from "./image";
import { Alignment, BitmapData, PrintingOptions, QRErrorLevel, QRPrintingOptions, SunmiInnerPrinter } from "./types";

declare global {
  interface Window {
    sunmiInnerPrinter: SunmiInnerPrinter;
  }
}

const printerMutex = new Mutex();

const SET_BOLD = [0x1b, 0x45, 0x1];
const SET_UNDERLINE = [0x1b, 0x21, 0x80];
const CANCEL_BOLD = [0x1b, 0x45, 0x0];
const CANCEL_UNDERLINE = [0x1b, 0x21, 0x0];

const DEFAULT_QR_PRINTING_OPTIONS: QRPrintingOptions = {
  errorLevel: QRErrorLevel.L,
  headingWhiteLines: 1,
  moduleSize: 8,
  trailingWhiteLines: 1,
};

const DEFAULT_TEXT_PRINTING_OPTIONS: PrintingOptions = {
  headingWhiteLines: 0,
  trailingWhiteLines: 1,
};

const DEFAULT_BITMAP_PRINTING_OPTIONS: PrintingOptions = {
  headingWhiteLines: 1,
  trailingWhiteLines: 1,
};

const DEFAULT_COLS_PRINTING_OPTIONS: PrintingOptions = {
  headingWhiteLines: 0,
  trailingWhiteLines: 0,
};

export enum FontSize {
  small = 16,
  normal = 24,
  large = 34,
}

export function buildPrintBarcode(data: string, type?: string): PrintCall {
  return buildPrintBitmap(createBarcodeBitMapData(data, type));
}

/**
 * Imprime la imagen representada en un string base64.
 * @param data datos de la imagen en base64.
 */
export function buildPrintB64Image(data: string): PrintCall {
  return async (printer: SunmiInnerPrinter) => {
    const bitmapData = await bitmapFromBase64Image(data);
    await printer.printBitmap(bitmapData.base64Data, bitmapData.width, bitmapData.height);
  };
}

export function buildPrintImage(image: HTMLImageElement): PrintCall {
  return buildPrintBitmap(bitmapFromImage(image));
}

function BufferToBase64(buf: Uint16Array) {
  const binstr = Array.prototype.map
    // XXX: Revisar este any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .call(buf, (ch: any) => {
      return String.fromCharCode(ch);
    })
    .join("");

  return btoa(binstr);
}

export function setAlignment(alignment: Alignment): PrintCall {
  return async (printer: SunmiInnerPrinter) => {
    await printer.setAlignment(alignment);
  };
}

export function setFontSize(size: FontSize): PrintCall {
  return async (printer: SunmiInnerPrinter) => {
    await printer.setFontSize(size);
  };
}

export function resetFontSize(): PrintCall {
  return setFontSize(FontSize.normal);
}

export function resetAlignment(): PrintCall {
  return setAlignment(Alignment.LEFT);
}

export function setUnderline(): PrintCall {
  return sendRaw(SET_UNDERLINE);
}

export function cancelUnderline(): PrintCall {
  return sendRaw(CANCEL_UNDERLINE);
}

export function setBold(): PrintCall {
  return sendRaw(SET_BOLD);
}

export function cancelBold(): PrintCall {
  return sendRaw(CANCEL_BOLD);
}

export function printAndFeed(whiteLines = 1): PrintCall {
  return sendRaw([0x1b, 0x4a, whiteLines]);
}

function sendRaw(data: number[]): PrintCall {
  const uint16 = new Uint16Array(data);
  const base64 = BufferToBase64(uint16);

  return async (printer: SunmiInnerPrinter): Promise<void> => {
    await printer.sendRAWData(base64);
  };
}

export function buildPrintBitmap(bitmapData: BitmapData): PrintCall {
  return async (printer: SunmiInnerPrinter) => {
    await printer.printBitmap(bitmapData.base64Data, bitmapData.width, bitmapData.height);
  };
}

/**
 * Prints an image
 *
 * @param bitmapData image data
 * @param options printing options
 */
export async function printBitmap(bitmapData: BitmapData, options: Partial<PrintingOptions> = {}) {
  if (!bitmapData) {
    throw new Error("Nothing to print");
  }
  if (!bitmapData.base64Data || !bitmapData.height || !bitmapData.width) {
    throw new Error("IllegalArgument: bitmapData not valid");
  }
  if (bitmapData.height < 1) {
    throw new Error("IllegalArgument: bitmapData.height must be > 0");
  }
  if (bitmapData.width < 1 || bitmapData.width > 380) {
    throw new Error("IllegalArgument: bitmapData.width must be beween 1 and 380");
  }

  const pOptions = { ...DEFAULT_BITMAP_PRINTING_OPTIONS, ...options };

  await print(pOptions, printer => printer.printBitmap(bitmapData.base64Data, bitmapData.width, bitmapData.height));
}

export function buildPrintColumnsText(
  colsTextArr: string[],
  colsWidthArr: number[],
  colsAlign: Alignment[]
): PrintCall {
  return innerPrintColumnsText(colsTextArr, colsWidthArr, colsAlign);
}

/**
 * Imprime texto en columnas (la fila de una tabla)
 *
 * @param colsTextArr el texto de cada una de las columnas
 * @param colsWidthArr el ancho de cada una de las columnas
 * @param colsAlign la alineación del texto en cada una de las columnas
 */
export async function printColumnsText(
  colsTextArr: string[],
  colsWidthArr: number[],
  colsAlign: Alignment[],
  options: Partial<PrintingOptions> = {}
): Promise<void> {
  const pOptions = { ...DEFAULT_COLS_PRINTING_OPTIONS, ...options };

  await print(pOptions, innerPrintColumnsText(colsTextArr, colsWidthArr, colsAlign));
}

/**
 * Función para texto en clumnas sin utilizar la nativa de la impresora pues
 * falla si el salto de línea se hacen en carácteres no ascii.
 *
 * @param colsTextArr el texto de cada una de las columnas
 * @param colsWidthArr el ancho de cada una de las columnas
 * @param colsAlign la alineación del texto en cada una de las columnas
 */
function innerPrintColumnsText(colsTextArr: string[], colsWidthArr: number[], colsAlign: Alignment[]) {
  return async (printer: SunmiInnerPrinter) => {
    const chunkedTextArr = colsTextArr.map(
      (text = "", idx) =>
        // Esto hace n-chunks del tamaño de la colúmna
        text.match(new RegExp(`.{1,${colsWidthArr[idx]}}`, "g")) || []
    );

    const rowsCount = chunkedTextArr.reduce((max, textChunks) => Math.max(textChunks.length, max), 0);
    let row = 0;
    while (row < rowsCount) {
      const columnString = chunkedTextArr.reduce((columnText, texts, idx) => {
        let cellText = texts[row] || "";

        switch (colsAlign[idx]) {
          case Alignment.RIGHT:
            cellText = cellText.padStart(colsWidthArr[idx]);
            break;

          case Alignment.CENTER: {
            const spaceToFill = colsWidthArr[idx] - cellText.length;
            if (spaceToFill > 0) {
              const atStart = Math.ceil(spaceToFill / 2);
              const atEnd = Math.floor(spaceToFill / 2);

              cellText = cellText.padStart(atStart).padEnd(atEnd);
            }
            break;
          }
          case Alignment.LEFT:
          /* falls through */
          default:
            cellText = cellText.padEnd(colsWidthArr[idx]);
        }

        return columnText + cellText;
      }, "");

      await printer.printString(columnString + "\n");

      row += 1;
    }
  };
}

/**
 * Prints a QR
 *
 * @param qrCode QR data
 * @param options printing options
 */
export async function printQR(qrCode: string, options: Partial<QRPrintingOptions> = {}): Promise<void> {
  if (!qrCode) {
    throw new Error("Nothing to print");
  }

  const pOptions = { ...DEFAULT_QR_PRINTING_OPTIONS, ...options };

  await print(pOptions, printer => printer.printQRCode(qrCode, pOptions.moduleSize, pOptions.errorLevel));
}

export function buildPrintQR(qrCode: string): PrintCall {
  return async (printer: SunmiInnerPrinter) => {
    await printer.printQRCode(qrCode, 6, QRErrorLevel.M);
  };
}

export function buildPrintString(text: string): PrintCall {
  return async (printer: SunmiInnerPrinter) => {
    await printer.printString(text);
  };
}

/**
 * Prints text
 *
 * @param text text to print
 * @param options printing options
 */
export async function printString(text: string, options: Partial<PrintingOptions> = {}) {
  if (!text) {
    throw new Error("Nothing to print");
  }

  const pOptions = { ...DEFAULT_TEXT_PRINTING_OPTIONS, ...options };

  await print(pOptions, printer => printer.printString(text));
}

export async function printTextWithFont(text: string, fontSize: number, options: Partial<PrintingOptions> = {}) {
  if (!text) {
    throw new Error("Nothing to print");
  }

  const pOptions = { ...DEFAULT_TEXT_PRINTING_OPTIONS, ...options };

  await print(pOptions, printer => printer.printTextWithFont(text, "", fontSize));
}

export type PrintCall = (printer: SunmiInnerPrinter) => Promise<void>;

export async function print(options: PrintingOptions, ...printCalls: PrintCall[]) {
  return printerMutex.runExclusive(() => sendToPrinter(options, ...printCalls));
}

async function sendToPrinter(options: PrintingOptions, ...printCalls: PrintCall[]) {
  const printer = window.sunmiInnerPrinter;
  if (!printer) {
    throw new Error("Printer not found");
  }

  try {
    if (!(await printer.hasPrinter())) {
      throw new Error("Printer not found");
    }
  } catch (err) {
    throw new Error("Printer not found: " + err);
  }
  try {
    await printer.printerInit();
  } catch (err) {
    throw new Error("Unable to init printer:" + err);
  }
  if (options.headingWhiteLines > 0) {
    try {
      await printer.lineWrap(options.headingWhiteLines);
    } catch (err) {
      throw new Error("Error on print heading lineWrap: " + err);
    }
  }
  try {
    for (const printCall of printCalls) {
      await printCall(printer);
    }
  } catch (err) {
    throw new Error("Error on print: " + err);
  }
  if (options.trailingWhiteLines > 0) {
    try {
      await printer.lineWrap(options.trailingWhiteLines);
    } catch (err) {
      throw new Error("Error on print trailing lineWrap: " + err);
    }
  }
}
