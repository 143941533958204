/* Etiquetas multiidoma en castellano. */
export default {
  "printLiquidation.dateFrom": "Desde: {dateFrom}",
  "printLiquidation.dateTo": "Hasta: {dateTo}",
  "printLiquidation.detail.sales": "Ventas",
  "printLiquidation.detail.refunds": "Devoluciones",
  "printLiquidation.detail.total": "Total",
  "printLiquidation.paymentType.card": "Tarjeta:",
  "printLiquidation.paymentType.cash": "Efectivo:",
  "printLiquidation.paymentType.general": "General:",
  "printLiquidation.printTime": "Impresión: {time}",
  "printLiquidation.ticketsSold": "Nº tickets vendidos",
  "printLiquidation.ticketsCancelled": "Nº tickets anulados",
};
