import { Action } from "redux";

import { AppThunk } from "~/state";

import { getAppConfig as apiGetAppConfig } from "../webapi/configuration";
import { AppConfig } from "../webapi/types";

/** Definición de acciones */
export const SET_APP_CONFIG = "Config/SET_APP_CONFIG";

/** Acción para establecer la configuración de la app */
interface SetAppConfig extends Action {
  appConfig: AppConfig;
  type: typeof SET_APP_CONFIG;
}

/** Genera una acción para establecer la configuración */
export const setAppConfig = (appConfig: AppConfig): SetAppConfig => ({ appConfig, type: SET_APP_CONFIG });

/** Acciones del módulo */
export type ConfigAction = SetAppConfig;

/** Recupera la configuración de la aplicación. */
function getAppConfig(): AppThunk {
  return async dispatch => {
    const appConfig = await apiGetAppConfig();

    dispatch(setAppConfig(appConfig));
  };
}

/** Handler para registrarse en el evento de autenticación */
export const initConfig = (): AppThunk => getAppConfig();
