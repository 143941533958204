import React, { useMemo } from "react";

import { LocalDate, YearMonth } from "js-joda";

import TableRow from "@material-ui/core/TableRow";

import { ExcursionTicketOption, LocalDateString } from "~/services/webapi/types";

import CalendarCell from "./CalendarCell";

export interface CalendarRowProps {
  /** La fecha actualmente seleccionada. */
  currentSelectedDate?: LocalDate;

  /** Indica si un día debe estar habilitado. */
  isEnabled?: (ticketOption: ExcursionTicketOption) => boolean;

  /** Día en el que empieza la fila. */
  firstDayOfWeek: LocalDate;

  /** Mes que se está visualizando. */
  month: YearMonth;

  /** Función invocada al seleccionar una celda. */
  onSelectDate: (date: LocalDate) => void;

  /** Mapa con los valores de disponibilidad para cada día. */
  values?: Map<LocalDateString, ExcursionTicketOption> | null;
}

/**
 * Vista de la fila de un calendario.
 */
const CalendarRow: React.FC<CalendarRowProps> = props => {
  const { currentSelectedDate, firstDayOfWeek, isEnabled, month, onSelectDate, values } = props;

  /**
   * Genera las celdas de la semana indicada.
   */
  const buildWeekCells = useMemo(() => {
    const cells: React.ReactNode[] = [];

    let day = firstDayOfWeek;
    for (let i = 1; i <= 7; i++) {
      const isCurrentMonth = month.monthValue() === day.monthValue();
      const isSelectedDay = day.equals(currentSelectedDate);

      cells.push(
        <CalendarCell
          day={day}
          isCurrentMonth={isCurrentMonth}
          isEnabled={isEnabled}
          isSelectedDay={isSelectedDay}
          onSelectDate={onSelectDate}
          values={values}
        />
      );
      day = day.plusDays(1);
    }

    return cells;
  }, [currentSelectedDate, isEnabled, firstDayOfWeek, month, onSelectDate, values]);

  return <TableRow key={firstDayOfWeek.isoWeekOfWeekyear()}>{buildWeekCells}</TableRow>;
};

export default CalendarRow;
