import React from "react";

//import { LocalDateTime } from "js-joda";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Print from "@material-ui/icons/Print";

//import { formatLocalDateString, formatLocalDateTimeString } from "~/services/i18n/i18nApiImpl";
import { Seller } from "~/services/webapi/types";

/** Estilos del componente. */
const useStyles = makeStyles(theme =>
  createStyles({
    dateRangeStyle: {
      color: theme.palette.text.secondary,
    },
    dataStyle: {
      flex: "1 1 auto",
    },
    fontText: {
      fontSize: "0.875rem",
    },
    headerStyle: {
      alignItems: "flex-start",
      color: theme.palette.text.primary,
      display: "flex",
    },
    printStyle: {
      flex: "0 0 auto",
      marginRight: -8,
      marginTop: -8,
    },
  })
);

interface HeaderProps {
  /** Fecha de inicio del rango */
  dateFrom: string;

  /** Fecha de fin del rango */
  dateTo: string;

  /** Vendedor creador/responsable de la reserva */
  seller: Seller;

  /** Identificador de terminal */
  terminalId?: string;

  /** Función invocada al imprimir la liquidación */
  onPrintLiquidation: () => void;
}

const Header: React.FC<HeaderProps> = props => {
  const {
    dateTo,
    dateFrom,
    seller: { name: sellerName },
    terminalId,
    onPrintLiquidation,
  } = props;

  const classes = useStyles();

  /* Render */
  return (
    <div className={classes.headerStyle}>
      <div className={classes.dataStyle}>
        <div className={classes.fontText}>{sellerName}</div>

        <div className={classes.fontText}>{terminalId}</div>

        <div className={(classes.dateRangeStyle, classes.fontText)}>
          {dateFrom} - {dateTo}
        </div>
      </div>
      <div className={classes.printStyle}>
        <IconButton onClick={onPrintLiquidation}>
          <Print />
        </IconButton>
      </div>
    </div>
  );
};

export default Header;
