import React from "react";
import Selector, { SelectorProps } from "~/components/Selector";
import { CodeName } from "~/services/webapi/types";

const getSelectorValue = (item: CodeName) => {
  return { code: item.code, description: item.name };
};

export type Props = Omit<SelectorProps<CodeName>, "getSelectorValue">;

const CodeNameSelector: React.FC<Props> = props => {
  return <Selector<CodeName> getSelectorValue={getSelectorValue} {...props}></Selector>;
};

export default CodeNameSelector;
