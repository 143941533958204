/// <reference types="cordova-plugin-file" />

import { readBlobAsBase64 } from "./blobs";

/**
 * Recupera el archivo correspondiente a la ruta indicada.
 *
 * @param path ruta del archivo
 */
export function getFile(path: string): Promise<Entry> {
  return new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(
      `file:///${path}`,
      /* Se accede por filesystem.root porque sino el plugin no pide permisos */
      /* Diría que es un bug del plugin, debería consultarse en github. */
      fileEntry => fileEntry.filesystem.root.getFile(fileEntry.fullPath, {}, resolve, reject),
      fileError => reject(fileError)
    );
  });
}

/**
 * Lee el contenido del ficher indicado y lo devuelve como un string codificado
 * en base64.
 *
 * @param filePath la ruta del archivo
 */
export async function readFileAsBase64(path: string): Promise<string> {
  const entry = await getFile(path);
  if (entry.isFile) {
    return await readFileEntryAsBase64(entry as FileEntry);
  } else {
    throw new Error(`Is not a file: ${path}`);
  }
}

/**
 * Lee el contenido del ficher indicado y lo devuelve como un string codificado
 * en base64.
 *
 * @param fileEntry el archivo a leer
 */
export function readFileEntryAsBase64(fileEntry: FileEntry): Promise<string> {
  return new Promise((resolve, reject) => {
    fileEntry.file(file => {
      readBlobAsBase64(file).then(resolve, reject);
    });
  });
}
