/* Etiquetas multiidoma en inglés. */
export default {
  "rescheduleView.confirm.cancelContinue": "¿Continue?",
  "rescheduleView.confirm.message": "It will cancel the current ticket and create a new one with date: ",
  "rescheduleView.confirm.title": "Change date",
  "rescheduleView.errorDialog.message": "Error when changing the date of the ticket. Check the reservation.",
  "rescheduleView.errorDialog.title": "Error when changing the date.",
  "rescheduleView.feeDialog.message": "Cannot change the ticket's date because it has cancellation fees.",
  "rescheduleView.feeDialog.title": "Cancellation fees.",
};
