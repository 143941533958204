/* Etiquetas multiidoma en castellano. */
export default {
  "bookingView.agency": "Agencia",
  "bookingView.bookingNumber": "Localizador",
  "bookingView.bookingStatus": "Reserva {status}",
  "bookingView.bookingStatus.CANCELLED": "Cancelada",
  "bookingView.bookingStatus.CONFIRMED": "Confirmada",
  "bookingView.bookingStatus.ON_REQUEST": "Pendiente de confirmar",
  "bookingView.cancelContinue": "¿Continuar?",
  "bookingView.cancelTicket": "Anular ticket",
  "bookingView.cancelTicketAlert": "Se dispone a anular un ticket.",
  "bookingView.cancellationAmount": "Gastos de cancelación",
  "bookingView.cancelled": "Ticket anulado",
  "bookingView.contact": "Contacto",
  "bookingView.creationDate": "Fecha de creación",
  "bookingView.editBooking": "Editar",
  "bookingView.editCancel": "Descartar",
  "bookingView.editSave": "Guardar",
  "bookingView.error.cancelTicket": "Error durante la anulación del ticket.",
  "bookingView.error.fetchCancelCost": "No se han podido recuperar los gastos de cancelación.",
  "bookingView.errorTitle": "Error",
  "bookingView.hideCancelled": "Ocultar anulados",
  "bookingView.mode.default": " ",
  "bookingView.mode.editing": "Editando",
  "bookingView.paymentReference": "Referencia",
  "bookingView.paymentType": "Tipo de pago",
  "bookingView.paymentType.CARD": "Tarjeta",
  "bookingView.paymentType.CASH": "Metálico",
  "bookingView.phoneNumber": "Teléfono",
  "bookingView.printAllTickets": "Imprimir todos los tickets de la reserva",
  "bookingView.printCancelTicket": "¿Imprimir ticket de anulación?",
  "bookingView.printVoucher": "Recibo",
  "bookingView.showCancelled": "Mostrar anulados",
  "bookingView.startNewBooking": "Empezar nueva Reserva",
};
