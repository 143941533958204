import React, { useCallback, useState } from "react";
import { LocalDate } from "js-joda";

import { useAsyncCancelableEffect as useAsyncEffect } from "~/hooks/asyncHooks";
import { findTicketOptions } from "~/services/booking";
import { useI18n } from "~/services/i18n";
import { get as apiGetBooking } from "~/services/webapi/bookings";
import { Booking, ExcursionTicket, ExcursionTicketOption, LocalDateString } from "~/services/webapi/types";
import { useViewContext } from "~/views/ViewWrapper";

import { rescheduleTicket as reschedule } from "./actions";

import RescheduleTicket, { RescheduleTicketProps } from "./RescheduleTicketView";

export interface RescheduleControllerProps {
  /** Número de reserva que se está modificando. */
  bookingNumber: string;

  /** Función invocada cuando ha cambiado la reserva.  */
  onBookingChange: (booking: Booking) => void;

  /** Función invocada cuando finaliza el cambio de ticket. */
  onClose: (error?: string) => void;

  /** Número de ticket al que se cambia la fecha. */
  ticketNumber: string;
}

/**
 * Lógica de la vista RescheduleTicket.
 */
const RescheduleTicketController: React.FC<RescheduleControllerProps> = props => {
  const { bookingNumber, onBookingChange, onClose, ticketNumber } = props;

  const { showDialog } = useViewContext();

  const { formatMessage } = useI18n();

  const [agencyCode, setAgencyCode] = useState<string | undefined>();

  /** Fecha seleccionada del ticket. */
  const [date, setDate] = useState<LocalDate | undefined>();

  /** Mapa con los valores de disponibilidad para cada día. */
  const [excursionOptionMap, setExcursionOptionMap] = useState<Map<LocalDateString, ExcursionTicketOption>>();

  /** Gastos de cancelación del ticket seleccionado. */
  const [fee, setFee] = useState<number | null>();

  /** Ticket que se está reprogramando. */
  const [ticket, setTicket] = useState<ExcursionTicket | undefined>();

  /* Callbacks */
  const rescheduleTicket = useCallback((date: LocalDate) => {
    setDate(date);
  }, []);

  /** Función para recuperar la disponibilidad entre fechas. */
  const getTicketOptions = useCallback(
    async (from: LocalDate, to: LocalDate) => {
      if (!ticket) {
        return;
      }

      const ticketOptions = await findTicketOptions(
        ticket.excursionCode,
        ticket.modalityCode,
        agencyCode,
        from.toString(),
        to.toString()
      );

      const ticketOptionsTmp = ticketOptions.reduce(
        (map, value) => (value.date ? map.set(value.date.toString(), value) : map),
        new Map<LocalDateString, ExcursionTicketOption>()
      );

      setExcursionOptionMap(ticketOptionsTmp);
    },
    [agencyCode, ticket]
  );

  /* Efectos */
  useAsyncEffect(
    async () => {
      const bookingTmp = await apiGetBooking(bookingNumber).catch(() => {
        /* No hacer nada. */
      });
      if (bookingTmp) {
        return bookingTmp;
      }
      return null;
    },
    bookingTmp => {
      if (bookingTmp) {
        setAgencyCode(bookingTmp.agency.code);
        const ticketFound = bookingTmp.tickets.find(t => t.ticketNumber === ticketNumber);
        setTicket(ticketFound);
        if (ticketFound) {
          const price = ticketFound.price;
          setFee(price.cancellationAmount);
        } else {
          setFee(null);
        }
      } else {
        setFee(null);
      }
    },
    () => {
      /* Nunca debería, tenemos un .catch arriba. */
    },
    [bookingNumber, ticketNumber]
  );

  useAsyncEffect(
    async () => {
      if (fee !== undefined) {
        if (fee === null || fee !== 0) {
          return showDialog(
            formatMessage("rescheduleView.feeDialog.message"),
            formatMessage("rescheduleView.feeDialog.title")
          );
        }
      }

      return true;
    },
    nextStep => {
      if (!nextStep) {
        onClose();
      }
    },
    () => {
      /* Si falla al mostrar el error no hacemos nada. */
    },
    [fee]
  );

  useAsyncEffect(
    async () => {
      if (date) {
        if (ticket) {
          return reschedule(bookingNumber, ticket, date);
        }
      }

      return null;
    },
    booking => {
      if (booking) {
        onBookingChange(booking);
      }
    },
    () => {
      showDialog(
        formatMessage("rescheduleView.errorDialog.message"),
        formatMessage("rescheduleView.errorDialog.title")
      );
    },
    [date]
  );

  const showCalendar = fee === 0;

  const viewProps: RescheduleTicketProps = {
    excursionOptionMap: excursionOptionMap,
    onClose: onClose,
    onGetTicketOptions: getTicketOptions,
    onRescheduleTicket: rescheduleTicket,
    showCalendar: showCalendar,
    ticket: ticket,
  };

  return <RescheduleTicket {...viewProps} />;
};

export default RescheduleTicketController;
