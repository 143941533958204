import React from "react";

import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

/**
 * Icono composición del "Done" junto con unas ondas tipo wifi para indicar
 * comunicación inalámbrica con otro dispositivo.
 */
const DoneOutlineContactless: React.SFC<SvgIconProps> = props => (
  <SvgIcon {...props}>
    <g>
      {/* eslint-disable max-len */}
      <path d="M19.77 4.03l1.4 1.4L8.43 18.17l-5.6-5.6 1.4-1.4 4.2 4.2L19.77 4.03m0-2.83L8.43 12.54l-4.2-4.2L0 12.57 8.43 21 24 5.43 19.77 1.2z" />
      <path d="M15.1 15.01c.26.56.39 1.16.4 1.8.01.63-.13 1.25-.4 1.86l1.37.62c.37-.81.55-1.65.54-2.5-.01-.84-.19-1.65-.54-2.4l-1.37.62zM21.33 12.16c.78 1.57 1.18 3.14 1.18 4.64 0 1.51-.4 3.09-1.18 4.69l1.35.66c.88-1.81 1.33-3.61 1.33-5.35 0-1.74-.45-3.53-1.33-5.31l-1.35.67zM18.2 13.55c.53 1.07.8 2.21.8 3.4 0 1.17-.26 2.23-.78 3.15l1.3.74c.65-1.15.98-2.45.98-3.89 0-1.42-.32-2.79-.96-4.07l-1.34.67z" />
      {/* eslint-enable max-len */}
    </g>
  </SvgIcon>
);

export default DoneOutlineContactless;
