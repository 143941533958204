/**
 * Lee el contenido de un Blob y lo devuelve como un string codificado en base64
 *
 * @param blob el Blob a leer
 */
export function readBlobAsBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function() {
      const content = this.result;
      if (typeof content === "string") {
        const base64Index = content.indexOf(";base64,");
        if (base64Index !== -1) {
          resolve(content.substring(base64Index + 8));
          return;
        }
      }

      reject("Unexpected content.");
    };

    reader.onerror = function() {
      reject(this.error);
    };

    reader.readAsDataURL(blob);
  });
}
