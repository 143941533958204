import client from "./client";

import { LiquidationReport } from "./types";

import { LocalDateTimeString } from "~/services/webapi/types";

export async function getLiquidationReport(
  terminalId: string,
  dateFrom: LocalDateTimeString,
  dateTo: LocalDateTimeString
): Promise<LiquidationReport> {
  const response = await client.get("/reports/liquidation", {
    params: { terminalId, dateFrom, dateTo },
  });

  return response.data;
}
