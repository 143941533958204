import { LocalDate } from "js-joda";
import moize from "moize";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { bindActionCreators } from "redux";

import { AppState } from "~/state";
import { wrap } from "~/views/ViewWrapper";

import {
  createAddBookingAction,
  fetchExcursion,
  fetchTicketOption,
  fetchTicketOptions,
  loadSearchView,
} from "./actions";
import AddExcursionView, { PayloadProps, Props } from "./AddExcursionView";

/** Propiedades que se derivan del estado de Redux. */
type StateProps = Pick<
  Props,
  "agencyName" | "currencyRestriction" | "excursionTicketOption" | "monthAvailability" | "ready" | "ticketTemplate"
>;

const mapStateToProps: MapStateToProps<StateProps, PayloadProps, AppState> = state => {
  return {
    agencyName: state.bookingProcess.agency?.name,
    currencyRestriction: state.bookingProcess?.booking?.tickets?.[0].price.currency,
    excursion: state.addExcursionView.excursion,
    excursionTicketOption: state.addExcursionView.excursionTicketOption,
    monthAvailability: state.addExcursionView.rangeAvailability,
    ready: state.addExcursionView.ready,
    ticketTemplate: state.bookingProcess.ticketTemplate,
  };
};

/** Propiedades que contienen acciones. */
type DispatchProps = Pick<
  Props,
  "getExcursion" | "getTicketOption" | "getTicketOptions" | "onBackToSearcher" | "onReservation"
>;
const mapDispatchToProps: MapDispatchToProps<DispatchProps, PayloadProps> = (
  dispatch,
  { excursionCode, modalityCode }
) =>
  bindActionCreators(
    {
      getExcursion: () => fetchExcursion(excursionCode, modalityCode),
      getTicketOption: (date: LocalDate) => fetchTicketOption(excursionCode, modalityCode, date),
      getTicketOptions: (from: LocalDate, to: LocalDate) => fetchTicketOptions(excursionCode, modalityCode, from, to),
      onBackToSearcher: loadSearchView,
      onReservation: createAddBookingAction,
    },
    dispatch
  );

export default connect(mapStateToProps, moize.simple(mapDispatchToProps))(wrap(AddExcursionView));
