/* Etiquetas multiidoma en castellano. */
export default {
  "distributionTable.amountRestriction": "max. {amount}",
  "distributionTable.footer.cancellation": "Cancellation",
  "distributionTable.footer.discount": "Discount",
  "distributionTable.footer.subtotal": "Subtotal",
  "distributionTable.footer.totalPrice": "TOTAL",
  "distributionTable.head.passenger": "Passenger",
  "distributionTable.head.totalAmount": "Total",
  "distributionTable.head.unitaryPrice": "U.Price",
  "distributionTable.head.units": "Qty",
  "distributionTable.paxRestrictionFrom": "from {paxMin} pax",
  "distributionTable.paxRestrictionFromTo": "from {paxMin} to {paxMax} pax",
  "distributionTable.paxRestrictionTo": "to {paxMax} pax",
};
