/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import Environment from "~/environment";
import { remoteLog } from "../webapi/log";

/** Nivel de severidad */
export enum Level {
  TRACE = -1,
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
  NONE = 4,
}

/** Definición genérica del stream a utilizar para printar en el log */
type Stream = (...args: any[]) => void;

/**
 * Servicio de logging
 */
class Logger {
  /** Indica si se printa por consola */
  private consoleOutput: boolean;
  /** Nivel acual de log */
  private level: Level;

  /**
   * Constructor
   *
   * @param level nivel de log
   */
  public constructor(level?: Level) {
    this.level = level || Level.TRACE;
    this.consoleOutput = !Environment.APK && Environment.DEBUG;
  }

  /**
   * Escribe un mensaje con información útil para DEBUG
   *
   * @param message mensaje
   * @param args parámetros opcionales
   */
  public debug(message: any, ...args: any[]): void {
    this.log(Level.DEBUG, console.debug, "[DEBUG] " + message, ...args);
  }

  /**
   * Escribe un mensaje de ERROR en el log
   *
   * @param message mensaje
   * @param args parámetros opcionales
   */
  public error(message: any, ...args: any[]): void {
    this.log(Level.ERROR, console.error, "[ERROR] " + message, ...args);
  }

  public errorCatch(error: Error, info?: React.ErrorInfo) {
    this.log(Level.ERROR, console.error, JSON.stringify({ error, info }));
  }

  /**
   * Devuelve el estado de la salida por consola
   */
  public getConsoleOutput(): boolean {
    return this.consoleOutput;
  }

  /**
   * Escribe un mensaje de INFOrmación en el log
   *
   * @param message mensaje
   * @param args parámetros opcionales
   */
  public info(message: any, ...args: any[]): void {
    this.log(Level.INFO, console.info, "[INFO] " + message, ...args);
  }

  /**
   * Printa un log por consola sin comprobar nada
   *
   * @param level nivel de log
   * @param message mensaje
   * @param args parámetros opcionales
   */
  public rawLog(level: Level, message: string, ...args: any[]) {
    console.log(`[${level}] ${message} ${JSON.stringify(args)}`);
  }

  /**
   * Establece si se printa por consola
   * @param consoleOutput nuevo estado
   */
  public setConsoleOutput(consoleOutput: boolean): void {
    this.consoleOutput = consoleOutput;
  }

  /**
   * Genera una excepción y la saca por consola
   */
  public stackTrace(): void {
    try {
      throw new Error();
    } catch (err) {
      this.info("[STACK]", err);
    }
  }

  /**
   * Escribe una traza (TRACE) en el log
   *
   * @param message mensaje
   * @param args parámetros opcionales
   */
  public trace(message: any, ...args: any[]): void {
    this.log(Level.TRACE, console.debug, "[TRACE] " + message, ...args);
  }

  /**
   * Escribe un mensaje con categoría avertencia (WARNing) en el log
   *
   * @param message mensaje
   * @param args parámetros opcionales
   */
  public warn(message: any, ...args: any[]): void {
    this.log(Level.WARN, console.warn, "[WARN] " + message, ...args);
  }

  /**
   * Escribe un mensaje en el log
   *
   * @param level: nivel de severidad del mensaje
   * @param stream: canal donde se escribe el mensaje
   * @param message mensaje
   * @param args parámetros opcionales
   */
  private log(level: Level, stream: Stream | undefined, message: string, ...args: any[]) {
    if (this.consoleOutput && this.level <= level) {
      try {
        const _stream = stream ? stream : console.log;
        if (args && args.length > 0) {
          _stream(message, ...args);
        } else {
          _stream(message);
        }
      } catch (err) {
        this.rawLog(level, message, args);
      }
    }
    // Remote log
    if (level === Level.WARN || level === Level.ERROR) {
      remoteLog(level === Level.WARN ? "WARN" : "ERROR", message).catch(err => {
        this.rawLog(level, message, err);
      });
    }
  }
}

/** Instacia ("singleton") del logger */
const logger = new Logger();

export default logger;
