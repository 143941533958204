/* eslint-disable max-len */
/* Etiquetas multiidoma en castellano. */
export default {
  "addExcursionView.agency": "Agencia",
  "addExcursionView.back": "Volver a la búsqueda",
  "addExcursionView.book": "Reservar",
  "addExcursionView.calendar.label": "Fecha Excursión",
  "addExcursionView.copyTemplate": "Copiar",
  "addExcursionView.excursion": "Excursión",
  "addExcursionView.hotel": "Hotel",
  "addExcursionView.hotelReference": "Hab.",
  "addExcursionView.hotelZone": "Zona del hotel",
  "addExcursionView.invalidCurrency":
    "Este ticket no se puede añadir a la reserva en curso. No es posible combinar distintas divisas en una misma reserva.",
  "addExcursionView.language": "Idioma",
  "addExcursionView.modality": "Modalidad",
  "addExcursionView.pickUpPoint": "Recog.",
  "addExcursionView.remarks": "Obs.",
  "addExcursionView.withoutAvailability": "No se ha encontrado disponibilidad en los próximos meses.",
};
