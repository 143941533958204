import React from "react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { setAgency } from "~/services/booking";
import { AppState } from "~/state";

import AgencySelector from "./AgencySelector";

/** Redux state to props. */
function statePropsSelector(state: AppState) {
  return {
    agencies: state.bookingProcess.agencies,
    agencyCode: state.bookingProcess.agency?.code,
    disabled: state.bookingProcess.booking != null,
  };
}

/**
 * Controlador del selector de agencia.
 * Permite escoger la agencia a utilizar durente el proceso de reserva.
 */
const AgencySelectorController: React.FC = () => {
  /* Datos */
  const dispatch = useDispatch();
  const { agencies, agencyCode, disabled } = useSelector(statePropsSelector, shallowEqual);

  /* Handlers */
  const changeAgency = (value: string) => {
    const agency = agencies?.find(({ code }) => code === value);
    if (agency) {
      dispatch(setAgency(agency));
    } else {
      // LOG: Esto no debería pasar.
    }
  };

  /* Render */
  return <AgencySelector agencies={agencies} disabled={disabled} onChange={changeAgency} value={agencyCode} />;
};

export default AgencySelectorController;
