import { BitmapData } from "./types";

/** Ancho máximo que permite la impresora para imágenes. */
const MAX_SUMNI_INNER_PRINTER_WIDTH = 380;

/**
 * Genera el bitmap para la impresora de sumni a partir de los datos de la
 * imagen codificados en base64.
 *
 * @param data imagen en base64.
 */
export async function bitmapFromBase64Image(data: string): Promise<BitmapData> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      try {
        resolve(bitmapFromImage(img));
      } catch (err) {
        reject(err);
      }
    };

    img.onerror = reject;

    img.src = `data:${imageType(data)};base64,${data}`;
  });
}

/**
 * Genera el BitmapData necesario para imprimir la imagen proporcionada.
 * NOTA: La imagen debe estar ya cargada.
 *
 * @param image elemento img ya cargado
 */
export function bitmapFromImage(image: HTMLImageElement): BitmapData {
  const canvas = window.document.createElement("canvas");

  canvas.width = Math.trunc(Math.min(image.width, MAX_SUMNI_INNER_PRINTER_WIDTH));
  canvas.height = Math.trunc(image.height * (canvas.width / image.width));

  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    const imageData = canvas.toDataURL("image/png");

    return {
      base64Data: imageData.substr("data:image/png;base64,".length),
      height: canvas.height,
      width: canvas.width,
    };
  }

  throw new Error("Cannot create 2D context");
}

/**
 * Recupera el tipo de imagen a partir de su "magic number". Ver
 * https://en.wikipedia.org/wiki/List_of_file_signatures
 *
 * @param data datos de la imagen en base64
 */
function imageType(data: string): string {
  switch (data.charAt(0)) {
    /* jpg: /9j/ */
    case "/":
      return "image/jpg";

    /* png: iVBORw0KGgo */
    case "i":
      return "image/png";

    /* bmp: Qk0 */
    case "Q":
      return "image/bmp";

    /* gif: R0lGOD */
    case "R":
      return "image/gif";

    default:
      /* "image" no es válido, pero le damos una oportunidad a webkit. */
      return "image";
  }
}
