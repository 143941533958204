/* Etiquetas multiidoma en castellano. */
export default {
  "bookingView.agency": "Agency",
  "bookingView.bookingNumber": "Locator",
  "bookingView.bookingStatus": "Booking {status}",
  "bookingView.bookingStatus.CANCELLED": "Cancelled",
  "bookingView.bookingStatus.CONFIRMED": "Confirmed",
  "bookingView.bookingStatus.ON_REQUEST": "On Request",
  "bookingView.cancelContinue": "Continue?",
  "bookingView.cancelTicket": "Ticket cancellation",
  "bookingView.cancelTicketAlert": "You are going to cancel a ticket.",
  "bookingView.cancellationAmount": "Cancellaction amount",
  "bookingView.cancelled": "Ticket cancelled",
  "bookingView.contact": "Contact",
  "bookingView.creationDate": "Creation Date",
  "bookingView.editBooking": "Edit",
  "bookingView.editCancel": "Cancel",
  "bookingView.editSave": "Save",
  "bookingView.error.cancelTicket": "Unable to cancel ticket.",
  "bookingView.error.fetchAnnulation": "Unable to retrieve annulation ticket.",
  "bookingView.error.fetchCancelCost": "Unable to retrive annulation price.",
  "bookingView.errorTitle": "Error",
  "bookingView.hideCancelled": "Hide cancelled",
  "bookingView.mode.default": " ",
  "bookingView.mode.editing": "Editing",
  "bookingView.paymentReference": "Reference",
  "bookingView.paymentType": "Payment Type",
  "bookingView.paymentType.CARD": "Card",
  "bookingView.paymentType.CASH": "Cash",
  "bookingView.phoneNumber": "Phone",
  "bookingView.printAllTickets": "Print all tickets",
  "bookingView.printCancelTicket": "Print cancellation ticket?",
  "bookingView.printVoucher": "Voucher",
  "bookingView.showCancelled": "Show cancelled",
  "bookingView.startNewBooking": "Start new booking",
};
