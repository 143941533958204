import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { LocalDate, LocalTime } from "js-joda";

import { SearchCriteria } from "../../types";

import { useI18n } from "~/services/i18n";
import DateRangePicker from "~/components/DateRangePicker";
import TimePicker from "~/components/TimePicker";
import { getDevice } from "~/utils/device";

/** Estilos del componente. */
const useStyles = makeStyles(() =>
  createStyles({
    buttonPanel: {
      "& > button": {
        marginLeft: 8,
      },
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 16,
    },
    root: {},
    row: {
      alignItems: "baseline",
      display: "flex",
      justifyContent: "space-between",
    },
    separator: {
      flex: "1 0 auto",
      minWidth: 25,
      textAlign: "center",
    },
  })
);

/** Propiedades del componente. */
export interface LiquidationSearcherProps extends Partial<SearchCriteria> {
  /** Función invocada al lanzar la búsqueda. */
  onSearch: (criteria: SearchCriteria) => void;
}

/* Devuelve el primer dia del mes */
function beginMonth() {
  return LocalDate.now().withDayOfMonth(1);
}

/* Devuelve el último día del mes */
function endMonth() {
  return LocalDate.now()
    .withDayOfMonth(1)
    .plusMonths(1)
    .minusDays(1);
}

const LiquidationSearcherForm: React.FC<LiquidationSearcherProps> = props => {
  const { dateFrom: dateFromProp, dateTo: dateToProp, onSearch, timeFrom: timeFromProp, timeTo: timeToProp } = props;

  const classes = useStyles();
  const { formatMessage } = useI18n();

  const [dateFrom, setDateFrom] = useState<LocalDate | undefined>(
    dateFromProp ? LocalDate.parse(dateFromProp) : beginMonth()
  );

  const [dateTo, setDateTo] = useState<LocalDate | undefined>(dateToProp ? LocalDate.parse(dateToProp) : endMonth());

  const [hours, setHours] = useState<boolean>(false);

  const [timeFrom, setTimeFrom] = useState<LocalTime | null>(timeFromProp ? LocalTime.parse(timeFromProp) : null);
  const [timeTo, setTimeTo] = useState<LocalTime | null>(timeToProp ? LocalTime.parse(timeToProp) : null);

  /* Callbacks */
  const updateDateRange = (dateFrom?: LocalDate, dateTo?: LocalDate) => {
    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const doSearch = () => {
    if (dateFrom && dateTo) {
      onSearch({
        dateFrom: dateFrom.toString(),
        dateTo: dateTo.toString(),
        terminalId: getDevice()?.serial || "UNKNOWN",
        timeFrom: hours && timeFrom ? timeFrom.toString() : undefined,
        timeTo: hours && timeTo ? timeTo.toString() : undefined,
      });
    }
  };

  const clearForm = () => {
    /* Días */
    setDateFrom(undefined);
    setDateTo(undefined);
    /* Horas */
    setTimeFrom(null);
    setTimeTo(null);
  };

  const updateTimeFrom = (newTimeFrom: LocalTime) => {
    setTimeFrom(newTimeFrom);
  };

  const updateTimeTo = (newTimeTo: LocalTime) => {
    setTimeTo(newTimeTo);
  };

  const updateHours = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHours(event.target.checked);
  };

  /* Render */
  return (
    <form noValidate={true} autoComplete="off" className={classes.root}>
      <DateRangePicker
        label={formatMessage("liquidationSearcherForm.dateRange.label")}
        dateFromValue={dateFrom}
        dateToValue={dateTo}
        onChange={updateDateRange}
      />

      <FormControlLabel
        control={<Switch checked={hours} onChange={updateHours} name="checkedB" color="secondary" />}
        label={formatMessage("liquidationSearcherForm.timepicker.label")}
      />

      {hours && (
        <div className={classes.row}>
          <TimePicker
            label={formatMessage("liquidationSearcherForm.timepicker.from")}
            time={timeFrom ? timeFrom.toString() : null}
            onChange={updateTimeFrom}
          />
          <div className={classes.separator}>-</div>
          <TimePicker
            label={formatMessage("liquidationSearcherForm.timepicker.to")}
            time={timeTo ? timeTo.toString() : null}
            onChange={updateTimeTo}
          />
        </div>
      )}

      <div className={classes.buttonPanel}>
        <Button color="default" variant="outlined" onClick={clearForm}>
          {formatMessage("liquidationSearcherForm.action.clear")}
        </Button>
        <Button color="primary" variant="contained" onClick={doSearch}>
          {formatMessage("liquidationSearcherForm.action.search")}
        </Button>
      </div>
    </form>
  );
};

export default LiquidationSearcherForm;
