import React from "react";

import Fab from "@material-ui/core/Fab";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import CancelIcon from "@material-ui/icons/Clear";
import Highlight from "@material-ui/icons/Highlight";
import HighlightOff from "@material-ui/icons/HighlightOff";

import { useI18n } from "~/services/i18n";

const useStyles = makeStyles(theme =>
  createStyles({
    lanternControl: {
      left: 5,
      position: "absolute",
      top: 5,
    },
    closeControl: {
      right: 5,
      position: "absolute",
      top: 5,
    },
    frame: {
      borderColor: theme.palette.secondary.main,
      borderRadius: "35px",
      borderStyle: "dashed",
      height: 200,
      width: 200,
    },
    frameContainer: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    root: {
      display: "flex",
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "100%",
    },
    scanner: {
      alignItems: "center",
      background: "none !important",
      backgroundColor: "none",
      display: "flex",
      flex: 1,
    },
  })
);

/** Las propiedades del componente. */
export interface ProviedProps {
  /** Indica si la luz está disponible. */
  isLightAvailable: boolean;

  /** Indica si la luz está encendida. */
  isLightOn: boolean;

  /** Función invocada cuando se cierra el escáner. */
  onClose: () => void;

  /** Función invocada para encender/apagar la luz. */
  onToggleLight: () => void;
}

/**
 * Componente para escanear códigos qr.
 */
const QRScanner: React.FC<ProviedProps> = props => {
  const { isLightAvailable, isLightOn, onClose, onToggleLight } = props;

  const classes = useStyles();
  const { formatMessage } = useI18n();

  return (
    <div className={classes.root}>
      <div className={classes.scanner}>
        <div className={classes.frameContainer}>
          <div className={classes.frame} />
        </div>
      </div>
      {isLightAvailable && (
        <div className={classes.lanternControl}>
          <Fab
            size="small"
            color="default"
            aria-label={formatMessage("qrScanner.lanternButton")}
            onClick={onToggleLight}
          >
            {isLightOn ? <HighlightOff /> : <Highlight />}
          </Fab>
        </div>
      )}
      <div className={classes.closeControl}>
        <Fab size="small" color="default" aria-label={formatMessage("qrScanner.closeButton")} onClick={onClose}>
          <CancelIcon />
        </Fab>
      </div>
    </div>
  );
};

export default QRScanner;
