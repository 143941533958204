import React from "react";
import { LocalDate } from "js-joda";

import DialogContentText from "@material-ui/core/DialogContentText";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import ConfirmDialog from "~/components/ConfirmDialog";
import { useI18n } from "~/services/i18n";
import { formatDate } from "~/services/i18n/i18nApiImpl";

const useStyles = makeStyles(theme =>
  createStyles({
    date: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

export interface RescheduleTicketProps {
  /** Fecha preseleccionada para el ticket. */
  date: LocalDate;

  /** Función invocada al cerrar la confirmación de cambio de ticket.  */
  onHandleAction: (actionConfirmed: boolean) => void;
}

/**
 * Vista confirmación de cambio de fecha de una reserva.
 */
const ResheduleConfirmation: React.FC<RescheduleTicketProps> = props => {
  const { date, onHandleAction } = props;

  const { formatMessage } = useI18n();
  const classes = useStyles();

  const dialogContent = (
    <>
      <DialogContentText>
        {formatMessage("rescheduleView.confirm.message")}
        <span className={classes.date}>{formatDate(date.toString())}</span>
      </DialogContentText>
      <DialogContentText>{formatMessage("rescheduleView.confirm.cancelContinue")}</DialogContentText>
    </>
  );

  return (
    <>
      {date && (
        <ConfirmDialog open={true} title={formatMessage("rescheduleView.confirm.title")} onSelect={onHandleAction}>
          {dialogContent}
        </ConfirmDialog>
      )}
    </>
  );
};

export default ResheduleConfirmation;
