import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { LiquidationReport } from "~/services/webapi/types";
import { useI18n } from "~/services/i18n";

import Header from "./Header";
import Detail from "./Detail";
import TotalTickets from "./TotalTickets";

/** Estilos del componente. */
const useStyles = makeStyles(() =>
  createStyles({
    cardStyle: {
      marginTop: 24,
    },
    dividerStyle: {
      marginTop: 8,
      marginBottom: 8,
    },
  })
);

/** Propiedades del componente. */
export interface LiquidationResultProps {
  /** Informe de liquidación */
  liquidation: LiquidationReport;

  /** Función invocada al imprimir la liquidación */
  onPrintLiquidation: () => void;
}

const LiquidationResult: React.FC<LiquidationResultProps> = props => {
  const {
    liquidation: { cancelledTicketsCount, dateFrom, dateTo, liquidations, seller, soldTicketsCount, terminalId },
    onPrintLiquidation,
  } = props;

  const classes = useStyles();
  const { formatMessage } = useI18n();

  /* Render */
  return (
    <Card className={classes.cardStyle}>
      <CardContent>
        <Header
          terminalId={terminalId}
          dateFrom={dateFrom}
          dateTo={dateTo}
          seller={seller}
          onPrintLiquidation={onPrintLiquidation}
        />

        {liquidations &&
          liquidations.map((liquidation, index) => (
            <div key={index}>
              <Detail value={liquidation.cash} title={formatMessage("liquidationResult.paymentType.cash")} />
              <Detail value={liquidation.card} title={formatMessage("liquidationResult.paymentType.card")} />
              <Detail value={liquidation.total} title={formatMessage("liquidationResult.paymentType.general")} />
              <Divider className={classes.dividerStyle} />
            </div>
          ))}

        <TotalTickets cancelledTicketsCount={cancelledTicketsCount} soldTicketsCount={soldTicketsCount} />
      </CardContent>
    </Card>
  );
};

export default LiquidationResult;
