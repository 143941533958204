/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { cancelTicket, updateBooking, refreshBooking } from "~/services/booking";
import { get as apiGetBooking } from "~/services/webapi/bookings";
import { Booking } from "~/services/webapi/types";
import { AppState } from "~/state";
import { wrap } from "../ViewWrapper";
import { onNewBooking, printAllTickets, printOneTicket, printVoucher } from "./actions";
import BookingView, { BookingViewProps as Props } from "./BookingView";

const mapStateToProps: MapStateToProps<void, Props, AppState> = (state, props): Partial<Props> => {
  /*
   * Inicialmente se muestra el que viene por props, pero tras cualquier acción
   * que modifique la reserva, nos quedamos con el que está en el estado local.
   */
  const actualBooking = state.bookingView.booking || props.booking;

  return {
    allowCancellation:
      state.auth.user!.authorities && state.auth.user!.authorities.find(p => p === "CANCEL_BOOKING") != null,
    allowEdition: state.auth.user!.authorities && state.auth.user!.authorities.find(p => p === "EDIT_BOOKING") != null,
    allowReschedule:
      state.auth.user!.authorities && state.auth.user!.authorities.find(p => p === "RESCHEDULE_BOOKING") != null,
    booking: actualBooking,
    getBooking: apiGetBooking,
    printVoucher: printVoucher,
  };
};

type DispatchProps = Pick<Props, "cancelTicket" | "startNewBooking" | "updateBooking">;
const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = (
  dispatch: ThunkDispatch<AppState, void, Action>
) => ({
  cancelTicket: async (bookingNumber: string, ticketNumber: string) =>
    await dispatch(cancelTicket(bookingNumber, ticketNumber)),
  initBooking: (booking: Booking) => dispatch(refreshBooking(booking)),
  printAllTickets: (booking: Booking) => dispatch(printAllTickets(booking)),
  printTicket: (booking: Booking, ticketNumber: string) => dispatch(printOneTicket(booking, ticketNumber)),
  refreshBooking: (booking: Booking) => dispatch(refreshBooking(booking)),
  startNewBooking: () => dispatch(onNewBooking()),
  updateBooking: async (bookingData: Booking) => await dispatch(updateBooking(bookingData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(wrap(BookingView));
