import RedsysMPos, { RedsysResponse } from "cordova-plugin-redsys-mpos";

import logger from "~/services/logger";
import { postVoucher } from "~/services/webapi/payment";
import { readFileAsBase64 } from "~/utils/files";

import { PaymentResult } from "./types";

/**
 * Construye la respuesta a partir de los datos recibidos del pinpad.
 */
function buildPaymentResult(response?: RedsysResponse): PaymentResult {
  let errorMessage: string | undefined;
  if (typeof response?.state === "number") {
    errorMessage = `${response.state}: ${response.errorMsg}`;
  }

  return {
    authorizationCode: response?.authorizationNumber,
    errorMessage,
  };
}

/**
 * Inicia un cobro en el mpos de redsys
 *
 * @param amount importe a cobrar
 * @param reference referencia del cobro
 */
export async function charge(amount: number, reference: string): Promise<PaymentResult> {
  const chargeAction = new Promise<RedsysResponse>((resolve, reject) => {
    RedsysMPos.charge(
      amount,
      reference,
      response => resolve(response),
      error => reject(error)
    );
  });

  const response = await chargeAction;

  const result = buildPaymentResult(response);
  result.voucherId = await saveVoucher(response);

  return result;
}

/**
 * Guarda el recibo del tpv
 *
 * @param response identificador del recibo en el sistema
 */
async function saveVoucher(response: RedsysResponse): Promise<number | undefined> {
  if (response?.authorizationNumber && response?.ticketPath) {
    /* Enviar. */
    try {
      const voucher = {
        name: response.ticketPath,
        content: await readFileAsBase64(response.ticketPath),
        contentType: "application/pdf",
      };

      return (await postVoucher(voucher)).id;
    } catch (error) {
      /* Si falla poco podemos hacer más que notificarlo en el log. */
      logger.error("Unable to save voucher.");
      logger.errorCatch(error);
    }
  }

  return;
}
