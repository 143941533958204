import { Reducer } from "redux";
import { combineReducers } from "@reduxjs/toolkit";

import { reducer as authReducer } from "~/services/auth";
import { reducer as bookingProcessReducer } from "~/services/booking";
import { reducer as configReducer } from "~/services/config";

import { reducer as appViewReducer } from "~/views";
import { reducer as addExcursionViewReducer } from "~/views/AddExcursionView";
import { reducer as bookingSearchViewReducer } from "~/views/BookingSearchView";
import { reducer as bookingViewReducer } from "~/views/BookingView";
import { reducer as confirmBookingViewReducer } from "~/views/ConfirmBookingView";
import { reducer as liquidationReportViewReducer } from "~/views/LiquidationReportView";
import { reducer as loginViewReducer } from "~/views/LoginView";
import { reducer as searchViewReducer } from "~/views/SearcherView";

import { RESET_STATE, SOFT_RESET } from "../actions";

/** Reducer, combinación de los reducers de cada componente.  */
const appReducer = combineReducers({
  addExcursionView: addExcursionViewReducer,
  appView: appViewReducer,
  auth: authReducer,
  bookingProcess: bookingProcessReducer,
  bookingSearchView: bookingSearchViewReducer,
  bookingView: bookingViewReducer,
  config: configReducer,
  confirmBookingView: confirmBookingViewReducer,
  liquidationReportView: liquidationReportViewReducer,
  loginView: loginViewReducer,
  searchView: searchViewReducer,
});

/**
 * Definición del estado de la App que se alamcena en redux.
 * Se compone de los estados individuales de cada componente.
 */
export type AppState = ReturnType<typeof appReducer>;

/**
 * Reducer principal que trata casos especiales que implican el reset del
 * estado de múltiples componentes. Son casusas ajenas al comportamiento de cada
 * componente y, por ello, no se quiere tratar en cada uno de los reducers.
 */
const rootReducer: Reducer<AppState> = (state, action) => {
  if (state != null) {
    if (action.type === SOFT_RESET) {
      /*
       * Se hace un reset de todo para tener el estado por defecto y después se
       * restaura el subestado de la autenticación y configuración.
       */
      return { ...appReducer(undefined, action), auth: state.auth, config: state.config };
    } else if (action.type === RESET_STATE) {
      /* Reset de toda la aplicación. */
      return appReducer(undefined, action);
    }
  }

  return appReducer(state, action);
};

export default rootReducer;
