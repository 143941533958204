import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { printLiquidation } from "./printLiquidationReport";
import { SearchCriteria } from "./types";

import { getLiquidationReport as liquidation } from "~/services/webapi/report.ts";
import { LiquidationReport, LocalDateTimeString } from "~/services/webapi/types";
import { AppThunk } from "~/state";

export interface LiquidationReportState {
  /** Criterio de búsqueda. */
  searchCriteria?: SearchCriteria;
}

const defaultState: LiquidationReportState = {};

const liquidationReportSlice = createSlice({
  name: "liquidationReportView",
  initialState: defaultState,
  reducers: {
    /**
     * Guarda searchCriteria en el estado de Redux.
     */
    setSearchCriteria(state, action: PayloadAction<SearchCriteria>) {
      state.searchCriteria = action.payload;
    },
  },
});

export const { setSearchCriteria } = liquidationReportSlice.actions;

export default liquidationReportSlice.reducer;

/* Thunks */
export const setCriteria = (criteria: SearchCriteria): AppThunk => async dispatch => {
  dispatch(setSearchCriteria(criteria));
};

/* Acciones */
export async function printLiquidationReport(liquidationReport: LiquidationReport): Promise<void> {
  printLiquidation(liquidationReport);
}

export async function getLiquidationReport(
  terminalId: string,
  dateFrom: LocalDateTimeString,
  dateTo: LocalDateTimeString
): Promise<LiquidationReport> {
  const result = await liquidation(terminalId, dateFrom, dateTo);
  return result;
}
