import React from "react";

import { LocalDate } from "js-joda";

import Dialog from "@material-ui/core/Dialog";

import AvailabilityCalendar from "~/components/AvailabilityCalendar";

import { ExcursionTicket, ExcursionTicketOption, LocalDateString } from "~/services/webapi/types";

/** Indica si un día del calendario debe estar habilitado. */
const enabled = (currentTicket: ExcursionTicket, ticketOption: ExcursionTicketOption) => {
  if (currentTicket) {
    if (ticketOption.paxTypes) {
      let basePrice = 0;
      const currency = currentTicket.paxes[0].paxType.price.currency;

      for (const paxQuota of currentTicket.paxes) {
        //Debe tener el mismo tipo de pasajero.
        const paxType = ticketOption.paxTypes.find(item => item.code === paxQuota.paxType.code);
        if (!paxType) {
          return false;
        }

        //Debe cumplir con la capacidad mínima y máxima de pasajeros.
        if (
          (ticketOption.paxMin && paxQuota.units < ticketOption.paxMin) ||
          (ticketOption.paxMax && paxQuota.units > ticketOption.paxMax)
        ) {
          return false;
        }

        //Todos los tipos de pasajeros deben tener la misma divisa.
        const price = paxType.price;
        if (currency !== price.currency) {
          return false;
        }

        const paxAmount = paxQuota.units * price.amount;
        basePrice += paxAmount;
      }

      //Misma divisa que el ticket original.
      if (currency !== currentTicket.price.currency) {
        return false;
      }

      //Mismo precio que el ticket original.
      if (basePrice !== currentTicket.price.basePrice) {
        return false;
      }
    } else {
      return false;
    }

    //Debe aceptar el mismo idioma que el ticket original.
    if (!ticketOption.languages?.some(item => item.code === currentTicket.language.code)) {
      return false;
    }

    //Mismo hotel y punto de recogida.
    if (currentTicket.hotel?.pickUpPoints) {
      if (ticketOption?.hotels) {
        const hotel = ticketOption.hotels.find(item => item.code === currentTicket.hotel.code);
        if (hotel?.pickUpPoints) {
          const pickupPoint = hotel.pickUpPoints.some(item => item.code === currentTicket.pickupPoint.code);
          if (!pickupPoint) {
            return false;
          }
        }
      }
      return false;
    }

    //Mismo punto de recogida.
    if (currentTicket.pickupPoint) {
      if (!ticketOption.pickUpPoints) {
        return false;
      }
      const pickupPoint = ticketOption.pickUpPoints.some(item => item.code === currentTicket.pickupPoint.code);
      if (!pickupPoint) {
        return false;
      }
    }

    //TODO Faltaría comprobar los rangos de edad (fromAge, toAge) pero no disponemos de la edad de los pasajeros.

    return true;
  }
  return false;
};

export interface RescheduleCalendarProps {
  /** Mapa con los valores de disponibilidad para cada día. */
  excursionOptionMap?: Map<LocalDateString, ExcursionTicketOption>;

  /** Función invocada cuando se cancela el cambio de ticket. */
  onClose: (error?: string) => void;

  /** Función para recuperar la disponibilidad entre fechas. */
  onGetTicketOptions: (from: LocalDate, to: LocalDate) => void;

  /** Función invocada al seleccionar una fecha del calendario. */
  onSelect: (date: LocalDate) => void;

  /** Ticket que se está reprogramando. */
  ticket: ExcursionTicket;
}

/**
 * Vista del calendario para el cambio de fecha de un ticket.
 */
const RescheduleCalendar: React.FC<RescheduleCalendarProps> = props => {
  const { excursionOptionMap, onClose, onGetTicketOptions, onSelect, ticket } = props;

  const isEnabled = (ticketOption: ExcursionTicketOption) => {
    return enabled(ticket, ticketOption);
  };

  const paperFix = { style: { margin: 8 } };

  return (
    <>
      {
        <Dialog
          onClose={() => {
            onClose();
          }}
          open={true}
          PaperProps={paperFix}
        >
          <AvailabilityCalendar
            onSelect={onSelect}
            getTicketOptions={onGetTicketOptions}
            values={excursionOptionMap}
            isEnabled={isEnabled}
          />
        </Dialog>
      }
    </>
  );
};

export default RescheduleCalendar;
