import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { useI18n } from "~/services/i18n";
import { LiquidationReport } from "~/services/webapi/types";

import LiquidationResult from "./components/LiquidationResult";
import LiquidationSearcherForm from "./components/LiquidationSearcherForm";

import { SearchCriteria } from "./types";

/** Estilos del componente. */
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexShrink: 0,
    },
  })
);

export interface LiquidationReportProps {
  /** Criterio de búsqueda */
  searchCriteria?: SearchCriteria;

  /** Liquidación */
  liquidation?: LiquidationReport;

  /** Función invocada al lanzar la búsqueda. */
  onSearch: (criteria: SearchCriteria) => void;

  /** Función invocada al imprimir la liquidación */
  onPrintLiquidation: () => void;
}

const LiquidationReportView: React.FC<LiquidationReportProps> = props => {
  const { liquidation, onPrintLiquidation, onSearch, searchCriteria } = props;

  const classes = useStyles();
  const { formatMessage } = useI18n();

  /* Render */
  return (
    <div className={classes.root}>
      <Typography variant="h6">{formatMessage("liquidationReport.title")}</Typography>

      <LiquidationSearcherForm
        dateFrom={searchCriteria?.dateFrom}
        dateTo={searchCriteria?.dateTo}
        terminalId={searchCriteria?.terminalId}
        timeFrom={searchCriteria?.timeFrom}
        timeTo={searchCriteria?.timeTo}
        onSearch={onSearch}
      />

      {liquidation && <LiquidationResult liquidation={liquidation} onPrintLiquidation={onPrintLiquidation} />}
    </div>
  );
};

export default LiquidationReportView;
