import React from "react";

import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";

import AlertDialog from "~/components/AlertDialog";
import QRScannerController from "~/components/QRScanner";
import { ViewId } from "~/views";

import { HeaderBar } from "./components/HeaderBar";
import { ViewContext, ViewContextInstance } from "./wrapperContext";

/**
 * Definición de los estilos por defecto.
 */
const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    wrappedView: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      overflow: "auto",
      padding: "10px 10px 10px 10px",
      position: "relative",
    },
    hiddenContent: {
      display: "none",
    },
  });

/** Las propiedades del componente. */
export interface Props extends WithStyles<typeof styles> {
  /** El nombre de la agencia seleccionada. */
  agency?: string | null;

  /** Indica si el número de items en la cesta. */
  bookedItems?: number;

  /** La vista actual. */
  currentView: ViewId;

  /** Handler para el vento cerrar sesión. */
  onLogout: () => void;

  /** Handler para tratar el evento de cuando el usuario escoge una vista. */
  onViewSelect: (view: ViewId) => void;

  /** El nombre de usuario a mostrar. */
  user?: string | null;
}

type ScannerCloseHandler = () => void;
type ScannerErrorHandler = (error: QRScannerError) => void;
type ScannerResponseHandler = (qrText?: string) => void;

/** Estado del wrapper. */
interface State {
  context: ViewContext;
  dialogOpen?: boolean;
  dialogData?: {
    title?: string;
    text: string;
    onClose: ScannerCloseHandler;
  };
  showQR?: boolean;
  qrData?: {
    onClose: ScannerCloseHandler;
    onError: ScannerErrorHandler;
    onScanned: ScannerResponseHandler;
  };
}

/**
 * Wrapper para añadir el menú de la aplicación a las vistas.
 */
class ViewWrappwer extends React.PureComponent<Props, State> {
  /** #constructor */
  public constructor(props: Props) {
    super(props);

    this.state = {
      context: {
        showDialog: (text, title) => {
          let onClose = () => {
            /* empty */
          };
          const result = new Promise<void>(resolve => {
            onClose = () => {
              this.setState({ dialogOpen: false, dialogData: undefined });
              resolve();
            };
          });
          this.setState({ dialogOpen: true, dialogData: { text, title, onClose } });

          return result;
        },
        qrScanner: () => {
          let onClose = () => {
            /* empty */
          };

          let onError: ScannerErrorHandler = () => {
            /* empty */
          };

          let onScanned: ScannerResponseHandler = () => {
            /* empty */
          };

          const result = new Promise<string | null>((resolve, reject) => {
            onClose = () => {
              this.setState({ showQR: false });
              resolve(null);
            };

            onError = (error: QRScannerError) => {
              this.setState({ showQR: false });
              reject(error);
            };

            onScanned = (qrText: string) => {
              this.setState({ showQR: false });
              resolve(qrText);
            };
          });

          this.setState({ showQR: true, qrData: { onClose, onError, onScanned } });

          return result;
        },
      },
    };
  }

  /** #render */
  public render() {
    const { agency, bookedItems, classes, currentView, onLogout, onViewSelect, user } = this.props;

    const { context, dialogOpen, dialogData, qrData, showQR } = this.state;

    return (
      <ViewContextInstance.Provider value={context}>
        <div className={showQR ? classes.hiddenContent : classes.root}>
          <HeaderBar
            agency={agency}
            bookedItems={bookedItems}
            currentView={currentView}
            onLogout={onLogout}
            onViewSelect={onViewSelect}
            user={user}
          />
          <div className={classes.wrappedView}>{this.props.children}</div>
        </div>
        {dialogOpen && dialogData && (
          <AlertDialog open={true} onClose={dialogData?.onClose} title={dialogData.title} text={dialogData?.text} />
        )}
        {showQR && qrData && (
          <div className={classes.root}>
            <div className={classes.wrappedView}>
              <QRScannerController onClose={qrData?.onClose} onError={qrData?.onError} onScanned={qrData?.onScanned} />
            </div>
          </div>
        )}
      </ViewContextInstance.Provider>
    );
  }
}

export default withStyles(styles)(ViewWrappwer);
